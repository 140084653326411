import React, {useState} from "react";
import FormContainer from "../FormComponents/FormContainer";
import Input from "../FormComponents/Input";
import SubmitButton from "../ButtonComponents/SubmitButton";
import FormLabel from "../FormComponents/FormLabel";
import styled from "styled-components";
import Header1Blue from "../TextComponents/Header1";
import BigTextBlue from "../TextComponents/BigTextBlue";
import SmallTextBlue from "../TextComponents/SmallTextBlue";
import logo from "../../assets/images/logo.png";
import useSharedSignup from "./useSharedSignup";
import validateRegister from "../Register/validateRegister";
import ErrorMessage from "../FormComponents/ErrorMessage";
import {NUVOBOLDFONT, NUVOGREEN, NUVOWHITE} from "../../constants/styles";


const  SharedRegister =  (props) => {
    const { isMobile, setLoginActive } = props;
    const { handleChange, handleSubmit, values, errors } = useSharedSignup(validateRegister, props.handleRegistrationState, props.handleLoginActive);
    const [isSubmitted, setIsSubmitted] = useState(false);

    return (
        <React.Fragment>
            { isMobile ? <Logo src={logo} alt={"logo"} /> : null }
            <Header1Blue>Welcome to Nuvo Energy</Header1Blue>
            <BigTextBlue>Register as a user</BigTextBlue>
            <SignupWrapper>
                <SmallTextBlue>Already have an account?</SmallTextBlue>
                <Link href={'/'}>Login</Link>
            </SignupWrapper>
            <FormContainer noValidate onSubmit={handleSubmit}>
                <FormLabel htmlFor="name" name="Name*"/>
                <Input
                    isMobile={isMobile}
                    name="name"
                    type="text"
                    placeholder="Johnny English"
                    value={values.name}
                    onChange={handleChange}
                />
                <ErrorMessage message={errors.name}/>
                <FormLabel htmlFor="email" name="Email*"/>
                <Input
                    readOnly={true}
                    isMobile={isMobile}
                    name="email"
                    type="email"
                    placeholder="name@domain.com"
                    value={values.email}
                    onChange={handleChange}
                />
                <ErrorMessage message={errors.email}/>
                <FormLabel htmlFor="password" name="Password*"/>
                <Input
                    isMobile={isMobile}
                    name="password"
                    type="password"
                    placeholder="password"
                    value={values.password}
                    onChange={handleChange}
                />
                <ErrorMessage message={errors.password}/>
                <FormLabel htmlFor="password2" name="Confirm Password*"/>
                <Input
                    isMobile={isMobile}
                    name="password2"
                    type="password"
                    placeholder="password"
                    value={values.password2}
                    onChange={handleChange}
                />
                <ErrorMessage message={errors.password2}/>
                <ButtonWrapper isMobile={isMobile}>
                    <SubmitButton>Register</SubmitButton>
                </ButtonWrapper>
            </FormContainer>
        </React.Fragment>
    )
}

export default SharedRegister;

const ButtonWrapper = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`;

const SignupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const Logo = styled.img`
  height: 100px;
  width: 270px;
`;

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  color: ${NUVOGREEN};
  padding: 0;
  margin-left: 5px;
  font-weight: bold;
`;