import styled from "styled-components";
import { NUVOBOLDFONT, NUVOHEADERBLUE, NUVOWHITE} from "../../constants/styles";
import SignOut from "../ButtonComponents/SignOut";
import useMobileView from '../Hooks/useMobileView';
import useRenderNav from '../Hooks/Nav/useRenderNav';
import Table from "./Users/UsersTable";

const Management = () => {
    const { isMobile } = useMobileView();
    const { renderNav } = useRenderNav(isMobile);
    const ResponsiveTitle = isMobile ? DashBoardHeaderMobile : DashBoardHeader

    return (
        <PageWrapper>
            {renderNav()}
            <DashboardContent>
                <DashBoardHeaderWrapper>
                    <ResponsiveTitle>Management - Users</ResponsiveTitle>
                    <SignOut>Sign-out</SignOut>
                </DashBoardHeaderWrapper>
                <Table/>
            </DashboardContent>
        </PageWrapper>

    )
}

export default Management;

const PageWrapper = styled.div`
    display: flex;
`;

const DashboardContent = styled.div`
  width: 100%;
`;

const DashBoardHeaderWrapper = styled.div`
  background-color: ${NUVOWHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 14px;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
  margin-bottom: 40px;
`;

const DashBoardHeader = styled.h1`
  margin: 0 0 0 30px;
`;

const DashBoardHeaderMobile = styled.h2`
  margin: 0 0 0 30px;
`;

