import React, {useState} from 'react';
import styled from 'styled-components';
import {InlineIcon} from "@iconify/react";
import useMobileView from "../Hooks/useMobileView";
import {NUVOWHITE, NUVOBOLDFONT} from "../../constants/styles";

const Accordion = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(children.some(i => i.props?.path === true)); //update when more children
    const {isMobile} = useMobileView();
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <AccordionWrapper>
            <AccordionHeader onClick={toggleAccordion} isMobile={isMobile}>
                <div style={{display: 'flex'}}>
                    <InlineIcon icon="material-symbols:manage-accounts-outline" style={{marginRight: '4px'}}/>
                    {!isMobile && <AccordionTitle>{title}</AccordionTitle>}
                </div>
                {!isMobile && <InlineIcon
                    icon={isOpen ? "material-symbols:keyboard-arrow-up" : "material-symbols:keyboard-arrow-down"}
                    style={{marginRight: '8px'}}/>}
            </AccordionHeader>
            {isOpen && <AccordionContent>{children}</AccordionContent>}
        </AccordionWrapper>
    );
};

export default Accordion;

const AccordionWrapper = styled.div`
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  cursor: pointer;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  line-height: 20px;
  font-size: ${props => props.isMobile ? '35px' : '16px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${props => props.isMobile ? '0' : '30px'};

  &:hover {
    background-color: ${({isMobile}) => !isMobile ? 'rgba(0,0,12,0.26)' : null};
  }
`;

const AccordionTitle = styled.div`
  margin: 0;
`;

const AccordionContent = styled.div`
  padding-right: 8px;
  padding-left: 14px;
  flex-direction: column;
  display: flex;
`;
