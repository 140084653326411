import { useState, useMemo } from "react";
import ReactFlow from "react-flow-renderer";
import SolarIcon from "../Icons/SolarIcon";
import ConsumptionIcon from "../Icons/ConsumptionIcon";
import GridIcon from "../Icons/GridIcon";
import GensetIcon from "../Icons/GensetIcon";
import useGetEnergyFlowData from "../Hooks/useGetEnergyFlowData";

const EnergyFlow = ({ deviceId, isGensetMeterSite, isLive }) => {
    const [edges, setEdges] = useState({ solarEdge: null, gridEdge: null, gensetEdge: null });
    const EnergyFlowData = useGetEnergyFlowData(deviceId, isLive);

    const svgSize = '30px';
    const props = {
        data: EnergyFlowData.data,
        isGensetMeterSite: isGensetMeterSite,
        width: svgSize,
        height: svgSize
    };

    const box = {
        width: 85,
        height: 90,
        background: '#EFEEEE'
    }

    const handleSolar = (solarValue) => {
        setEdges(prev => ({
            ...prev,
            solarEdge: solarValue > 0 ? { id: 'e1-3', source: '1', target: '3', animated: true } : null
        }))
    }

    const handleGrid = (gridActive, gridValue) => {
        setEdges(prev => ({
            ...prev,
            gridEdge: gridActive ? (gridValue >= 0
                    ? { id: 'e2-3', source: '2', target: '3', animated: true }
                    : { id: 'e2-3', source: '3', target: '2', animated: true })
                : null
        }))
    }

    const handleActiveGenset = (gensetActive) => {
        setEdges(prev => ({
            ...prev,
            gensetEdge: gensetActive ? {id: 'e4-3', source: '4', target: '3', animated: true} : null
        }))
    }

    const elements = useMemo(() => [
        {
            id: '1',
            type: 'input', // input node
            sourcePosition: 'right',
            data: { label: <SolarIcon {...props} handleSolar={handleSolar}/> },
            position: { x: 20, y: 65 },
            style: {
                background: box.background,
                color: '#333',
                border: '1px solid #91CC32',
                width: box.width,
                height: box.height
            },
        },
        // default node
        {
            id: '2',
            type: 'input',
            sourcePosition: 'left',
            data: { label: <GridIcon {...props} handleGrid={handleGrid}/>},
            position: { x: 280, y: 65 },
            style: {
                background: box.background,
                color: '#333',
                border: '1px solid #91CC32',
                width: box.width,
                height: box.height
            },
        },
        {
            id: '3',
            type: 'input',
            sourcePosition: 'bottom',
            data: { label: <ConsumptionIcon {...props}/>},
            position: {x: 150, y: 0},
            style: {
                background: box.background,
                color: '#333',
                border: '1px solid #91CC32',
                width: box.width,
                height: box.height
            },
        },
        {
            id: '4',
            data: { label: <GensetIcon {...props} handleActiveGenset={handleActiveGenset} />},
            sourcePosition: 'top',
            position: {x: 150, y: 130},
            style: {
                background: box.background,
                color: '#333',
                border: '1px solid #91CC32',
                width: box.width,
                height: box.height
            },
        },
        // animated edge
        edges.solarEdge,
        edges.gridEdge,
        edges.gensetEdge
    ].filter(Boolean), [edges, props, box]); // useMemo here will prevent re-calculating `elements` unless `edges`, `props` or `box` changes

    return (
        <div style={{width: 400, height:  300 }}>
            <ReactFlow
                elements={elements}
                nodesDraggable={false}
                elementsSelectable={false}
                zoomOnScroll={false}
                zoomOnPinch={false}
                zoomOnDoubleClick={false}
                selectNodesOnDrag={false}
                paneMoveable={false}
            />
        </div>
    )
}

export default EnergyFlow;
