import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NUVOTEXTBLUE, NUVOSEMIFONT } from '../../constants/styles'

const GensetIcon = React.memo(({data, isGensetMeterSite, width, height, handleActiveGenset}) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (data) {
            const {meter2_active_power, eb_state, meter_active_power} = data?.getEnergyFlowData || {}
            if (isGensetMeterSite) {
                handleActiveGenset(meter2_active_power !== 0);
                if(meter2_active_power !== 0) setValue(meter2_active_power);
            } else {
                handleActiveGenset(eb_state === 0);
                if(eb_state === 0) setValue(meter_active_power);
            }
        }
    }, [data]);

    return (
        <IconWrapper>
            <div>Genset</div>
            <svg  xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 108.067 96.805">
                <g  transform="translate(0 10.09)">
                    <path d="M385.343,92.009h-88.7a2.068,2.068,0,0,1-2.07-2.07V16.7a2.069,2.069,0,0,1,2.07-2.07h88.7a17.321,17.321,0,0,1,17.3,17.3V74.708A17.32,17.32,0,0,1,385.343,92.009ZM298.716,87.87h86.626A13.177,13.177,0,0,0,398.5,74.708V31.93a13.177,13.177,0,0,0-13.162-13.164H298.716Z" transform="translate(-294.577 -14.627)" fill="#7bce00"/>
                </g>
                <g  transform="translate(0 0)">
                    <path d="M325.09,22.763H296.647a2.069,2.069,0,0,1-2.07-2.07V14.421a5.893,5.893,0,0,1,5.886-5.888h20.808a5.894,5.894,0,0,1,5.888,5.888v6.272A2.069,2.069,0,0,1,325.09,22.763Zm-26.374-4.139h24.3v-4.2a1.749,1.749,0,0,0-1.749-1.749H300.463a1.749,1.749,0,0,0-1.747,1.749Z" transform="translate(-294.577 -8.533)" fill="#7bce00"/>
                </g>
                <g  transform="translate(0 22.713)">
                    <g  transform="translate(0 0)">
                        <path d="M317.612,26.389H296.647a2.07,2.07,0,1,1,0-4.139h20.966a2.07,2.07,0,0,1,0,4.139Z" transform="translate(-294.577 -22.25)" fill="#7bce00"/>
                    </g>
                    <g  transform="translate(0 8.001)">
                        <path d="M317.612,31.221H296.647a2.07,2.07,0,1,1,0-4.139h20.966a2.07,2.07,0,0,1,0,4.139Z" transform="translate(-294.577 -27.082)" fill="#7bce00"/>
                    </g>
                    <g  transform="translate(0 16)">
                        <path d="M317.612,36.052H296.647a2.07,2.07,0,1,1,0-4.139h20.966a2.07,2.07,0,0,1,0,4.139Z" transform="translate(-294.577 -31.913)" fill="#7bce00"/>
                    </g>
                    <g  transform="translate(0 23.999)">
                        <path d="M317.612,40.883H296.647a2.07,2.07,0,1,1,0-4.139h20.966a2.07,2.07,0,0,1,0,4.139Z" transform="translate(-294.577 -36.744)" fill="#7bce00"/>
                    </g>
                    <g  transform="translate(0 32)">
                        <path d="M317.612,45.715H296.647a2.07,2.07,0,1,1,0-4.139h20.966a2.07,2.07,0,0,1,0,4.139Z" transform="translate(-294.577 -41.576)" fill="#7bce00"/>
                    </g>
                    <g  transform="translate(0 39.999)">
                        <path d="M317.612,50.546H296.647a2.07,2.07,0,1,1,0-4.139h20.966a2.07,2.07,0,0,1,0,4.139Z" transform="translate(-294.577 -46.407)" fill="#7bce00"/>
                    </g>
                    <g  transform="translate(0 47.998)">
                        <path d="M317.612,55.377H296.647a2.07,2.07,0,1,1,0-4.139h20.966a2.07,2.07,0,0,1,0,4.139Z" transform="translate(-294.577 -51.238)" fill="#7bce00"/>
                    </g>
                </g>
                <g  transform="translate(14.266 83.333)">
                    <path d="M318.246,72.333H305.263a2.068,2.068,0,0,1-2.07-2.07V60.931a2.069,2.069,0,0,1,2.07-2.07h12.983a2.07,2.07,0,0,1,2.07,2.07v9.332A2.069,2.069,0,0,1,318.246,72.333Zm-10.913-4.139h8.844V63h-8.844Z" transform="translate(-303.193 -58.861)" fill="#7bce00"/>
                </g>
                <g  transform="translate(76.451 83.333)">
                    <path d="M355.8,72.333H342.819a2.069,2.069,0,0,1-2.07-2.07V60.931a2.071,2.071,0,0,1,2.07-2.07H355.8a2.069,2.069,0,0,1,2.07,2.07v9.332A2.068,2.068,0,0,1,355.8,72.333Zm-10.913-4.139h8.844V63h-8.844Z" transform="translate(-340.749 -58.861)" fill="#7bce00"/>
                </g>
                <g  transform="translate(42.123 21.082)">
                    <path d="M347.717,76.666a27.7,27.7,0,1,1,27.7-27.7A27.732,27.732,0,0,1,347.717,76.666Zm0-51.262a23.561,23.561,0,1,0,23.564,23.56A23.588,23.588,0,0,0,347.717,25.4Z" transform="translate(-320.017 -21.265)" fill="#7bce00"/>
                </g>
                <g  transform="translate(49.816 28.773)">
                    <g  transform="translate(11.684 0)">
                        <g  transform="translate(0.001 19.001)">
                            <path d="M341.446,58.4H338.64a6.909,6.909,0,0,1-5.982-10.365l5.592-9.691a2.155,2.155,0,0,1,3.586,0l5.595,9.691A6.91,6.91,0,0,1,341.446,58.4Zm-1.4-14.882-3.8,6.587a2.7,2.7,0,0,0,0,2.77,2.736,2.736,0,0,0,2.4,1.386h2.807a2.771,2.771,0,0,0,2.4-4.156Z" transform="translate(-331.72 -37.385)" fill="#7bce00"/>
                        </g>
                        <g >
                            <path d="M340.043,47h0a2.073,2.073,0,0,1-1.793-1.035l-5.593-9.691A6.911,6.911,0,0,1,338.64,25.91h2.807a6.912,6.912,0,0,1,5.986,10.365l-5.6,9.691A2.069,2.069,0,0,1,340.043,47Zm-1.4-16.952a2.739,2.739,0,0,0-2.4,1.386,2.7,2.7,0,0,0,0,2.768l3.8,6.588,3.805-6.587a2.741,2.741,0,0,0,0-2.77,2.7,2.7,0,0,0-2.4-1.386Z" transform="translate(-331.719 -25.91)" fill="#7bce00"/>
                        </g>
                    </g>
                    <g  transform="translate(0 11.684)">
                        <g >
                            <path d="M331.573,49.616a6.936,6.936,0,0,1-6.91-6.921V39.887A6.909,6.909,0,0,1,335.027,33.9l9.693,5.6a2.07,2.07,0,0,1,0,3.585l-9.693,5.595A6.879,6.879,0,0,1,331.573,49.616Zm0-12.5a2.766,2.766,0,0,0-1.386.374,2.707,2.707,0,0,0-1.384,2.4V42.7a2.71,2.71,0,0,0,1.384,2.4,2.741,2.741,0,0,0,2.77,0l6.588-3.805-6.588-3.8A2.76,2.76,0,0,0,331.573,37.113Z" transform="translate(-324.663 -32.967)" fill="#7bce00"/>
                        </g>
                        <g  transform="translate(18.924)">
                            <path d="M350.276,49.616a6.9,6.9,0,0,1-3.456-.936l-9.693-5.6a2.07,2.07,0,0,1,0-3.585l9.693-5.6a6.908,6.908,0,0,1,10.364,5.984V42.7a6.933,6.933,0,0,1-6.908,6.921ZM342.3,41.291,348.89,45.1a2.768,2.768,0,0,0,4.154-2.4V39.887a2.768,2.768,0,0,0-4.154-2.4Z" transform="translate(-336.092 -32.967)" fill="#7bce00"/>
                        </g>
                    </g>
                </g>
            </svg>
            <div>{value ? value : "-"}</div>
            <div>kW</div>
        </IconWrapper>
    )
})

const IconWrapper = styled.div`
  font-family: ${NUVOSEMIFONT};
  color: ${NUVOTEXTBLUE};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default GensetIcon;
