import React from 'react';
import styled from "styled-components";
import Modal from "../Modal";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import useForgotPassword from "../../Hooks/useForgotPassword";
import useMobileView from "../../Hooks/useMobileView";

function SendResetPassword({setIsOpen, userEmail}) {
    const { isMobile } = useMobileView();
    const {handleSubmit, error} = useForgotPassword(userEmail, setIsOpen);

    return (
        <Modal onClose={() => setIsOpen(false)} width={isMobile ? '90%' : '30%'}>
            <h2>Are you sure you want to send the password reset email to user?</h2>
            <h3>"{userEmail}"</h3>
            {error && <ErrorMessage message={error}/>}
            <div style={{justifyContent: 'space-evenly', display: 'flex', marginTop: 16}}>
                <CloseButton onClick={() => setIsOpen(false)}>Close</CloseButton>
                <SaveButton onClick={handleSubmit}>Yes</SaveButton>
            </div>
        </Modal>
    );
}

export default SendResetPassword;

const SaveButton = styled(SubmitButton)`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
`;

const CloseButton = styled(SubmitButton)`
  width: 100px;
  background-color: #da3e3e;
  margin-top: 0;
  margin-bottom: 0;
`;
