import React, {useCallback} from 'react';
import styled from 'styled-components';
import {isLiveColor} from './handlers';
import {NUVOBLUE, NUVODARKGREY} from '../../constants/styles';
import {useNavigate} from 'react-router-dom';
import ButtonSpinner from '../ButtonComponents/BlueButtonSpinner';

const DataCard = ({data, loading, error, isMobile}) => {
    const navigate = useNavigate();
    const handleDetailClick = useCallback(
        (siteData) => {
            navigate(`/dashboard/site`, {state: {deviceId: siteData.deviceId}});
        },
        [navigate]
    );

    if (loading) return <Loading/>;
    if (error) return <ErrorState/>;

    return (
        <CardWrapper isMobile={isMobile}>
            {data.map((site) => (
                <Card
                    onClick={() => handleDetailClick(site)}
                    key={site.deviceId}
                    borderColor={isLiveColor(site.status)}
                >
                    <TopSection>
                        <Status color={isLiveColor(site.status)}>{site.status}</Status>
                    </TopSection>
                    <CardSection>
                        <SiteName>{site.deviceName}</SiteName>
                    </CardSection>
                    <CardSection>
                        <Text>Solar</Text>
                        <CurrentValue color={isLiveColor(site.status)}>{site.currentPower} kW</CurrentValue>
                    </CardSection>
                    <CardSection>
                        <Text>Grid</Text>
                        <CurrentValue>{site.gridPower} kW</CurrentValue>
                    </CardSection>
                    <CardSection>
                        <Text>Total</Text>
                        <TotalValue>{site.totalEnergy} kWh</TotalValue>
                    </CardSection>
                </Card>
            ))}
        </CardWrapper>
    );
};

const Loading = () => (
    <CardWrapper>
        <ButtonSpinner/>
    </CardWrapper>
);

const ErrorState = () => (
    <CardWrapper>
        <Error>
            Sorry something went wrong, please try to sign out and login again, if issue
            persists please contact the Nuvo Energy Team.
        </Error>
    </CardWrapper>
);

export default DataCard;

const CardWrapper = styled.div`
  display: flex;
  background-color: #EFEEEE;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: ${props => props.isMobile ? '70px' : '10px'};
`;

const Card = styled.div`
  height: auto;
  width: 160px;
  border: ${props => props.borderColor ? `1px solid ${props.borderColor}` : '0.2px solid #FFFFFF'};
  border-radius: 12px;
  background: #EFEEEE;
  margin-bottom: 20px;
  box-shadow: 26px 26px 16px 0 rgba(217, 210, 200, 0.51),
    -26px -26px 26px 0 rgba(255, 255, 255, 0.83);
  -webkit-box-shadow: 6px 6px 16px 0 rgba(217, 210, 200, 0.51),
    -6px -6px 26px 0 rgba(255, 255, 255, 0.83);
  -moz-box-shadow: 6px 6px 16px 0 rgba(217, 210, 200, 0.51),
    -6px -6px 26px 0 rgba(255, 255, 255, 0.83);
  padding: 5px;
  cursor: pointer;
  z-index: 1;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 30px;
  width: 100%;
`;

const CardSection = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  width: 100%;
`;

const Status = styled.div`
  color: ${props => props.color ? props.color : '#EFEEEE'};
  font-size: 12px;
`;

const SiteName = styled.div`
  color: ${NUVOBLUE};
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Text = styled.div`
  color: ${NUVODARKGREY}
`;

const CurrentValue = styled.div`
  color: ${props => props.color ? props.color : `${NUVOBLUE}`};
  font-weight: bold;
`;

const TotalValue = styled.div`
  color: ${NUVOBLUE};
  font-weight: bold;
`;

const Error = styled.div`
  color: red;
  padding: 20px;
  text-align: center;
`;
