import {gql, useQuery} from "@apollo/client";

const GET_SITE_DATA = gql`
    query GetSiteData ($deviceId: String!) {
        getSiteData (deviceId: $deviceId) {
            seconds_past_now
            mac_id
            name
            now
            last_update
            online_check
            seconds_past_now
            genset_meter
            genset_meter_nr
        }
    }
`;

const useGetSiteData = (deviceId) => {
    const {data, loading, error} = useQuery(GET_SITE_DATA, {
        variables: {deviceId: deviceId}
    });

    const siteData = data?.getSiteData;

    if (error) {
        console.error("Failed to fetch site data:", error);
    }

    return {siteData, loading};
};

export default useGetSiteData;
