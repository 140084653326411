import KpiCard from "./KpiCard";
import useDailyEnergy from "../Hooks/useDailyEnergy";
import useMobileView from "../Hooks/useMobileView";
import {NUVOGREEN} from "../../constants/styles";


const TotalEnergyKpi = ({ deviceId }) => {
    const { isMobile } = useMobileView();
    const description = isMobile ? "Total" : "Lifetime Solar Production";
    const { totalSolarProduction, loading } = useDailyEnergy(deviceId);
    const value = loading ? "-" : totalSolarProduction;
    return <KpiCard items={[{description: description, value, unit: 'kWh', color: NUVOGREEN}]}/>
}

export default TotalEnergyKpi
