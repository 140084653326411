import {useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {useNavigate} from "react-router-dom";

const FORGOT_PASSWORD = gql`
    mutation ForgotPasswordCheck ($token: String!, $password: String!) {
        forgotPasswordCheck(token: $token, password: $password) {
            success
            message
            error
        }
    }
`;

const usePasswordReset = (token) => {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        password: '',
        confirmPassword: '',
    })
    const [error, setError] = useState(null);

    const [resetPassword] = useMutation(FORGOT_PASSWORD, {
        onError(error) {
            setError(error.message)
        },
        onCompleted() {
            navigate('/')
        },
        variables: {password: values.password, token}
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    function validatePassword(password) {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        return regex.test(password);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (values.password === values.confirmPassword) {
            if (validatePassword(values.password)) {
                resetPassword()
                    .catch(error => console.log(error))
            } else setError('Weak password')
        } else {
            setError('Passwords do not match !')
        }
    }
    return {handleChange, handleSubmit, values, error}
}

export default usePasswordReset;
