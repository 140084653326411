import styled from "styled-components";
import Spinner from "../../Spinner/Spinner";
import {NUVOWHITE, NUVOHEADERBLUE, NUVOBOLDFONT} from "../../../constants/styles";
import MonthlyBarChart from "../../SiteChart/MonthlyBarChart";
import {useCallback, useContext, useEffect, useState} from "react";
import {DateContext} from "../../../context/DateContext";
import moment from "moment";
import ChartError from "./ChartError";
import DayPicker from "../DayPicker/DayPicker";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import {ChartDurationContext} from "../../../context/ChartDurationContext";
import useGetGridGensetRunningTotalByAnyDate from "../../Hooks/useGetGridGensetRunningTotalByAnyDate";
import GridDataCard from "../../GridCard/GridDataCard";
import MeterTable from "../../Table/MeterTable";

const chartColorsImportExport = [
    '#4a87f9',
    '#FF9138',
    '#ae6bec',
];

const Grid = ({deviceId, isGensetMeterSite}) => {
    const { monthDate, YTDDate } = useContext(DateContext);
    const { chartDuration, updateDuration } = useContext(ChartDurationContext);
    const [chartTypeToggle, setChartTypeToggle] = useState(false);
    const CHART_TIME = {
        '7D': {
            duration: moment().subtract(7, 'days').toDate(),
            dateTrunc: 'week'
        },
        '1M': {
            duration: moment(monthDate || new Date()).startOf('month').toDate(),
            dateTrunc: 'month'
        },
        'YTD': {
            duration: moment(YTDDate || new Date()).startOf('year').toDate(),
            dateTrunc: 'year'
        }
    };
    const [dateForRunningTotalQuery, setDateForRunningTotalQuery] = useState(CHART_TIME[chartDuration] || {});

    const {
        gridGensetRunningTotals,
        error,
        importReading,
        exportReading,
        gensetReading,
        gensetImportTotal,
        gensetRunningMinutes,
        gridImportTotal,
        exportTotal,
        loading
    } = useGetGridGensetRunningTotalByAnyDate(
        deviceId,
        dateForRunningTotalQuery.dateTrunc,
        moment(dateForRunningTotalQuery.duration).format('YYYY-MM-DD'),
        isGensetMeterSite
    );

    const onChartChange = useCallback((checked) => {
        setChartTypeToggle(checked);
    }, []);

    const handleChartTimeState = useCallback((time) => {
        updateDuration(time);
    }, [updateDuration]);

    const handleChartTimeActive = useCallback((state) => {
        return state === chartDuration;
    }, [chartDuration]);

    useEffect(() => {
        setDateForRunningTotalQuery(CHART_TIME[chartDuration] || {});
    }, [chartDuration, monthDate, YTDDate]);

    if (error) {
        return (
            <>
                <DayPicker chartDuration={chartDuration}/>
                <SiteChartWrapperLarge>
                    <CardHeader> Grid </CardHeader>
                    <ChartError/>
                </SiteChartWrapperLarge>
            </>
        )
    }

    return (
        <SiteChartCardLarge>
            <DateWrapper>
                <DayPicker chartDuration={chartDuration}/>
            </DateWrapper>
            <ControlsWrapper>
                <ChartTimeWrapper>
                    <ChartTimeSelector onClick={() => {
                        handleChartTimeState('7D')
                    }} active={handleChartTimeActive('7D')}>7D</ChartTimeSelector>
                    <ChartTimeSelector onClick={() => {
                        handleChartTimeState('1M')
                    }} active={handleChartTimeActive('1M')}>1M</ChartTimeSelector>
                    <ChartTimeSelector onClick={() => {
                        handleChartTimeState('YTD')
                    }} active={handleChartTimeActive('YTD')}>YTD</ChartTimeSelector>
                </ChartTimeWrapper>
                <ToggleWrapper>
                    <ToggleText>CHECK METERS</ToggleText>
                    <ToggleSwitch name={'chartType'} checked={chartTypeToggle} onChange={onChartChange}/>
                </ToggleWrapper>
            </ControlsWrapper>
            <SiteChartWrapperLarge>
                <CardHeader>
                    <GridDataCard value={gridImportTotal.toLocaleString('en-US', {maximumFractionDigits: 2}) + ` kWh`}
                                  labelColour={'#4a87f9'} label={"Grid Import"}/>
                    <GridDataCard value={gensetImportTotal.toLocaleString('en', {maximumFractionDigits: 2}) + ` kWh`}
                                  labelColour={'#FF9138'} label={"Genset Import"}>
                        {isGensetMeterSite ? null : <GridImportText>{gensetRunningMinutes} mins</GridImportText>}
                    </GridDataCard>
                    <GridDataCard value={exportTotal.toLocaleString('en', {maximumFractionDigits: 2}) + ` kWh`}
                                  labelColour={'#ae6bec'} label={"Grid Export"}/>
                    <GridDataCard type='large' labelColour={'#5D5D5D'}>
                        <ReadingWrapper>
                            <MeterTable isGensetMeterSite={isGensetMeterSite} importData={{...importReading}}
                                        exportData={{...exportReading}} gensetData={{...gensetReading}}>
                            </MeterTable>
                        </ReadingWrapper>
                    </GridDataCard>
                </CardHeader>
                {
                    loading
                        ? <Spinner/>
                        : (<ChartWrapper>
                                <MonthlyBarChart
                                    colors={chartColorsImportExport}
                                    groupMode={'grouped'}
                                    keys={isGensetMeterSite ? ['grid_import', 'm2_import_kwh', 'export_kwh'] : ['grid_import', 'genset_import', 'export_kwh']}
                                    data={{gridGensetRunningTotals}}
                                    showCheckMeter={chartTypeToggle}
                                    duration={chartDuration}
                                    labelSkipWidth={25}
                                    type={'grid'}/>
                            </ChartWrapper>
                        )
                }
            </SiteChartWrapperLarge>
        </SiteChartCardLarge>
    )
}

export default Grid;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
`;

const ChartTimeWrapper = styled.div`
  display: flex;
`;

const ChartTimeSelector = styled.div`
  margin-right: 20px;
  color: ${props => props.active ? `#1976d2` : '#5D5D5D'};
  border-bottom: ${props => props.active ? `2px solid #1976d2` : 'none'};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
`;

const GridImportText = styled.span`
  color: #FF9138;
`;

const SiteChartCardLarge = styled.div`
  width: 100%;
  height: 80%;
`;

const ReadingWrapper = styled.div`
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SiteChartWrapperLarge = styled.div`
  background-color: ${NUVOWHITE};
  width: 100%;
  margin: 10px auto 0 auto;
  padding-bottom: 20px;
  height: 550px;
  border: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
`;

const ToggleWrapper = styled.div`
  text-align: center;
  margin-left: 100px;
`;

const ToggleText = styled.div`
  color: #5D5D5D;
  font-size: 8px;
  font-weight: bold;
  min-width: 78px;
`;

const CardHeader = styled.div`
  color: ${NUVOHEADERBLUE};
  background-color: #f8f9fa;
  font-family: ${NUVOBOLDFONT};
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  height: 100px;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 80%;
`;
