import React, {useState} from 'react';
import styled from 'styled-components';
import {Icon} from "@iconify/react";

function ModalPortal({children, icon, title, color, noNeedSpace, renderItem}) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            {!renderItem ? <Span title={title} noNeedSpace={noNeedSpace}>
                <IconX icon={icon} color={color} onClick={(e) => {
                    e.stopPropagation()
                    setIsOpen(true)
                }}/>
            </Span> : renderItem(setIsOpen)}
            {isOpen && children(setIsOpen)}
        </>
    );
}

export default ModalPortal

const Span = styled.span`
  margin-right: ${props => props.noNeedSpace ? '' : '20px'};
`;

const IconX = styled(Icon)`
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 5px;

  &:hover {
    background-color: rgba(0, 0, 12, 0.13);
  }
`;
