import { useMemo } from "react";
import useGetAllSites from "./useGetAllSites";
import { isLiveHandler } from "../DataGrid/handlers";

const useFetchAllSiteDataForAdminUser = () => {
    const { data, loading, error } = useGetAllSites();

    const fetchAdminSiteData = useMemo(() => {
        if (data) {
            const {getAllSites} = data;
            return  getAllSites.map((object) => {
                const totalEnergy = object.total_kwh_active
                    ? object.total_kwh_active.toFixed(0)
                    : null;
                return {
                    status: isLiveHandler(object.seconds_past_now, object.online_check),
                    deviceName: object.name,
                    deviceId: object.mac_id,
                    location: object.location,
                    inverterCount: object.inverter_count,
                    currentPower: Math.round(object.total_ac_active_power / 1000),
                    gridPower: Math.round(object.grid_power / 1000),
                    totalEnergy: totalEnergy
                        ? new Intl.NumberFormat().format(totalEnergy)
                        : null,
                    actions: object.mac_id,
                    date_time: object.date_time,
                    seconds_past_now: object.seconds_past_now,
                    online_check: object.online_check,
                    last_update: object.last_update ? object.last_update.slice(0, -6) : null,
                    latitude: object.latitude,
                    longitude: object.longitude,
                };
            });
        } else return [];

    }, [data]);



    return { data: fetchAdminSiteData, loading, error};
};

export default useFetchAllSiteDataForAdminUser;
