import React, {useContext, useState} from 'react';
import styled from "styled-components";
import Modal from "../Modal";
import useAdminResetPassword from "../../Hooks/useAdminResetPasssword";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import FormLabel from "../../FormComponents/FormLabel";
import Input from "../../FormComponents/Input";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import FormContainer from "../../FormComponents/FormContainer";
import {NUVOREGULARFONT} from "../../../constants/styles";
import UserContext from "../../../context/UserContext";
import useMobileView from "../../Hooks/useMobileView";

function ResetPasswordAdmin({setIsOpen, user}) {
    const { isMobile } = useMobileView();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const {adminResetPassword, loading, error, data} = useAdminResetPassword(setIsOpen)
    const { user: me } = useContext(UserContext);

    return (
        <Modal onClose={() => setIsOpen(false)} width={isMobile ? '90%' : '25%'}>
            <h1>Reset Password</h1>
            <h3>Enter the new password for the user:</h3>
            <h4>{user.email}</h4>
            <FormContainer noValidate className='form'>
                <FormLabel htmlFor="email" name="Password*"/>
                <Input
                    name="password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                    fullWidth
                />
                <FormLabel htmlFor="confirmEmail" name="Confirm Password*"/>
                <Input
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    required
                    fullWidth
                />
            </FormContainer>
            {error && <ErrorMessage message={error.message}/>}
            <UList>
                <li>At least 8 characters long</li>
                <li>Contains at least one uppercase letter</li>
                <li> Contains at least one lowercase letter</li>
                <li>Contains at least one number</li>
            </UList>
            <ButtonContainer>
                <CloseButton onClick={() => setIsOpen(false)}>Close</CloseButton>
                <SaveButton onClick={() => adminResetPassword(me.username, user.email, password)}
                            disabled={!password || !confirmPassword || (password !== confirmPassword) || password.length < 8}>Yes</SaveButton>
            </ButtonContainer>
        </Modal>
    );
}

export default ResetPasswordAdmin;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const SaveButton = styled(SubmitButton)`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
`;

const CloseButton = styled(SubmitButton)`
  width: 100px;
  background-color: #da3e3e;
  margin-top: 0;
  margin-bottom: 0;
`;

const UList = styled.ul`
  font-family: ${NUVOREGULARFONT};
  font-size: 13px;
  text-align: justify;
  color: #06427d;
`