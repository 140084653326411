import styled from "styled-components";

const ErrorMessage = ({message}) => {
    return <SpanMessage message={message}>{message}</SpanMessage>
}

export default ErrorMessage;

const SpanMessage = styled.span`
  height: 10px;
  font-size: 12px;
  color: red;
  padding: 8px;
`;