import useDailyConsumption from "../Hooks/useDailyConsumption";
import KpiCard from "./KpiCard";
import {NUVOGREY} from "../../constants/styles";

const ImportExportKpi = ({deviceId}) => {
    const {totalImport, totalExport, loading} = useDailyConsumption(deviceId);
    const totalImportValue = loading ? "-" : `${totalImport.toLocaleString() + ' kWh'}`;
    const totalExportValue = loading ? "-" : `${totalExport.toLocaleString() + ' kWh'}`;
    return <KpiCard items={[
        {description: 'Total Import', value: totalImportValue, color: NUVOGREY},
        {description: 'Total Export', value: totalExportValue, color: 'orangered'}
    ]}/>
}

export default ImportExportKpi;
