import React from 'react';
import styled from 'styled-components';
import {useQuery} from "@apollo/client";
import {GET_SHARED_USERS} from "./queries";
import Spinner from "../../Spinner/Spinner";
import {NUVOBOLDFONT} from "../../../constants/styles";
import Header1Blue from "../../TextComponents/Header1";
import {useOwnerUnAssignSiteUsers} from "../../Hooks/useOwnerUnAssignSiteUsers";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import Modal from "../Modal";
import useMobileView from "../../Hooks/useMobileView";

function SharedWith({deviceId, setIsOpen}) {
    const {isMobile} = useMobileView();
    const {loading, data, error} = useQuery(GET_SHARED_USERS, {
        variables: {site_id: deviceId},
    })
    const {unAssignSitesUsers} = useOwnerUnAssignSiteUsers()
    if (loading) return <Modal width='40%'><Spinner/></Modal>
    return (
        <Modal onClose={() => setIsOpen(false)} width={isMobile ? '90%' : '40%'}>
            {data.getSharedUsers.length === 0 ? <Header1Blue>This site is not shared</Header1Blue> : <>
                <Header1Blue>Shared With</Header1Blue>
                <TableContainer>
                    <StyledTable>
                        <StyledHeader>
                            <tr>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Actions</th>
                            </tr>
                        </StyledHeader>
                        <tbody>
                        {data.getSharedUsers.map((user) => (
                            <tr key={user.id}>
                                <StyledCell>{user.name}</StyledCell>
                                <StyledCell>{user.username}</StyledCell>
                                <StyledCell>
                                    <RemoveButton
                                        onClick={() => unAssignSitesUsers([user.id], deviceId)}>Remove</RemoveButton>
                                </StyledCell>
                            </tr>
                        ))}
                        </tbody>
                    </StyledTable>
                </TableContainer>
                <CancelButton onClick={() => setIsOpen(false)}>Close</CancelButton></>}
        </Modal>
    );
}

export default SharedWith;

const TableContainer = styled.div`
  max-height: 55vh;
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #979797;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: ${NUVOBOLDFONT};
  
`;

const StyledHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const StyledCell = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const RemoveButton = styled.button`
  background-color: #f44336;
  color: white;
  padding: 6px 12px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #da190b;
  }
`;

const CancelButton = styled(SubmitButton)`
  background-color: #60ab25;
  width: auto;
  margin-bottom: 0;
  padding: 6px 12px;
`;
