import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import PublicRoutes from './PublicRoutes';
import SuperAdminR from "./SuperAdminRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export function AuthRoutes() {
    return useRoutes(MainRoutes, config.basename);
}

export function UnAuthRoutes() {
    return useRoutes(PublicRoutes, config.basename);
}

export function SuperAdminRoutes() {
    return useRoutes(SuperAdminR, config.basename);
}

const config = {
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
}
