import React, {useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import SitesMap from "../SitesMap/SitesMap";
import SiteKpi from "../SiteKpi/SiteKpi";
import SignOut from "../ButtonComponents/SignOut";
import {NUVOBOLDFONT, NUVOGREEN, NUVOHEADERBLUE, NUVOWHITE} from "../../constants/styles";
import DataCard from "../DataGrid/DataCard";
import DataGrid from "../DataGrid/DataGrid";
import usePermission from "../Hooks/useGetPermissions";
import {SUPER_ADMIN_ROLE} from "../../helpers/variables";
import withData from "../DataGrid/withData";
import useFetchSiteDataForUser from "../Hooks/useFetchSiteDataForUser";
import useFetchAllSiteDataForAdminUser from "../Hooks/useFetchAllSiteDataForAdminUser";
// wrap DataCard with data fetching hooks
const AdminDataCard = withData(DataCard, useFetchAllSiteDataForAdminUser);
const GuestDataCard = withData(DataCard, useFetchSiteDataForUser);
const AdminDataGrid = withData(DataGrid, useFetchAllSiteDataForAdminUser);
const GuestDataGrid = withData(DataGrid, useFetchSiteDataForUser);

const Overview = ({isMobile}) => {
    const hasAdminPermission = usePermission(SUPER_ADMIN_ROLE);
    const navigate = useNavigate();

    const DataComponent = useMemo(() => {
        if (isMobile) {
            return hasAdminPermission ? AdminDataCard : GuestDataCard;
        } else {
            return hasAdminPermission ? AdminDataGrid : GuestDataGrid;
        }
    }, [isMobile, hasAdminPermission]);

    return (
        <DashboardContent isMobile={isMobile}>
            <DashBoardHeaderWrapper>
                <DashBoardHeader>Dashboard</DashBoardHeader>
                <SignOut>Sign-out</SignOut>
            </DashBoardHeaderWrapper>
            <TopWrapper>
                {!isMobile && <SitesMap/>}
                <SiteKpi isMobile={isMobile}/>
            </TopWrapper>
            <DataGridTableWrapper>
                <AddDeviceWrapper>
                    <DataGridHeader>Site List</DataGridHeader>
                    <AddDeviceButton onClick={() => navigate('/dashboard/addDevice')}> Add Device </AddDeviceButton>
                </AddDeviceWrapper>
                <DataComponent isMobile={isMobile}/>
            </DataGridTableWrapper>
        </DashboardContent>
    )
}

export default React.memo(Overview);

const DashboardContent = styled.div`
  width: 100%;
  background-color: #f5f6f8;
`;

const DashBoardHeaderWrapper = styled.div`
  background-color: ${NUVOWHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 14px;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
  margin-bottom: 40px;
`;

const DashBoardHeader = styled.h1`
  margin: 0 0 0 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto auto 40px auto;
  width: 95%;
  height: 300px;
`;

const DataGridTableWrapper = styled.div`
  background-color: ${NUVOWHITE};
  width: 95%;
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2)
`;

const AddDeviceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px;
`;

const DataGridHeader = styled.div`
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  display: flex;
  font-size: 14px;
`;

const AddDeviceButton = styled.button`
  width: 130px;
  height: 40px;
  background-color: ${NUVOGREEN};
  color: ${NUVOWHITE};
  border: none;
  border-radius: 3px;
  letter-spacing: 1.5px;
  cursor: pointer;
`;
