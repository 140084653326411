import React, {useState} from 'react';
import Header1Blue from "../../TextComponents/Header1";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import styled from "styled-components";
import useMobileView from "../../Hooks/useMobileView";
import {gql, useMutation, useQuery} from "@apollo/client";
import {GET_ALL_USERS} from "../Users/UsersTable";
import Spinner from "../../Spinner/Spinner";
import {GET_ALL_SITES } from "./queries";
import Input from "../../FormComponents/Input";
import Modal from "../Modal";

function ChangeOwner({deviceId, deviceName, owner, setIsOpen}) {
    const {isMobile} = useMobileView();
    const [filter, setFilter] = useState('');
    const {loading, error, data} = useQuery(GET_ALL_USERS);
    const [userIdSelected, setUserIdSelected] = useState(null);
    const [AddDevice] = useMutation(CHANGE_SITE_OWNER, {
        variables: {user_id: userIdSelected, site_id: deviceId},
        refetchQueries: [
            GET_ALL_SITES,
            'getAllSites'
        ],
        onCompleted() {
            setIsOpen && setIsOpen(false)
        },
    })
    if (loading) return <Modal><Spinner/></Modal>
    const onSelectUser = (userId) => {
        setUserIdSelected(userId)
    }
    const filteredUsers = data.getAllUsers.filter(user => user.name?.toLowerCase().includes(filter.toLowerCase()) || user.username.toLowerCase().includes(filter.toLowerCase()));

    return (
        <Modal onClose={() => setIsOpen(false)} width={isMobile ? '90%' : '30%'}>
            <Header1Blue>Change Owner</Header1Blue>
            <p>Current owner: {owner || 'No owner'}</p>
            <p>{deviceName}</p>
            <Input placeholder="Search by name or username" type="text" value={filter}
                   onChange={event => setFilter(event.target.value)} fullWidth={true}/>
            <Column>
                {filteredUsers.map(user => (
                    <Site
                        key={user.id}
                        onClick={() => onSelectUser(user.id)}
                        isSelected={userIdSelected === user.id || (!userIdSelected && owner === user.username)}
                    >
                        <SiteText>{user.username} ({user.name})</SiteText>
                    </Site>
                ))}
            </Column>

            <ButtonWrapper>
                <SubmitButton onClick={AddDevice} disabled={!userIdSelected}>
                    Change Owner
                </SubmitButton>
            </ButtonWrapper>
        </Modal>
    );
}

export default ChangeOwner;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  height: 45vh;
  overflow-y: scroll;
  background-color: #f6f8fa;
  margin-top: 16px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #979797;
  }
`;

const Site = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: ${({isSelected}) => (isSelected ? '2px solid #181879' : 'none')};
`;

const SiteText = styled.span`
  color: #181879;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  width: 100%;
`;

const CHANGE_SITE_OWNER = gql`
    mutation ChangeSiteOwner($user_id: String!, $site_id: Int!) {
        assignSiteOwner(user_id: $user_id, site_id: $site_id) {
            success
            message
            error
        }
    }
`;
