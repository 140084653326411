import styled from "styled-components";
import {NUVOTEXTBLUE} from "../../constants/styles";

const FormLabel = (props) => <Label>{props.name}</Label>

const Label = styled.label`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  color: ${NUVOTEXTBLUE};
`;

export default FormLabel;