import React from "react";
import styled from "styled-components";

const FilterSelect = ({ value, onChange }) => {

    const handleChange = (e) => {
        const newValue = e.target.value === 'true' ? true : e.target.value === 'false' ? false : null;
        onChange(newValue);
    }

    return (
        <Select value={value} onChange={handleChange}>
            <option value="false">Active Users</option>
            <option value="true">Inactive Users</option>
        </Select>
    );
};

export default FilterSelect;

const Select = styled.select`
  font-size: 13px;
  padding: 6px;
  border: 2px solid #dddddd;
  border-radius: 5px;
  margin-left: auto;
  height: 42px;
`;
