import styled from "styled-components"
import { NUVORED } from "../../../constants/styles";

const ChartError =  () => {
    return (
        <>
        <SiteChartCardLarge>
            <ErrorMessageWrapper>
                <ErrorMessageText>
                Sorry, something went wrong when fetching the 
                    data for this chart, please navigate back to the dashboard or try sign out then log back in again, 
                    if the problem persists, please contact your Installer or someone from Nuvo Energy Test Test.
                </ErrorMessageText>
            </ErrorMessageWrapper>
        </SiteChartCardLarge>
    </>
    )
}

export default ChartError;

const SiteChartCardLarge = styled.div`
  width: 100%;
  height: 80%;
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ErrorMessageText = styled.div`
  width: 60%;
  color: ${NUVORED};
  font-weight: bold;
`;