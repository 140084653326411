
const validateShareDevice = (values) => {
    console.log(values);
    const errors = {}
    const regEx = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

    if (!values.email) {
        errors.email = "Email is required";
    } else if (!regEx.test(values.email)) {
        errors.email = "Email address is invalid";
    }

    if (!values.email2 || values.email !== values.email2) {
        errors.email2 = "Emails need to match";
    }

    if(!values.checkbox) {
        errors.checkbox = "confirmation required before sharing site"
    }

    return errors;
}

export default validateShareDevice;
