
import styled from "styled-components";
import {NUVOBOLDFONT, NUVOGREEN, NUVOWHITE} from "../../constants/styles";

const UnderScoreButton = ({ setLoginActive, text }) => {

    return <ButtonWithUnderscore onClick={setLoginActive}>{text}</ButtonWithUnderscore>
}

export default UnderScoreButton;

const ButtonWithUnderscore = styled.button`
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  color: ${NUVOGREEN};
  padding: 0;
  margin-left: 5px;
  font-weight: bold;
`;
