import {gql, useQuery} from '@apollo/client';
import {POLL_INTERVAL} from '../../constants/apollo';

const GET_ENERGY_FLOW_DATA = gql`
  query GetEnergyFlowData($deviceId: String!) {
    getEnergyFlowData(deviceId: $deviceId ) {
      isLive
      eb_state
      inv_active_power
      meter_active_power
      meter2_active_power
      cons_active_power
    }
  }
`;

const useGetEnergyFlowData = (deviceId, isLive) => {
  return useQuery(GET_ENERGY_FLOW_DATA, {
    variables: {deviceId: deviceId},
    pollInterval: POLL_INTERVAL,
    skip: !isLive
  });
}

export default useGetEnergyFlowData;
