import {gql, useQuery} from "@apollo/client";

export const GET_SHARED_USER_SITES = gql`
    query GetSharedSites($user_id: String!) {
        getSharedSites(user_id: $user_id) {
            mac_id
            name
            location
            date_time
            inverter_count
            total_ac_active_power
            total_kwh_active
            seconds_past_now
            last_update
            grid_power
            online_check
            latitude
            longitude
            genset_meter
            genset_meter_nr
        }
    }
`;


const useSharedUserSites = ({ id }) => {
    const {data, loading, error} = useQuery(GET_SHARED_USER_SITES, {
        variables: { user_id: id },
        nextFetchPolicy: 'network-only',
        onError: (error) => {
            console.log('Error, on GET_SHARED_USER_SITES:', error);
        },
    })

    return { data, loading, error };
}

export default useSharedUserSites;
