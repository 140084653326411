import styled from "styled-components";
import { useEffect, useState } from "react";
import { NUVOGREEN, NUVOWHITE } from "../../constants/styles";
import React from "react";
import { gql, useMutation } from "@apollo/client";
import ButtonSpinner from "../ButtonComponents/ButtonSpinner";
import { useCSVDownloader } from "react-papaparse";

const EXPORT_RAW_METER_DATA = gql`
  mutation ExportRawMeterData(
    $deviceId: String!
    $startDate: String!
    $endDate: String!
  ) {
    exportRawMeterData(
      deviceId: $deviceId
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        id
        mac_id
        meter_nr
        ac_active_power
        ac_apparent_power
        ac_reactive_power
        ac_active_power_a
        ac_active_power_b
        ac_active_power_c
        ac_current_a
        ac_current_b
        ac_current_c
        ac_frequency
        ac_voltage_an
        ac_voltage_bn
        ac_voltage_cn
        kwh_total_export
        kwh_total_import
        ac_pf
        eb_state
        check_meter
        date_time
        day
      }
    }
  }
`;

const EXPORT_RAW_INVERTER_DATA = gql`
  mutation ExportRawInverterData(
    $deviceId: String!
    $startDate: String!
    $endDate: String!
  ) {
    exportRawInverterData(
      deviceId: $deviceId
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        id
        mac_id
        inverter_nr
        ac_active_power
        ac_apparent_power
        ac_reactive_power
        ac_current
        ac_pf
        event_code
        percent
        status_code
        temperature_internal
        kwh_total_active
        dc_voltage_1
        dc_current_1
        dc_voltage_2
        dc_current_2
        dc_voltage_3
        dc_current_3
        dc_voltage_4
        dc_current_4
        date_time
        eb_state
        day
      }
    }
  }
`;

const EXPORT_RAW_RADIANCE_DATA = gql`
  mutation ExportRawRadianceData(
    $deviceId: String!
    $startDate: String!
    $endDate: String!
  ) {
    exportRawRadianceData(
      deviceId: $deviceId
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        mac_id
        weather1_status_code
        weather1_GHI
        weather1_DNI
        weather1_device_temp
        weather1_panel_temp
        day
      }
    }
  }
`;

const EXPORT_SUMMARY_BY_HOUR = gql`
  mutation ExportSummaryByHour(
    $deviceId: String!
    $startDate: String!
    $endDate: String!
  ) {
    exportSummaryByHour(
      deviceId: $deviceId
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        date_hour
        inv1_ac_active_power
        inv2_ac_active_power
        inv3_ac_active_power
        inv4_ac_active_power
        inv5_ac_active_power
        inv6_ac_active_power
        inv1_kwh_total_active
        inv2_kwh_total_active
        inv3_kwh_total_active
        inv4_kwh_total_active
        inv5_kwh_total_active
        inv6_kwh_total_active
        mtr1_kwh_total_import
        mtr1_kwh_total_export
        mtr2_kwh_total_import
        mtr2_kwh_total_export
        mtr3_kwh_total_import
        mtr3_kwh_total_export
      }
    }
  }
`;

const EXPORT_SUMMARY_BY_MINUTE = gql`
  mutation ExportSummaryByMinute(
    $deviceId: String!
    $startDate: String!
    $endDate: String!
  ) {
    exportSummaryByMinute(
      deviceId: $deviceId
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        date_hour
        inv1_ac_active_power
        inv2_ac_active_power
        inv3_ac_active_power
        inv4_ac_active_power
        inv5_ac_active_power
        inv6_ac_active_power
        inv1_kwh_total_active
        inv2_kwh_total_active
        inv3_kwh_total_active
        inv4_kwh_total_active
        inv5_kwh_total_active
        inv6_kwh_total_active
        mtr1_kwh_total_import
        mtr1_kwh_total_export
        mtr2_kwh_total_import
        mtr2_kwh_total_export
        mtr3_kwh_total_import
        mtr3_kwh_total_export
      }
    }
  }
`;

const DataExportModal = ({ handleClose, show, header, type, deviceId }) => {
  const [values, setValues] = useState({
    startDate: "",
    endDate: "",
  });
  const args = {
    deviceId: deviceId,
    startDate: values.startDate,
    endDate: values.endDate,
  };
  const { CSVDownloader, Type } = useCSVDownloader();
  // using onComplete on useMutation to set out own data varables so we can clear them if the date changes
  const [rawMeterData, setRawMeterData] = useState(null);
  const [rawInverterData, setRawInverterData] = useState(null);
  const [rawRadianceData, setRawRadianceData] = useState(null);
  const [summaryByHourData, setSummaryByHourData] = useState(null);
  const [summaryByMinuteData, setSummaryByMinuteData] = useState(null);
  const [rawMeterDataReady, setRawMeterDataReady] = useState(false);
  const [rawInverterDataReady, setRawInverterDataReady] = useState(false);
  const [rawRadianceDataReady, setRawRadianceDataReady] = useState(false);
  const [summaryByHourReady, setSummaryByHourReady] = useState(false);
  const [summaryByMinuteReady, setSummaryByMinuteReady] = useState(false);

  const [GetRawMeterData, RawMeterData] = useMutation(EXPORT_RAW_METER_DATA, {
    variables: args,
    onCompleted: (data) => {
      setRawMeterData(data);
    },
  });
  const [GetRawInverterData, RawInverterData] = useMutation(
    EXPORT_RAW_INVERTER_DATA,
    {
      variables: args,
      onCompleted: (data) => {
        setRawInverterData(data);
      },
    }
  );

  const [GetRawRadianceData, RawRadianceData] = useMutation(
    EXPORT_RAW_RADIANCE_DATA,
    {
      variables: args,
      onCompleted: (data) => {
        setRawRadianceData(data);
      },
    }
  );

  const [GetSummaryByHour, SummaryByHour] = useMutation(
    EXPORT_SUMMARY_BY_HOUR,
    {
      variables: args,
      onCompleted: (data) => {
        setSummaryByHourData(data);
      },
    }
  );

  const [GetSummaryByMinute, SummaryByMinute] = useMutation(
    EXPORT_SUMMARY_BY_MINUTE,
    {
      variables: args,
      onCompleted: (data) => {
        setSummaryByMinuteData(data);
      },
    }
  );

  useEffect(() => {
    if (rawMeterData) setRawMeterDataReady(true);
    if (rawInverterData) setRawInverterDataReady(true);
    if (rawRadianceData) setRawRadianceDataReady(true);
    if (summaryByHourData) setSummaryByHourReady(true);
    if (summaryByMinuteData) setSummaryByMinuteReady(true);
  }, [
    rawMeterData,
    rawInverterData,
    rawRadianceData,
    summaryByHourData,
    summaryByMinuteData,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setValues({
      ...values,
      [name]: value,
    });
    // reset useMutation data if user changes start or end date
    if (rawMeterData) {
      setRawMeterData(null);
      setRawMeterDataReady(false);
    }
    if (rawInverterData) {
      setRawInverterData(null);
      setRawInverterDataReady(false);
    }
    if (rawRadianceData) {
      setRawRadianceData(null);
      setRawRadianceDataReady(false);
    }
    if (summaryByHourData) {
      setSummaryByHourData(null);
      setSummaryByHourReady(false);
    }
    if (summaryByMinuteData) {
      setSummaryByMinuteData(null);
      setSummaryByMinuteReady(false);
    }
  };

  const handleRawMeterFetch = async () => {
    await GetRawMeterData().catch((e) => console.log(e));
  };

  const handleRawInverterFetch = async () => {
    await GetRawInverterData().catch((e) => console.log(e));
  };

  const handleRawRadianceFetch = async () => {
    await GetRawRadianceData().catch((e) => console.log(e));
  };

  const handleSummaryByHourFetch = async () => {
    await GetSummaryByHour().catch((e) => console.log(e));
  };

  const handleSummaryByMinuteFetch = async () => {
    await GetSummaryByMinute().catch((e) => console.log(e));
  };

  return (
    <ModalWrapper show={show}>
      <Section>
        <ModelContentWrapper>
          <Header>{header}</Header>
          <Text>Select Date Range</Text>
          <Form>
            <Label>Start Date</Label>
            <input type="date" name="startDate" onChange={handleChange} />
            <Label>End Date</Label>
            <input type="date" name="endDate" onChange={handleChange} />
          </Form>
          <ExportButtonWrapper>
            <ExportButtonRow>
              {rawMeterDataReady ? (
                <ExportButton color="#56C46C">
                  <CSVDownloader
                    filename={`meter_raw`}
                    data={rawMeterData.exportRawMeterData.records}
                  >
                    Download Meter Raw
                  </CSVDownloader>
                </ExportButton>
              ) : (
                <ExportButton color="#56C46C" onClick={handleRawMeterFetch}>
                  {RawMeterData.loading ? <ButtonSpinner /> : "Fetch Meter Raw"}
                </ExportButton>
              )}

              {rawInverterDataReady ? (
                <ExportButton color="#4a87f9">
                  <CSVDownloader
                    filename={`inverter_raw`}
                    data={rawInverterData.exportRawInverterData.records}
                  >
                    Download Inverter Raw
                  </CSVDownloader>
                </ExportButton>
              ) : (
                <ExportButton color="#4a87f9" onClick={handleRawInverterFetch}>
                  {RawInverterData.loading ? (
                    <ButtonSpinner />
                  ) : (
                    "Fetch Inverter Raw"
                  )}
                </ExportButton>
              )}

              {rawRadianceDataReady ? (
                <ExportButton color="#FFa138">
                  <CSVDownloader
                    filename={`irradiance_raw`}
                    data={rawRadianceData.exportRawRadianceData.records}
                  >
                    Download Irradiance Raw
                  </CSVDownloader>
                </ExportButton>
              ) : (
                <ExportButton color="#FFa138" onClick={handleRawRadianceFetch}>
                  {RawRadianceData.loading ? (
                    <ButtonSpinner />
                  ) : (
                    "Fetch Irradiance Raw"
                  )}
                </ExportButton>
              )}
            </ExportButtonRow>
            <ExportButtonRow>
              {summaryByHourReady ? (
                <ExportButton color="#ae6bec">
                  <CSVDownloader
                    filename={`summary_byHour`}
                    data={summaryByHourData.exportSummaryByHour.records}
                  >
                    Download Summary (Hour)
                  </CSVDownloader>
                </ExportButton>
              ) : (
                <ExportButton
                  color="#ae6bec"
                  onClick={handleSummaryByHourFetch}
                >
                  {SummaryByHour.loading ? (
                    <ButtonSpinner />
                  ) : (
                    "Fetch Hour Data"
                  )}
                </ExportButton>
              )}
              {summaryByMinuteReady ? (
                <ExportButton color="#e6ae5b">
                  <CSVDownloader
                    filename={`summary_byMinute`}
                    data={summaryByMinuteData.exportSummaryByMinute.records}
                  >
                    Download Summary (Minute)
                  </CSVDownloader>
                </ExportButton>
              ) : (
                <ExportButton
                  color="#e6ae5b"
                  onClick={handleSummaryByMinuteFetch}
                >
                  {SummaryByMinute.loading ? (
                    <ButtonSpinner />
                  ) : (
                    "Fetch Minute Data"
                  )}
                </ExportButton>
              )}
            </ExportButtonRow>
          </ExportButtonWrapper>
        </ModelContentWrapper>
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </Section>
    </ModalWrapper>
  );
};

export default DataExportModal;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${NUVOWHITE};
`;

const ExportButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const ExportButtonRow = styled.div`
width: 100%;
display: flex;
gap: 1rem;
justify-content: center;
align-items: center;
`;

const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: ${(props) => (props.show ? "block" : "none")};
  z-index: 1000;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #4f4f47;
`;

const Text = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #4f4f47;
`;

const ModelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
`;

const Section = styled.section`
  position: fixed;
  border-radius: 10px;
  background: white;
  width: 80%;
  height: 500px;
  top: 50%;
  left: 50%;
  opacity: 0.9;
  transform: translate(-50%, -50%);
`;

const ExportButton = styled.button`
  background: ${(props) => props.color};
  color: ${NUVOWHITE};
  width: max-content;
  height: 40px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 5px;

  &:hover {
    filter: brightness(1.03);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff5252;
  color: ${NUVOWHITE};
  width: 50px;
  height: 30px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: ${(props) => (props.isMobile ? "10px" : "0")}
  margin-bottom: 20px;

  &:hover {
    filter: brightness(1.03);
  }
`;

const SpanMessage = styled.span`
  height: 10px;
  font-size: 10px;
  color: red;
  padding-left: 10px;
`;
