import React from "react";
import logo from "../../assets/images/logo.png";
import useMobileView from "../Hooks/useMobileView";
import EmailForm from "./EmailForm";
import {BlueBackground, SignupImage, Wrapper} from "../Landing";
import styled from "styled-components";

const ForgotPassword = () => {
    const {isMobile} = useMobileView();
    return (
        <Wrapper>
            <BlueBackground/>
            <SignupImage/>
            <Container isMobile={isMobile}>
               <Column1> <Logo src={logo} alt={"logo"}/> </Column1>
                <EmailForm isMobile={isMobile}/>
            </Container>
        </Wrapper>
    )
}

export default ForgotPassword;

const Container = styled.div`
  position: absolute;
  background-color: white;
  padding: 16px;
  border-radius: 20px;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  display: flex;
  align-items: center;
  width: 80%;
  overflow: hidden
`;

const Column1 = styled.div`
  flex-direction: column;
  display: flex;
  width: 50%;
`;

const Logo = styled.img`
  width: ${props => props.isMobile ? '200px' : '320px'}; ;
  align-self: center;
`;