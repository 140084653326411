import React, { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import styled from "styled-components";
import { NUVOWHITE, BOXSHADOWBLUE, NUVOBOLDFONT, NUVOHEADERBLUE } from "../../constants/styles";
import UserContext from "../../context/UserContext";
import useGetMapLocations from "../Hooks/useGetMapLocations";
import useSharedUserMapLocations from "../Hooks/userSharedUserMapLocations";

const SitesMap = () => {
    const { user } = useContext(UserContext)
    const { mapLocationData } = useGetMapLocations(user)
    const { sharedUserMapLocationData } = useSharedUserMapLocations(user);
    const [ selected, setSelected ] = useState({});
    const [locations, setLocations] = useState([]);
    const [defaultCenter, setDefaultCenter] = useState({});

    const mapOptions = useMemo(() => ({
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false
    }), []);

    const mapStyles = useMemo(() => ({
        height: "90%",
        width: "100%"
    }), []);

    const onSelect = useCallback((item) => {
        setSelected(item);
    }, []);

    useEffect(() => {
        const fetchMapLocationData = async (data) => {
            if (data && data.getSites) {
                const addData = data.getSites.map((location) => ({
                    "name": location.name,
                    "location" : {
                        "lat": parseFloat(location.latitude),
                        "lng": parseFloat(location.longitude)
                    }
                }));
                setLocations(addData);
                setDefaultCenter(addData[0]?.location);
            }
        };

        fetchMapLocationData(mapLocationData.data);
    }, [mapLocationData.data]);

    useEffect(() => {
        const fetchSharedMapLocationData = async (data) => {
            if (data && data.getSharedSites) {
                const addData = data.getSharedSites.map((location) => ({
                    "name": location.name,
                    "location" : {
                        "lat": parseFloat(location.latitude),
                        "lng": parseFloat(location.longitude)
                    }
                }));
                setLocations(addData);
                setDefaultCenter(addData[0]?.location);
            }
        };

        fetchSharedMapLocationData(sharedUserMapLocationData.data);
    }, [sharedUserMapLocationData.data]);

    return (
        <SiteLocationsWrapper>
            <h2>Site Locations</h2>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <GoogleMap options={mapOptions} mapContainerStyle={mapStyles} zoom={6} center={defaultCenter}>
                    {locations.map(item => (
                        <Marker key={item.name} position={item.location} onClick={() => onSelect(item)} />
                    ))}
                    {selected.location && (
                        <InfoWindow position={selected.location} clickable={true} onCloseClick={() => setSelected({})}>
                            <p>{selected.name}</p>
                        </InfoWindow>
                    )}
                </GoogleMap>
            </LoadScript>
        </SiteLocationsWrapper>
    )
};

export default SitesMap;

const SiteLocationsWrapper = styled.div`
  background-color: ${NUVOWHITE};
  width: 68%;
  height: 100%;
  box-shadow: 0 1px 10px -2px ${BOXSHADOWBLUE};
  h2 {
    color: ${NUVOHEADERBLUE};
    font-family: ${NUVOBOLDFONT};
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 20px;
    height: 30px;
    width: 100%;
  }
`;
