import React from "react";
import {BlueBackground, SignupImage, Wrapper} from "../Landing";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {NUVOBLUE, NUVOBOLDFONT, NUVOGREEN, NUVOREGULARFONT, NUVOTEXTBLUE, NUVOWHITE} from "../../constants/styles";
import FormLabel from "../FormComponents/FormLabel";
import Header1Blue from "../TextComponents/Header1";
import SmallTextBlue from "../TextComponents/SmallTextBlue";
import logo from "../../assets/images/logo.png";
import SubmitButton from "../ButtonComponents/SubmitButton";
import useMobileView from "../Hooks/useMobileView";
import ErrorMessage from "../FormComponents/ErrorMessage";
import usePasswordReset from "../Hooks/usePasswordReset";

const PasswordReset = () => {
    const {isMobile} = useMobileView();
    let {token} = useParams();
    const {handleChange, handleSubmit, values, error} = usePasswordReset(token);
    return (
        <Wrapper>
            <BlueBackground/>
            <SignupImage/>
            <Container isMobile={isMobile}>
                <Column1> <Logo src={logo} alt={"logo"} isMobile={isMobile}/> </Column1>
                <FormContainer isMobile={isMobile} onSubmit={handleSubmit}>
                    <Header1Blue>Creating a new password</Header1Blue>
                    <FormLabel htmlFor="password" name="*New Password"/>
                    <Input
                        aria-label="*New Password"
                        type="password"
                        id="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        required
                        placeholder='Enter the new password'
                    />
                    <FormLabel htmlFor="confirm-password" name="*Confirm Password"/>
                    <Input
                        aria-label="*Confirm Password"
                        type="password"
                        id="confirm-password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        required
                        placeholder='Confirm the new password'
                    />
                    {error && <ErrorMessage message={error}/>}
                    <UList>
                        <li>At least 8 characters long</li>
                        <li>Contains at least one uppercase letter</li>
                        <li> Contains at least one lowercase letter</li>
                        <li>Contains at least one number</li>
                    </UList>
                    <SubmitButton type="submit">Create password</SubmitButton>
                    <SignupWrapper>
                        <SmallTextBlue>Don't have an account ?</SmallTextBlue>
                        <Link href={'/signup'}>SignUp</Link>
                    </SignupWrapper>
                </FormContainer>
            </Container>
        </Wrapper>
    )
}

export default PasswordReset;

const Container = styled.div`
  position: absolute;
  background-color: white;
  padding: 16px;
  border-radius: 20px;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  display: flex;
  align-items: center;
  width: 80%;
  overflow: hidden
`;

const Column1 = styled.div`
  flex-direction: column;
  display: flex;
  width: 50%
`;

const FormContainer = styled.form`
  flex-direction: column;
  display: flex;
  width: ${props => props.isMobile ? '100%' : '50%'};
`;

const SignupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  color: ${NUVOGREEN};
  padding: 0;
  margin-left: 5px;
  font-weight: bold;
  font-size: 12px;
`;

const Input = styled.input`
  color: ${NUVOTEXTBLUE};
  height: 42px;
  outline: none;
  border: 1px solid ${NUVOBLUE};
  border-radius: 5px;
  padding: 0 10px;
`;

const UList = styled.ul`
  font-family: ${NUVOREGULARFONT};
  font-size: 13px;
  margin-bottom: -1rem;
  text-align: justify;
  color: #06427d;
`
const Logo = styled.img`
  width: ${props => props.isMobile ? '200px' : '320px'}; ;
  align-self: center;
`;
