import { ResponsiveLine } from '@nivo/line'

const SiteChart = ({ data, yScale, colors, legendItemSpacing }) => {

    return (
        <ResponsiveLine
            data={data}
            curve="monotoneX"
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            xScale={{
                type: "time",
                format: "%H:%M",
                useUTC: false
            }}
            yScale={yScale ? yScale : { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            yFormat=" >-.1f"
            xFormat="time:%H:%M"
            lineWidth={1.5}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            animate={true}
            // motionConfig={{mass: 1, tension: 1, friction: 1, clamp: true}}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'kW',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            colors={colors ? colors : [ '#002D62', '#FFEF00', '#FF2400' ]}
            enableSlices={'x'}
            sliceTooltip={({ slice }) => {
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >

                        <div><strong>{slice.points[0].data.xFormatted}</strong></div>
                        {slice.points.map(point => (
                            <div
                                key={point.id}
                                style={{
                                    color: point.serieColor,
                                    filter: 'brightness(80%)',
                                    padding: '3px 0',
                                }}
                            >
                                {point.serieId} {point.data.yFormatted}
                            </div>
                        ))}
                    </div>
                )
            }}
            enableArea={true}
            enablePoint={false}
            areaOpacity={0.2}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableGridY={true}
            enableGridX={false}
            legends={[
                {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -30,
                    itemsSpacing: legendItemSpacing ? legendItemSpacing : 45,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 0,
                    itemOpacity: 0.75,
                    symbolSize: 8,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default SiteChart;
