import styled from "styled-components";


const FormContainer = styled.form`
  width: 100%;
  height: 80%;
  overflow: hidden;
  display: flex;
  align-content: center;
  flex-direction: column;
`;

export default FormContainer;
