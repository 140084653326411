import KpiCard from "./KpiCard";
import useDailyConsumption from "../Hooks/useDailyConsumption";
import useMobileView from "../Hooks/useMobileView";
import {NUVOGREEN} from "../../constants/styles";

const DailyConsumptionKpi = ({deviceId}) => {
    const {isMobile} = useMobileView();
    const description = isMobile ? "Grid" : "Today's Grid Consumption";
    const {todayGridConsumption, loading} = useDailyConsumption(deviceId);
    const value = loading ? "-" : todayGridConsumption;
    return <KpiCard items={[{description: description, value, unit: 'kWh', color: NUVOGREEN}]}/>
}

export default DailyConsumptionKpi;
