import {gql, useMutation} from "@apollo/client";
import {GET_ALL_USERS} from "../Management/Users/UsersTable";

const DEACTIVATE_USER = gql`
    mutation DeleteUser ($user_id: String!, $activate: Boolean!) {
        deleteUser(user_id: $user_id) @skip(if: $activate) {
            success
            message
            error
        }
        restoreUser(user_id: $user_id) @include(if: $activate) {
            success
            message
            error
        }
    }
`;

const useDeactivateUser = (user_id, setIsOpen, activate) => {
    const [deleteUser, {error, loading}] = useMutation(DEACTIVATE_USER, {
        refetchQueries: [
            GET_ALL_USERS,
        ],
        onError(error) {
            console.log(error)
        },
        onCompleted() {
            setIsOpen && setIsOpen(false)
        },
        variables: {user_id, activate}
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteUser()
            .catch(error => console.log(error))
    }
    return {handleSubmit, error, loading}
}

export default useDeactivateUser;
