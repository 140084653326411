
import { useContext, useEffect, useState } from 'react';
import useGetSiteById from '../../Hooks/useGetSiteById';
import { useMutation } from "@apollo/client";
import { gql, useApolloClient } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { UserContext } from "../../../context/UserContext";
import { GET_ALL_SITES } from '../../Hooks/useGetAllSites';
import { useNavigate } from 'react-router-dom';

const MAC_ID_CHECKS  = gql`
    query MacIdChecks($deviceId: String!) {
        macIdChecks (deviceId: $deviceId) {
            mac_idExists
            mac_idAllocated
        }
    }
`;

const ADD_DEVICE = gql`
  mutation AddDevice (
    $deviceId: String!, 
    $name: String!, 
    $location: String!, 
    $latitude: String!, 
    $longitude: String!,
    $inverterCount: String,
    $gensetConfig: String,
    $inverterType: String
    $meterCount: String,
    $meter1Label: String,
    ) {
    addDevice (
    deviceId: $deviceId,
    name: $name,
    location: $location,
    latitude: $latitude,
    longitude: $longitude,
    inverterCount: $inverterCount,
    gensetConfig: $gensetConfig,
    inverterType: $inverterType,
    meterCount: $meterCount,
    meter1Label: $meter1Label
    ) {
    id
    message
  }
  }
`;



const useEditDeviceForm = (validateAddDevice, editSiteId ) => {

    // const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const { editSiteDetails } = useGetSiteById(editSiteId)
    const [AddDevice, {error}] = useMutation(ADD_DEVICE, {
        refetchQueries: [
            { query: GET_ALL_SITES }
        ]
    });

  useEffect(() => {
    if (error) {
      console.log("Mutation Error", error);
    }
  }, [error]);


    const [values, setValues] = useState( {
        email: '' ,
        deviceName: '',
        deviceId: '',
        location: '',
        latitude: 0, // set to 0 because need it to be a number/ int for the SiteMap component
        longitude: 0, // set to 0 because need it to be a number/ int for the SiteMap component
        inverterCount: "1",
        inverterType: '',
        gensetConfig: '',
        meterCount: "1",
        meter1Label: '',
        meter2Label: '',
        meter3Label: '',
        meter4Label: '',
        meter5Label: '',
        
    });

    useEffect( () => {
            setValues(editSiteDetails);

    }, [editSiteDetails])


    const [errors, setErrors] = useState({});
    // const [error, setError] = useState({}); 

    const client = useApolloClient();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit =  (e) => {

        e.preventDefault();

        client.query({
            query: MAC_ID_CHECKS,
            variables: {
                deviceId: values.deviceId
            }
        })
            .then((result) => {
                if (Object.keys(validateAddDevice(values, result.data.macIdChecks)).length > 0) {
                    setErrors(validateAddDevice(values, result.data.macIdChecks));
                } else {
                    AddDevice({ variables: {
                            name: values.deviceName,
                            deviceId: values.deviceId,
                            location: values.location,
                            latitude: values.latitude.toString(),
                            longitude: values.longitude.toString(),
                            gensetConfig: values.gensetConfig,
                            inverterCount: values.inverterCount,
                            inverterType: values.inverterType,
                            meterCount: values.meterCount,
                            meter1Label: values.meter1Label
                        }})
                        .then((result) => {
                            if (result) {
                                navigate('/dashboard')
                            }
                        })
                        .catch((error) => {
                          console.log("something");
                        });
                }

            })
            .catch(errors => console.log(errors));

    }
    return { handleChange, handleSubmit, values, errors };
}

export default useEditDeviceForm;
