import {gql, useQuery} from '@apollo/client';
import {useState} from "react";

const GET_SITE_GRID_CONSUMPTION = gql`
    query GetSiteGridConsumption ($deviceId: String!) {
        getSiteGridConsumption (deviceId: $deviceId) {
            today_total_export
            today_total_import
            latest_kwh_total_export
            latest_kwh_total_import
            meter_nr
        }
    }
`;

const useDailyConsumption = (deviceId) => {
    const [totalExport, setTotalExport] = useState(0);
    const [totalImport, setTotalImport] = useState(0);
    const [todayExport, setTodayExport] = useState(0);
    const [todayImport, setTodayImport] = useState(0);
    const [todayGridConsumption, setTodayGridConsumption] = useState(0);

    const {loading} = useQuery(GET_SITE_GRID_CONSUMPTION, {
        variables: {deviceId},
        nextFetchPolicy: 'network-only',
        onCompleted: ({getSiteGridConsumption}) => {
            if (getSiteGridConsumption) {
                const meterOneData = getSiteGridConsumption.filter(meter => meter.meter_nr === 1);
                const {
                    latest_kwh_total_export,
                    latest_kwh_total_import,
                    today_total_export,
                    today_total_import
                } = meterOneData[0] || {};

                setTotalExport(latest_kwh_total_export !== null ? Math.round(latest_kwh_total_export) : 0);
                setTotalImport(latest_kwh_total_import !== null ? Math.round(latest_kwh_total_import) : 0);
                setTodayExport(today_total_export !== null ? Math.round(today_total_export) : 0);
                setTodayImport(today_total_import !== null ? Math.round(today_total_import) : 0);
                setTodayGridConsumption(Math.round(today_total_import - today_total_export));
            }
        },
        onError: (error) => {
            console.log("Today's Grid Consumption", error);
        }
    });

    return {totalExport, totalImport, todayExport, todayImport, todayGridConsumption, loading};
}

export default useDailyConsumption;
