
import styled from "styled-components";
import { NUVOHEADERBLUE } from "../../constants/styles";
import { NUVOBOLDFONT } from "../../constants/styles";

const Header1Blue = styled.h1`
  font-family: ${NUVOBOLDFONT};
  color: ${NUVOHEADERBLUE};
  font-size: 24px;
  text-align: center;
`;

export default Header1Blue;

