import styled from "styled-components";
import {NUVOBLUE, NUVOGREEN, NUVOTEXTBLUE} from "../../constants/styles";

const Input = styled.input`
  color: ${NUVOTEXTBLUE};
  width: ${props => props.isMobile || props.fullWidth ? '100%' : '80%' };
  height: 42px;
  outline: none;
  border: 1px solid ${NUVOBLUE};
  border-radius: 5px;
  padding: 0 10px;
  
  &::placeholder {
    color: rgb(200, 200, 200, 1);
  }
  
  &:focus {
    outline: none;
    border-color: ${NUVOGREEN};
    box-shadow: 0 0 2.5px ${NUVOGREEN};
    transition: all 300ms ease-in;
  }
`;

export default Input;
