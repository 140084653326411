import { ISLIVE_OFFSET } from "../../constants/offsets";
import {NUVOBOLDFONT, NUVOREGULARFONT, NUVOTEXTBLUE} from "../../constants/styles";
import _ from 'lodash';

export const headerColor = {
    color : NUVOTEXTBLUE,
    fontFamily: NUVOBOLDFONT,
    fontSize: '10px',
}

/**
 *
 * @param secondsFromNow - is the number of seconds since the last entry date_time from device
 * @param onlineCheck - is the timestamp since the last insert from device
 * @returns {string|string}
 */
export const isLiveHandler = (secondsFromNow, onlineCheck ) => {
    const lastInsert = onlineCheck;
    const now = _.now()
    const diffInSeconds = Math.round((now - lastInsert) / 1000);
    // console.log("NOW:", now,"LASTINSERT:", lastInsert, "DIFF:", diffInSeconds );
    // if seconds past now is less then offset (15 mins) live else delayed
    // if online check is null or less then offset 15 mins offline
    if (diffInSeconds > ISLIVE_OFFSET ) {
        return 'offline'
    } else {
        return secondsFromNow < ISLIVE_OFFSET ? 'live' : 'delayed'
    }
}

export const isLiveColor = (status) => {
    if (status === 'live') {
        return '#91CC32';
    } else if (status === 'delayed') {
        return '#FECC47';
    } else {
        return '#D94444';
    }
}

export  const rowStyle = () => {
    return {
        color: NUVOTEXTBLUE,
        FontFamily: NUVOREGULARFONT,
        fontSize: '12px'
    }
}

export const gridStyle = {
    height: 276,
    border: '0.25px solid #7986cb',
    marginBottom: '16px',
};

export const rowClassName = () => {
    return 'global-custom-row';
}

