import React, {useCallback} from 'react';
import DayPicker from "../DayPicker/DayPicker";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import styled from "styled-components";

const ControlsWrapperComponent = ({chartDuration, handleChartTimeState, handleChartTimeActive, chartTypeToggle, setChartTypeToggle}) => {
    const onChartChange = useCallback((checked) => {
        setChartTypeToggle(checked);
    }, []);
    return (
        <>
            <SelectorWrapper>
            </SelectorWrapper>
            <DateWrapper>
                <DayPicker chartDuration={chartDuration} />
            </DateWrapper>
            <ControlsWrapper>
                <ChartTimeWrapper>
                    <ChartTimeSelector onClick={() => handleChartTimeState('7D')} active={handleChartTimeActive('7D')}>7D</ChartTimeSelector>
                    <ChartTimeSelector onClick={() => handleChartTimeState('1M')} active={handleChartTimeActive('1M')}>1M</ChartTimeSelector>
                    <ChartTimeSelector onClick={() => handleChartTimeState('YTD')} active={handleChartTimeActive('YTD')}>YTD</ChartTimeSelector>
                </ChartTimeWrapper>
                <ToggleWrapper>
                    <ToggleText>SHOW INVERTERS</ToggleText>
                    <ToggleSwitch name={'chartType'} checked={chartTypeToggle} onChange={onChartChange} />
                </ToggleWrapper>
            </ControlsWrapper>
        </>
    );
};

export default ControlsWrapperComponent;


const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
`;

const ChartTimeWrapper = styled.div`
    display: flex;
`;

const ChartTimeSelector = styled.div`
    margin-right: 20px;
    color: ${props => props.active ? `#1976d2` : '#5D5D5D'};
    border-bottom: ${props => props.active ? `2px solid #1976d2` : 'none'} ;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
`;

const ToggleWrapper = styled.div`
    text-align: center;
    margin-left: 100px;
`;

const ToggleText = styled.div`
    color: #5D5D5D;
    font-size: 8px;
    font-weight: bold;
    min-width: 78px;
`;

const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 50px;
`;
