import styled from 'styled-components';
import { NUVOBLUE, NUVOBOLDFONT, NUVOWHITE} from "../../../constants/styles";
import { InlineIcon } from '@iconify/react';
import dashIcon from '@iconify/icons-mdi/view-dashboard-outline';
import profileIcon from '@iconify/icons-mdi/account-circle-outline';
import settingsIcon from '@iconify/icons-mdi/folder-cog-outline';
import whiteLogo from "../../../assets/images/whiteLogo.png";
import { useNavigate, useLocation } from 'react-router-dom';
import Accordion from "../../Accordion/Accordion";
import usePermission from "../useGetPermissions";
import {SUPER_ADMIN_ROLE} from "../../../helpers/variables";

const useRenderNav = (isMobile) => {
    const navigate = useNavigate();
    const {pathname} = useLocation()
   const hasPermission = usePermission(SUPER_ADMIN_ROLE)

    const renderNav = () => {
        return isMobile ?
            <MobileNav isMobile={isMobile}>
                {(() => { return !isMobile ? <Logo src={whiteLogo} alt="logo" /> : null})()}
                <MobileMenuWrapper isMobile={isMobile}>
                    <MenuButton isMobile={isMobile} onClick={() => navigate('/dashboard')} path={pathname === '/dashboard'}><InlineIcon icon={dashIcon} /> { !isMobile ? "Dashboards" : null }</MenuButton>
                    <MenuButton isMobile={isMobile} onClick={() => navigate('/dashboard/profile')} path={pathname === '/dashboard/profile'}><InlineIcon icon={profileIcon}/> { !isMobile ? "Profile" : null }</MenuButton>
                    <MenuButton isMobile={isMobile} onClick={() => navigate('/dashboard/reports')} path={pathname === '/dashboard/reports'}><InlineIcon icon={settingsIcon} /> { !isMobile ? "Reporting" : null } </MenuButton>
                    {hasPermission && <MenuButton isMobile={isMobile} onClick={() => navigate('/management/users-list')} path={pathname === '/management/users-list'}><InlineIcon icon="fa-solid:users" /> { !isMobile ? "Users" : null }</MenuButton>}
                    <MenuButton isMobile={isMobile} onClick={() => navigate('/management/sites-list')} path={pathname === '/management/sites-list'}><InlineIcon icon="jam:sitemap-f" /> { !isMobile ? "Sites" : null }</MenuButton>
                </MobileMenuWrapper>
            </MobileNav>
            :
            <DashboardNav>
                {(() => { return !isMobile ? <Logo src={whiteLogo} alt="logo" /> : null})()}
                <MenuWrapper isMobile={isMobile}>
                    <MenuButton isMobile={isMobile} onClick={() => navigate('/dashboard')} path={pathname === '/dashboard'}><InlineIcon icon={dashIcon} /> { !isMobile ? "Dashboards" : null }</MenuButton>
                    <MenuButton isMobile={isMobile} onClick={() => navigate('/dashboard/profile')} path={pathname === '/dashboard/profile'}><InlineIcon icon={profileIcon}/> { !isMobile ? "Profile" : null }</MenuButton>
                    <MenuButton isMobile={isMobile} onClick={() => navigate('/dashboard/reports')} path={pathname === '/dashboard/reports'}><InlineIcon icon={settingsIcon} /> { !isMobile ? "Reporting" : null } </MenuButton>
                   <Accordion title="Management">
                       {hasPermission &&   <SubMenuButton onClick={() => navigate('/management/users-list')}
                                       path={pathname === '/management/users-list'}>• Users</SubMenuButton> }
                        <SubMenuButton onClick={() => navigate('/management/sites-list')}
                                       path={pathname === '/management/sites-list'}>• Sites</SubMenuButton>
                    </Accordion>
                </MenuWrapper>
            </DashboardNav>
    }

    return { renderNav }

}

export default useRenderNav;

const DashboardNav = styled.div`
  padding-top: 40px;
  width: 240px;
  height: 100vh;
  background-color: ${NUVOBLUE};
  display: flex;
  justify-content: start;
  flex-direction: column;
  position: sticky;
  top: 0;
`;

const MenuWrapper = styled.div`
  margin-top: ${props => props.isMobile ? '0' : '40px' };
  width: 100%;
  display: flex;
  align-items: ${props => props.isMobile ? 'center' : 'none'};
  flex-direction: ${props => props.isMobile ? 'row' : 'column' };
`;

const MobileNav = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  bottom: 0;
  z-index: 2000;
  background-color: ${NUVOBLUE};
`;

const MobileMenuWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-bottom: 10px;
`;

const MenuButton = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  line-height: 20px;
  vertical-align: middle;
  font-size: ${props => props.isMobile ? '30px' : '16px'};
  padding-left: ${props => props.isMobile ? null : '30px'};
  padding-top: ${props => props.isMobile ? null : '10px'};
  padding-bottom: ${props => props.isMobile ? null : '10px'};
  padding: ${props => props.isMobile ? '6px' : null};
  border-radius: ${props => props.isMobile ? '10px' : null};
  &:hover {
    background-color: rgba(0, 0, 12, 0.26);
  }
  background-color: ${({path}) => path ? 'rgba(0,0,12,0.19)' : null};
`;

const SubMenuButton = styled(MenuButton)`
  font-size: 14px;
  margin-left: 10px;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const Logo = styled.img`
  height: 60px;
  width: 180px;
  margin: 0 auto;
`;