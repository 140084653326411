
import bcrypt from 'bcryptjs';

const validatePassword = async (values, user, passwordCheck) => {
    const errors = {}
    const current = values.current ? values.current : "";
    const passMatch = await bcrypt.compare(current, passwordCheck);
    console.log(passMatch);

    if (!values.current) {
        errors.current = "Current Password is required";
    } else if (!passMatch) {
        errors.current = "Incorrect password"
    }


    if (!values.new) {
        errors.new = "Password is required";
    } else if (values.new.length < 8) {
        errors.new = "Password needs to be longer then 8 characters";
    }

    if (!values.confirm || values.new !== values.confirm) {
        errors.confirm = "Passwords need to match";
    }

    return errors;
}

export default validatePassword;
