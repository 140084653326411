import {useState} from "react";
import {gql, useMutation} from "@apollo/client";

const FORGOT_PASSWORD = gql`
    mutation ForgotPassword ($email: String!) {
        forgotPassword(email: $email) {
            success
            message
            error
        }
    }
`;

const useForgotPassword = (email, setIsOpen) => {
    const [error, setError] = useState(null);
    const [isEmailSent, setIsEmailSent] = useState(false);

    const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
        onError(error) {
            setError(error.message)
            console.log(error)
        },
        onCompleted() {
            !setIsOpen && setIsEmailSent(true)
            setIsOpen && setIsOpen(false)
        },
        variables: {email}
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        forgotPassword()
            .catch(error => console.log(error))
    }
    return {handleSubmit, error, isEmailSent}
}

export default useForgotPassword;
