import React from "react";
import styled from "styled-components";
import {NUVOSEMIFONT, NUVOTEXTBLUE} from "../../constants/styles";

const calculateValue = (data, isGensetMeterSite) => {
    const {getEnergyFlowData} = data
    let activePower = getEnergyFlowData.meter_active_power;

    if (isGensetMeterSite && getEnergyFlowData.meter2_active_power !== 0) {
        activePower = getEnergyFlowData.meter2_active_power;
    }
    return (getEnergyFlowData.cons_active_power + activePower).toLocaleString();
}

const ConsumptionIcon = React.memo(({data, isGensetMeterSite, width, height}) => {
    const value = data ? calculateValue(data, isGensetMeterSite) : null;

    return (
        <IconWrapper>
            <div>Consumption</div>
            <svg id="Group_150" data-name="Group 150" xmlns="http://www.w3.org/2000/svg" width={width} height={height}
                 viewBox="0 0 117.953 130.644">
                <g id="Group_95" data-name="Group 95" transform="translate(25.5 27.818)">
                    <path id="Path_442" data-name="Path 442"
                          d="M245.311,89.612H218.885a2.275,2.275,0,0,1-2.277-2.277V80.384a33.48,33.48,0,1,1,30.981,0v6.951A2.275,2.275,0,0,1,245.311,89.612Zm-24.15-4.554h21.873V78.972a2.279,2.279,0,0,1,1.312-2.062,28.924,28.924,0,1,0-24.5,0,2.279,2.279,0,0,1,1.311,2.062Z"
                          transform="translate(-198.62 -17.257)" fill="#7bce00"/>
                </g>
                <g id="Group_96" data-name="Group 96" transform="translate(37.77 95.62)">
                    <path id="Path_443" data-name="Path 443"
                          d="M236.105,89.5H216.741a11.4,11.4,0,0,1-11.385-11.385V56.756a2.277,2.277,0,0,1,2.277-2.277h37.578a2.278,2.278,0,0,1,2.277,2.277V78.119A11.4,11.4,0,0,1,236.105,89.5Zm-26.2-30.471V78.119a6.839,6.839,0,0,0,6.831,6.831h19.365a6.839,6.839,0,0,0,6.829-6.831V59.033Z"
                          transform="translate(-205.356 -54.479)" fill="#7bce00"/>
                </g>
                <g id="Group_97" data-name="Group 97" transform="translate(37.77 105.9)">
                    <path id="Path_444" data-name="Path 444"
                          d="M245.211,64.677H207.633a2.277,2.277,0,1,1,0-4.554h37.578a2.277,2.277,0,0,1,0,4.554Z"
                          transform="translate(-205.356 -60.123)" fill="#7bce00"/>
                </g>
                <g id="Group_98" data-name="Group 98" transform="translate(37.77 116.183)">
                    <path id="Path_445" data-name="Path 445"
                          d="M245.211,70.322H207.633a2.277,2.277,0,1,1,0-4.554h37.578a2.277,2.277,0,0,1,0,4.554Z"
                          transform="translate(-205.356 -65.768)" fill="#7bce00"/>
                </g>
                <g id="Group_101" data-name="Group 101" transform="translate(0 56.699)">
                    <g id="Group_99" data-name="Group 99" transform="translate(0 0)">
                        <path id="Path_446" data-name="Path 446"
                              d="M205.062,37.666H186.9a2.277,2.277,0,0,1,0-4.554h18.164a2.277,2.277,0,0,1,0,4.554Z"
                              transform="translate(-184.621 -33.112)" fill="#7bce00"/>
                    </g>
                    <g id="Group_100" data-name="Group 100" transform="translate(95.239 0)">
                        <path id="Path_447" data-name="Path 447"
                              d="M257.344,37.666H239.183a2.277,2.277,0,0,1,0-4.554h18.161a2.277,2.277,0,0,1,0,4.554Z"
                              transform="translate(-236.906 -33.112)" fill="#7bce00"/>
                    </g>
                </g>
                <g id="Group_103" data-name="Group 103" transform="translate(83.951 16.607)">
                    <g id="Group_102" data-name="Group 102" transform="translate(0)">
                        <path id="Path_448" data-name="Path 448"
                              d="M232.986,28.5a2.276,2.276,0,0,1-1.608-3.885l12.842-12.844a2.277,2.277,0,0,1,3.22,3.22L234.6,27.831A2.267,2.267,0,0,1,232.986,28.5Z"
                              transform="translate(-230.709 -11.102)" fill="#7bce00"/>
                    </g>
                </g>
                <g id="Group_105" data-name="Group 105" transform="translate(16.609 16.607)">
                    <g id="Group_104" data-name="Group 104" transform="translate(0)">
                        <path id="Path_449" data-name="Path 449"
                              d="M208.858,28.5a2.266,2.266,0,0,1-1.608-.667L194.406,14.989a2.277,2.277,0,1,1,3.219-3.22l12.844,12.844a2.276,2.276,0,0,1-1.61,3.885Z"
                              transform="translate(-193.739 -11.102)" fill="#7bce00"/>
                    </g>
                </g>
                <g id="Group_109" data-name="Group 109" transform="translate(56.701 0)">
                    <g id="Group_106" data-name="Group 106" transform="translate(0 0)">
                        <path id="Path_450" data-name="Path 450"
                              d="M218.026,24.7a2.278,2.278,0,0,1-2.277-2.277V4.262a2.277,2.277,0,0,1,4.554,0V22.424A2.277,2.277,0,0,1,218.026,24.7Z"
                              transform="translate(-215.749 -1.985)" fill="#7bce00"/>
                    </g>
                    <g id="Group_107" data-name="Group 107" transform="translate(2.277 97.514)">
                        <path id="Path_451" data-name="Path 451" d="M217,55.519" transform="translate(-216.999 -55.519)"
                              fill="none" stroke="#7bce00"/>
                    </g>
                    <g id="Group_108" data-name="Group 108" transform="translate(2.277 115.677)">
                        <path id="Path_452" data-name="Path 452" d="M217,65.49" transform="translate(-216.999 -65.49)"
                              fill="none" stroke="#7bce00"/>
                    </g>
                </g>
                <g id="Group_112" data-name="Group 112" transform="translate(41.191 55.534)">
                    <g id="Group_110" data-name="Group 110" transform="translate(0 0.001)">
                        <path id="Path_453" data-name="Path 453"
                              d="M218.036,76.729a2.278,2.278,0,0,1-2.277-2.277V38.143l-5.386,2.206a2.276,2.276,0,0,1-1.725-4.213l8.527-3.494a2.276,2.276,0,0,1,3.139,2.108v39.7A2.277,2.277,0,0,1,218.036,76.729Z"
                              transform="translate(-207.234 -32.473)" fill="#7bce00"/>
                    </g>
                    <g id="Group_111" data-name="Group 111" transform="translate(8.525 0)">
                        <path id="Path_454" data-name="Path 454"
                              d="M228.162,76.729a2.278,2.278,0,0,1-2.277-2.277V38.7l-3.567,2.069a2.275,2.275,0,0,1-2.284,0l-6.984-4.055a2.276,2.276,0,1,1,2.282-3.938l5.843,3.39,5.844-3.39a2.277,2.277,0,0,1,3.419,1.969v39.7A2.277,2.277,0,0,1,228.162,76.729Z"
                              transform="translate(-211.914 -32.473)" fill="#7bce00"/>
                    </g>
                </g>
                <g id="Group_113" data-name="Group 113" transform="translate(63.687 55.535)">
                    <path id="Path_455" data-name="Path 455"
                          d="M230.125,39.987a2.271,2.271,0,0,1-.769-.135l-8.262-2.958a2.277,2.277,0,0,1,1.536-4.288l8.263,2.96a2.277,2.277,0,0,1-.767,4.421Z"
                          transform="translate(-219.584 -32.473)" fill="#7bce00"/>
                </g>
            </svg>
            <div>{value ? value : "-"}</div>
            <div>kW</div>
        </IconWrapper>

    )
})

export default ConsumptionIcon;

const IconWrapper = styled.div`
  font-family: ${NUVOSEMIFONT};
  color: ${NUVOTEXTBLUE};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
