
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./daypicker.css";
import format from "date-fns/format";
import styled from 'styled-components';
import { useContext, useEffect, useState } from "react";
import {NUVOBLUE, NUVOWHITE} from "../../../constants/styles";
import { DateContext } from "../../../context/DateContext";

const DayPicker = ({ chartDuration }) => {
    const { date, monthDate, YTDDate, addDate } = useContext(DateContext);
    const [pickerDayDate, setPickerDayDate] = useState(date);
    const [pickerYTDDate, setPickerYTDDate] = useState(monthDate);
    const [pickerMonthDate, setPickerMonthDate] = useState(YTDDate);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
         setPickerMonthDate(monthDate);
    }, [monthDate]);

    useEffect(() => {
        setPickerYTDDate(YTDDate);
    }, [YTDDate]);

    useEffect(() => {
        setPickerDayDate(date);
    }, [date]);

    const handleChange = (e) => {
        setIsOpen(!isOpen);
        addDate('day',e);
    }

    const handleYTDChange = (e) => {
        setIsOpen(!isOpen);
        addDate('YTD',e);
    }

    const handleMonthChange = (e) => {
        setIsOpen(!isOpen);
        addDate('month', e);
        // setPickerMonthDate(e);
    }

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    if (chartDuration === "7D") return null;

    if (chartDuration === "1M") {
        return (
            <DayPickerWrapper>
            <PickerButton onClick={handleClick} >{format(pickerMonthDate, "MMM yyyy")}</PickerButton>
            {
                isOpen && (<DatePicker
                    selected={pickerMonthDate}
                    onChange={handleMonthChange}
                    showMonthYearPicker
                    minDate={new Date('May 31, 2021 00:00:00')}
                    maxDate={new Date()}
                    inline
            />
            )}
            </DayPickerWrapper>
        );

    }

    if (chartDuration === "YTD") {
        return (
            <DayPickerWrapper>
            <PickerButton onClick={handleClick} >{format(pickerYTDDate, "yyyy")}</PickerButton>
            {
                isOpen && (<DatePicker
                    selected={pickerYTDDate}
                    onChange={handleYTDChange}
                    showYearPicker
                    minDate={new Date('December 31, 2020 00:00:00')}
                    maxDate={new Date()}
                    inline

            />
            )}
            </DayPickerWrapper>
        );
    }

    return (
        <DayPickerWrapper>
            <PickerButton onClick={handleClick} >{format(pickerDayDate, "dd/MM/yyyy")}</PickerButton>
            {isOpen && (<DatePicker
                selected={pickerDayDate}
                onChange={handleChange}
                inline
                maxDate={new Date()} // user cannot select a date after today (data does not exist)

            />
            )}
        </DayPickerWrapper>
    );
}

export default DayPicker;

const DayPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 40px;
  z-index: 1000;
`;

const PickerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  min-height: 40px;
  background-color: ${NUVOBLUE};
  color: ${NUVOWHITE};
  align-content: center;
  border: none;
  border-radius: 3px;
  letter-spacing: 1.5px;
  //margin-right: 0.5rem;
  cursor: pointer;
  box-shadow: inset 3px 3px 15px #123c65,
            inset -3px -3px 15px #185189;
`;
