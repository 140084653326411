import React, {useMemo} from "react";
import styled from "styled-components";
import ChartTab from "../Tab/ChartTab";
import {NUVOBOLDFONT, NUVOWHITE, NUVOHEADERBLUE, NUVOBGGREY} from "../../constants/styles";
import DailyEnergyKpi from "../SiteKpi/DailyEnergyKpi";
import DailyConsumptionKpi from "../SiteKpi/DailyConsumptionKpi";
import TotalEnergyKpi from "../SiteKpi/TotalEnergyKpi";
import ImportExportKpi from "../SiteKpi/ImportExportKpi";
import useGetSiteData from "../Hooks/useGetSiteData";
import useMobileView from "../Hooks/useMobileView";
import useRenderNav from "../Hooks/Nav/useRenderNav";
import Popup from 'reactjs-popup';
import EnergyFlow from '../EnergyFlow/EnergyFlow';
import SignOut from "../ButtonComponents/SignOut";
import {isLiveHandler} from "../DataGrid/handlers";
import {useLocation} from "react-router-dom";
import BlueButtonSpinner from "../ButtonComponents/BlueButtonSpinner";
import Production from "./Charts/Production";
import Grid from "./Charts/Grid";
import PowerDistribution from "./Charts/PowerDistribution";
import DetailedDistribution from "./Charts/DetailedDistribution";
import ChartDurationContextProvider from "../../context/ChartDurationContext";
import {InlineIcon} from '@iconify/react';
import infoIcon from '@iconify/icons-mdi/info-circle-outline';
import useIsLive, {getLiveColor} from "../Hooks/useIsLive";
import BigTextBlue from "../TextComponents/BigTextBlue";

const Detail = () => {
    const location = useLocation();
    const deviceId = location.state?.deviceId;
    const {isMobile} = useMobileView();
    const {renderNav} = useRenderNav(isMobile);
    const {siteData, loading} = useGetSiteData(deviceId);
    const isLive = useIsLive(siteData);
    const isGensetMeterSite = siteData?.genset_meter || false;
    const liveStatus = useMemo(() => isLiveHandler(siteData?.seconds_past_now, siteData?.online_check), [siteData?.seconds_past_now, siteData?.online_check]);
    const liveColor = useMemo(() => getLiveColor(liveStatus), [liveStatus]);

    return (
        <PageWrapper>
            {renderNav()}
            {!deviceId && <ErrorWrapper><BigTextBlue>Error: No device ID provided</BigTextBlue></ErrorWrapper>}
            {loading && <LoadingWrapper><BlueButtonSpinner/></LoadingWrapper>}
            {deviceId && siteData && (
                <DashboardContent isMobile={isMobile}>
                    <DashBoardHeaderWrapper>
                        <DashBoardHeader>Site Detail</DashBoardHeader>
                        <SignOut>Sign-out</SignOut>
                    </DashBoardHeaderWrapper>
                    <SiteNameWrapper>
                        <SiteName isMobile={isMobile}>{siteData.name}</SiteName>
                        <SiteLastUpdatedSection isLive={isLive} liveColor={liveColor} liveStatus={liveStatus}
                                                siteData={siteData}/>
                    </SiteNameWrapper>
                    <TopWrapper>
                        <DailyEnergyKpi deviceId={deviceId}/>
                        <DailyConsumptionKpi deviceId={deviceId}/>
                        <ImportExportKpi deviceId={deviceId}/>
                        <TotalEnergyKpi deviceId={deviceId}/>
                    </TopWrapper>
                    <EnergyFlowCard>
                        <CardHeader>Energy Flow</CardHeader>
                        <FlowWrapper>
                            <EnergyFlow deviceId={deviceId} isGensetMeterSite={isGensetMeterSite} isLive={isLive}/>
                        </FlowWrapper>
                    </EnergyFlowCard>
                    <ChartDurationContextProvider>
                        <TabContent deviceId={deviceId} isGensetMeterSite={isGensetMeterSite}/>
                    </ChartDurationContextProvider>
                    <MobileNavSpacer/>
                </DashboardContent>
            )}
        </PageWrapper>
    )
}

export default Detail

const TabContent = React.memo(({deviceId, isGensetMeterSite}) => {
    const tabs = [
        {
            title: "Power Distribution",
            content: <PowerDistribution deviceId={deviceId} isGensetMeterSite={isGensetMeterSite}/>
        },
        {
            title: "Production",
            content: <Production deviceId={deviceId}/>
        },
        {
            title: "Import/Export",
            content: <Grid deviceId={deviceId} isGensetMeterSite={isGensetMeterSite}/>
        },
        {
            title: "Detailed Distribution",
            content: <DetailedDistribution deviceId={deviceId}/>
        },
    ];
    return <ChartTab>
        {tabs.map((tab, index) => (
            <ChartTab.Tabpane key={`Tab-${index}`} tab={tab.title} icon={tab.icon}>
                {tab.content}
            </ChartTab.Tabpane>
        ))}
    </ChartTab>
});

const SiteLastUpdatedSection = React.memo(({isLive, liveColor, liveStatus, siteData}) => {
    return isLive ? (
        <SiteLastUpdatedLive color={liveColor}>
            <span>{liveStatus}</span>
        </SiteLastUpdatedLive>
    ) : (
        <ColumnWrapper>
            <StyledPopup
                color={liveColor}
                trigger={() => (
                    <SiteLastUpdated color={liveColor}>
                        <span>{liveStatus}</span>
                        <div><InlineIcon icon={infoIcon}/></div>
                    </SiteLastUpdated>
                )}
                position="left center"
            >
                <PopupText>Last update from site:</PopupText>
                <p>{siteData.last_update ? siteData.last_update.slice(0, -6) : null}</p>
            </StyledPopup>
        </ColumnWrapper>
    );
});

const PageWrapper = styled.div`
  display: flex;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const MobileNavSpacer = styled.div`
  height: 120px;
`;

const DashboardContent = styled.div`
  width: 100%;
  background-color: #f5f6f8;
`;

const SiteNameWrapper = styled.div`
  width: 95%;
  height: 50px;
  margin: 0 auto 30px auto;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 1.2em;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const SiteLastUpdatedLive = styled.div`
  font-size: 0.6em;
  text-align: center;
  color: #FFFFFF;
  line-height: 30px;
  width: 70px;
  background-color: ${props => props.color};
  border-radius: 5px;
  padding: 1px 0;
`;

const SiteLastUpdated = styled(SiteLastUpdatedLive)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DashBoardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 50px;
  margin: 0 auto;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 12px;
  border: 0;
  padding-left: 20px;
`;

const DashBoardHeader = styled.h1`
  margin: 0 0 0 0;
  font-size: 16px;
  color: #5D5D5D;
`;

const CardHeader = styled.div`
  color: ${NUVOHEADERBLUE};
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  height: 30px;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto auto 40px auto;
  width: 95%;
`;

const EnergyFlowCard = styled.div`
  background-color: ${NUVOBGGREY};
  width: 95%;
  margin: 0 auto;
  height: 300px;
  border: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
`;

const FlowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  height: 100%;
  width: 100%;
`;

const SiteName = styled.div`
  font-size: ${props => props.isMobile ? '0.8em' : '1.5em'};
`;

const PopupText = styled.p`
  font-weight: 800;
`;

const ErrorWrapper = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center
`;

const StyledPopup = styled(Popup)`
  &-content {
    text-align: center;
    border-width: 1.5px;
    background-color: ${props => props.color};
    color: #ffffff;
    opacity: 0.8;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
  }

  &-arrow {
    color: ${props => props.color};
  }
`;
