import Management from "../Components/Management/Management";

// ==============================|| MAIN ROUTING ||============================== //

const SuperAdminR = [
    {
        path: "management",
        children: [
            {index: true, path: "users-list", element: <Management/>},
        ],
    },
]

export default SuperAdminR;
