import {useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {useLocation, useNavigate} from 'react-router-dom';
import {IS_LOGGED_IN} from "../../graphql/queries";

const LOGIN_USER = gql`
    mutation LoginUser ($email: String!, $password: String!) {
        loginUser (username: $email, password: $password) {
            access_token
            refresh_token
            message
        }
    }
`;

const useLoginForm = (validateLogin) => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    const [errors, setErrors] = useState({});

    const [LoginUser, {client, loading}] = useMutation(LOGIN_USER, {
        update(_, {data: {loginUser: user}}) {
            if (user) {
                localStorage.setItem('authToken', user.access_token);
                client.cache.writeQuery({
                    query: IS_LOGGED_IN,
                    data: {
                        isLoggedIn: true
                    }
                });
            }
        },
        onError(error) {
            console.log(error);
            setErrors({
                email: error.message
            })
        },
        variables: values
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(validateLogin(values)).length > 0) {
            setErrors(validateLogin(values));
        } else {
            LoginUser()
                .then(() => navigate(state?.path || '/dashboard', {replace: true}))
                .catch(error => console.log(error))
        }
    }

    return {handleChange, handleSubmit, values, errors}
}

export default useLoginForm;
