import React from 'react';
import styled from "styled-components";
import Modal from "../Modal";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import useDeactivateUser from "../../Hooks/useDeactiveUser";
import useMobileView from "../../Hooks/useMobileView";

function ActivateDeactivateUser({user, setIsOpen, activate}) {
    const { isMobile } = useMobileView();
    const {handleSubmit, error, loading} = useDeactivateUser(user.id, setIsOpen, activate)
    return (
        <Modal onClose={() => setIsOpen(false)} width={isMobile ? '90%' : '30%'}>
            <h2>Are you sure you want to {activate ? 'activate' : 'deactivate'} the user?</h2>
            <h3>{user.username} {user.name}</h3>
            {error && <ErrorMessage message={error.message}/>}
            <div style={{justifyContent: 'space-evenly', display: 'flex', marginTop: 16}}>
                <CloseButton onClick={() => setIsOpen(false)}>Close</CloseButton>
                <SaveButton onClick={handleSubmit}>Yes</SaveButton>
            </div>
        </Modal>
    );
}

export default ActivateDeactivateUser;

const SaveButton = styled(SubmitButton)`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
`;

const CloseButton = styled(SubmitButton)`
  width: 100px;
  background-color: #da3e3e;
  margin-top: 0;
  margin-bottom: 0;
`;
