import React, {useState} from 'react';
import styled from 'styled-components';
import {NUVOBOLDFONT} from "../../../constants/styles";
import SitesTable from "./SitesTable";
import {GET_ALL_SITES, GET_MY_SITES, GET_SHARED_SITES} from "./queries";
import usePermission from "../../Hooks/useGetPermissions";
import {SUPER_ADMIN_ROLE} from "../../../helpers/variables";

const TabsView = () => {
    const hasPermission = usePermission(SUPER_ADMIN_ROLE)

    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabs = [
        {title: 'My Sites', content: SitesTable, props: {query: GET_MY_SITES, func: 'getSites'} },
        {title: 'Shared with me', content: SitesTable, props: {query: GET_SHARED_SITES, func: 'getSharedSites'}},
        {title: 'All Sites', content: SitesTable, props: {query: GET_ALL_SITES, func: 'getAllSites'}, needP: true},
    ];

    return (
        <div>
            <TabContainer>
                {tabs.map((tab, index) => (
                    (!tab.needP || hasPermission) && (
                        <Tab
                            key={index}
                            active={index === activeTab}
                            onClick={() => handleTabClick(index)}
                        >
                            {tab.title}
                        </Tab>
                    )
                ))}
            </TabContainer>
            {tabs.map((tab, index) => {
                const TabContentComponent = tab.content;
                return (
                    <TabContent key={index} show={index === activeTab}>
                        {index === activeTab && <TabContentComponent {...tab.props}/>}
                    </TabContent>
                );
            })}
        </div>
    );
};

export default TabsView;


const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-family: ${NUVOBOLDFONT};
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: -35px;
  height: 50px;
`;

const Tab = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => (props.active ? 'black' : 'rgba(0, 0, 0, 0.6)')};
  padding: 10px 16px;
  font-size: 0.875rem;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  position: relative;

  &:hover {
    color: black;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(props) => (props.active ? 'black' : 'transparent')};
    transition: background-color 0.3s;
  }
`;

const TabContent = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
`;
