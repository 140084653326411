import styled from "styled-components";
import { NUVOGREEN, NUVOWHITE } from "../../constants/styles";

const SubmitButton = styled.button`
  background-color: ${NUVOGREEN};
  color: ${NUVOWHITE};
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 3px;
  letter-spacing: 1.5px;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 20px;
  :disabled {
    opacity: 0.4;
    cursor: default;
  }
  &:hover {
    filter: brightness(1.03);
  }
`;

export default SubmitButton;