import styled from "styled-components";
import {NUVOBLUE, NUVOTEXTBLUE, NUVOWHITE} from "../../constants/styles";

const RedirectButton = styled.button`
  background-color: ${NUVOWHITE};
  color: ${NUVOTEXTBLUE};
  width: 200px;
  height: 40px;
  border: 1px solid ${NUVOBLUE};
  border-radius: 3px;
  letter-spacing: 1.5px;
  margin-top: 2rem;
  cursor: pointer;
  
  &:hover {
    filter: brightness(1.03);
  }
`;

export default RedirectButton;