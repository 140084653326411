import DateContextProvider from "./context/DateContext";
import {useQuery} from "@apollo/client";
import {IS_LOGGED_IN} from "./graphql/queries";
import AuthenticatedView from "./Components/AuthenticatedView";
import {UnAuthRoutes} from "./routes";

const App = () => {
    const {data} = useQuery(IS_LOGGED_IN);

    return (
        <DateContextProvider>
            {data.isLoggedIn ? <AuthenticatedView/> : <UnAuthRoutes/>}
        </DateContextProvider>
    )
}

export default App;
