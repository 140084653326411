import styled from "styled-components";
import {NUVOWHITE, NUVOBOLDFONT, NUVOGREEN, NUVOHEADERBLUE, NUVOTEXTBLUE, NUVORED} from "../../constants/styles";
import useGetSites from "../Hooks/useGetSites";
import UserContext from "../../context/UserContext";
import {useContext, useMemo} from "react";
import {isLiveHandler} from "../DataGrid/handlers";
import useSharedUserSites from "../Hooks/useSharedUserSites";

const SiteKpi = ({isMobile}) => {
    const { user } = useContext(UserContext);
    const siteData = useGetSites(user);
    const sharedUserSiteData = useSharedUserSites(user);

    const kpiValues = useMemo(() => {
        if (!siteData.data || !sharedUserSiteData.data) {
            // return some default value
            return {
                sitesOffline: "-",
                sitesDelayed: "-",
                totalSites: "-",
                allSolarProduction: 0,
                lifetimeEnergy: 0,
            };
        }

        let offlineCount = 0;
        let delayedCount = 0;
        let totalSolarProduction = 0;
        let lifeTimeEnergy = 0;

        const { getSites } = siteData.data;
        const { getSharedSites } = sharedUserSiteData.data;

        // Stringify the sites and filter duplicates with Set
        const fullSiteList = Array.from(
            new Set([...getSites, ...getSharedSites].map(site => JSON.stringify(site)))
        ).map(site => JSON.parse(site));

        fullSiteList.forEach((site) => {
            const liveHandlerResult = isLiveHandler(site.seconds_past_now, site.online_check);
            if (liveHandlerResult === "offline") offlineCount++;
            if (liveHandlerResult === "delayed") delayedCount++;

            totalSolarProduction += site.total_ac_active_power / 1000;
            lifeTimeEnergy += site.total_kwh_active;
        });

        return {
            sitesOffline: offlineCount,
            sitesDelayed: delayedCount,
            totalSites: fullSiteList.length,
            allSolarProduction: Math.round(totalSolarProduction),
            lifetimeEnergy: Math.round(lifeTimeEnergy),
        };
    }, [siteData.data, sharedUserSiteData.data]);

    return (
        <SiteKpiWrapper isMobile={isMobile}>
            <Header>Site KPIs</Header>
            <KpiSlot3Col>
                <InlineWrapper>
                    <InlineKpiHeader>Total Sites</InlineKpiHeader><KpiValue>{`${kpiValues.totalSites}`} </KpiValue>
                </InlineWrapper>
                <InlineWrapper>
                    <InlineKpiHeader>Delayed</InlineKpiHeader><DelayedKpiValue
                    sitesDelayed={kpiValues.sitesDelayed}>{`${kpiValues.sitesDelayed}`} </DelayedKpiValue>
                </InlineWrapper>
                <InlineWrapper>
                    <InlineKpiHeader>Offline</InlineKpiHeader><OfflineKpiValue
                    sitesOffline={kpiValues.sitesOffline}>{`${kpiValues.sitesOffline}`} </OfflineKpiValue>
                </InlineWrapper>
            </KpiSlot3Col>
            <KpiSlot>
                <KpiValue>{kpiValues.allSolarProduction ? `${kpiValues.allSolarProduction.toLocaleString()} kW` : "-"}</KpiValue>
                <KpiHeader>Current Solar Production(All Sites)</KpiHeader>
            </KpiSlot>
            <KpiSlot>
                <KpiValue>{kpiValues.lifetimeEnergy ? `${kpiValues.lifetimeEnergy.toLocaleString()} kWh` : "-"}</KpiValue>
                <KpiHeader>Lifetime energy (All Sites)</KpiHeader>
            </KpiSlot>
        </SiteKpiWrapper>
    )
}

export default SiteKpi;

const SiteKpiWrapper = styled.div`
  width: ${props => props.isMobile ? '100%' : '28%'};
  background-color: ${NUVOWHITE};
  height: 100%;
  border: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
`;

const InlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  height: 30px;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;

const KpiSlot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90px;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;

const KpiSlot3Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 90px;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;

const KpiValue = styled.h3`
  font-size: 25px;
  margin: 0 0 0 20px;
  padding-top: 10px;
  color: ${props => props.sitesOffline > 0 ? `${NUVORED}` : `${NUVOGREEN}`};
`;

const OfflineKpiValue = styled.h3`
  font-size: 25px;
  margin: 0 0 0 20px;
  padding-top: 10px;
  color: ${props => props.sitesOffline > 0 ? `${NUVORED}` : `${NUVOGREEN}`};
`;

const DelayedKpiValue = styled.h3`
  font-size: 25px;
  margin: 0 0 0 20px;
  padding-top: 10px;
  color: ${props => props.sitesDelayed > 0 ? `#FECC47` : `${NUVOGREEN}`};
`;

const KpiHeader = styled.h5`
  margin: 0 0 0 20px;
  font-size: 0.6em;
  padding-top: 10px;
  color: ${NUVOTEXTBLUE};
`;

const InlineKpiHeader = styled.h5`
  display: inline;
  margin: 0 0 0 20px;
  font-size: 0.6em;
  padding-top: 10px;
  color: ${NUVOTEXTBLUE};
`;
