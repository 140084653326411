
import { gql, useQuery } from "@apollo/client";

const GET_MAP_LOCATIONS = gql`
    query GetSites($user_id: String!) {
        getSites (user_id: $user_id) {
            name
            latitude
            longitude
        }
    }
`;

const useGetMapLocations = ({ id, parent }) => {
    const mapLocationData = useQuery(GET_MAP_LOCATIONS, {
        skip: !parent,
        variables: { user_id: id }
    })

    return { mapLocationData }
}

export default useGetMapLocations;