import { useState, useContext, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate, useLocation } from 'react-router-dom';
import { setAccesstoken } from "../../accessToken";

const useSharedSignup = (validateRegister, handleRegistrationState, handleLoginActive) => {
    // const context = useContext(AuthContext);
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        password2: ''
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        console.log(queryParams);
        const email = queryParams.get('email');
        setValues({
            email: email,
        })

    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [RegisterSharedUser, { loading, error, data }] = useMutation(REGISTER_SHARED_USER, {
        update(_, {data: { registerUser: user}}) {
            console.log(user);
            if (user) {
                setAccesstoken(user.accessToken);
            }
            handleRegistrationState('success');
            handleLoginActive(true);
            navigate('/');
        },
        onError(error) {
            console.log(error);
            setErrors(error.graphQLErrors[0].extensions.exceptions.errors);
            handleRegistrationState('fail');
            handleLoginActive(true)
        },
        variables: values
    });


    const handleChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        });
    };


    const  handleSubmit =  e => {
        e.preventDefault();
        if (Object.keys(validateRegister(values)).length > 0) {
            setErrors(validateRegister(values))
        } else {
            RegisterSharedUser()
                .then(result => console.log('reg'))
                .catch(error => console.log(error));
        }
    }
    return {handleChange, handleSubmit, values, errors};

};

export default useSharedSignup;

const REGISTER_SHARED_USER = gql`
    mutation RegisterSharedUser($email: String!, $password: String!, $name: String! ) {
        registerSharedUser (username: $email, password: $password , name: $name) {
            access_token
            message
            user {
                id
                parent
            }
        }
    }
`;