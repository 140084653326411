import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import {NUVOSEMIFONT, NUVOTEXTBLUE} from "../../constants/styles";

const GridIcon = React.memo(({data, isGensetMeterSite, width, height, handleGrid}) => {
    const {eb_state, meter_active_power, meter2_active_power} = data?.getEnergyFlowData || {};

    useEffect(() => {
        if (data) {
            if (isGensetMeterSite) {
                handleGrid(meter2_active_power === 0, meter_active_power);
            } else {
                handleGrid(eb_state !== 0 && Boolean(meter_active_power), meter_active_power);
            }
        }
    }, [data]);

    const renderValue = useMemo(() => {
        const value = eb_state !== 0 ? meter_active_power : 0;
        return value ? value.toFixed(2).toLocaleString() : "-";
    }, [data, isGensetMeterSite]);

    return (
        <IconWrapper>
            <div>Grid</div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 82.494 113.863">
                <g transform="translate(0 0)">
                    <path d="M-30.883,27.46H-69.908a2.069,2.069,0,0,1-2.018-1.613A2.069,2.069,0,0,1-70.8,23.522L-31.774,4.9a2.066,2.066,0,0,1,2.759.975,2.069,2.069,0,0,1-.975,2.76L-60.765,23.32h29.882a2.068,2.068,0,0,1,2.07,2.07A2.069,2.069,0,0,1-30.883,27.46Z" transform="translate(71.978 -4.696)" fill="#7bce00"/>
                </g>
                <g transform="translate(0 28.311)">
                    <path d="M-30.883,44.558H-69.908a2.069,2.069,0,0,1-2.018-1.613A2.071,2.071,0,0,1-70.8,40.62L-31.774,22a2.066,2.066,0,0,1,2.759.975,2.067,2.067,0,0,1-.975,2.759L-60.765,40.418h29.882a2.069,2.069,0,0,1,2.07,2.07A2.069,2.069,0,0,1-30.883,44.558Z" transform="translate(71.978 -21.794)" fill="#7bce00"/>
                </g>
                <g transform="translate(3.625 4.549)">
                    <path d="M-67.718,116.757a2.063,2.063,0,0,1-1.265-.432,2.07,2.07,0,0,1-.649-2.427l25.713-62.409V9.513a2.07,2.07,0,0,1,2.07-2.07,2.069,2.069,0,0,1,2.07,2.07V51.9a2.071,2.071,0,0,1-.156.788l-22.984,55.787,29.354-22.146a2.073,2.073,0,0,1,2.9.406,2.07,2.07,0,0,1-.406,2.9l-35.4,26.706A2.069,2.069,0,0,1-67.718,116.757Z" transform="translate(69.788 -7.443)" fill="#7bce00"/>
                </g>
                <g transform="translate(12.293 18.624)">
                    <path d="M-62.484,31.239a2.069,2.069,0,0,1-2.07-2.07V18.014a2.068,2.068,0,0,1,2.07-2.07,2.069,2.069,0,0,1,2.07,2.07V29.169A2.071,2.071,0,0,1-62.484,31.239Z" transform="translate(64.554 -15.944)" fill="#7bce00"/>
                </g>
                <g transform="translate(12.293 46.935)">
                    <path d="M-62.484,48.335a2.068,2.068,0,0,1-2.07-2.07V35.112a2.069,2.069,0,0,1,2.07-2.07,2.071,2.071,0,0,1,2.07,2.07V46.265A2.069,2.069,0,0,1-62.484,48.335Z" transform="translate(64.554 -33.042)" fill="#7bce00"/>
                </g>
                <g transform="translate(29.495 46.934)">
                    <path d="M-40.83,71.916a2.071,2.071,0,0,1-1.555-.7,2.071,2.071,0,0,1,.184-2.921l13.912-12.261L-53.357,36.753a2.07,2.07,0,0,1-.378-2.9,2.068,2.068,0,0,1,2.9-.379l27.059,20.81a2.075,2.075,0,0,1,.808,1.571,2.076,2.076,0,0,1-.7,1.623L-39.462,71.4A2.061,2.061,0,0,1-40.83,71.916Z" transform="translate(54.164 -33.041)" fill="#7bce00"/>
                </g>
                <g transform="translate(20.921 67.745)">
                    <path d="M-21.639,49.749H-57.273a2.069,2.069,0,0,1-2.07-2.07,2.07,2.07,0,0,1,2.07-2.07h35.634a2.069,2.069,0,0,1,2.07,2.07A2.068,2.068,0,0,1-21.639,49.749Z" transform="translate(59.343 -45.61)" fill="#7bce00"/>
                </g>
                <g transform="translate(39.328 0.001)">
                    <path d="M-7.131,27.46H-46.156a2.071,2.071,0,0,1-2.07-2.07,2.069,2.069,0,0,1,2.07-2.07h29.882L-47.047,8.635a2.071,2.071,0,0,1-.977-2.76,2.07,2.07,0,0,1,2.76-.975L-6.238,23.522a2.068,2.068,0,0,1,1.126,2.325A2.067,2.067,0,0,1-7.131,27.46Z" transform="translate(48.226 -4.697)" fill="#7bce00"/>
                </g>
                <g transform="translate(39.328 28.312)">
                    <path d="M-7.131,44.558H-46.156a2.071,2.071,0,0,1-2.07-2.07,2.071,2.071,0,0,1,2.07-2.07h29.884L-47.047,25.731a2.069,2.069,0,0,1-.977-2.759A2.069,2.069,0,0,1-45.264,22L-6.238,40.62a2.07,2.07,0,0,1,1.126,2.325A2.067,2.067,0,0,1-7.131,44.558Z" transform="translate(48.226 -21.794)" fill="#7bce00"/>
                </g>
                <g transform="translate(39.329 4.549)">
                    <path d="M-10.756,116.757A2.069,2.069,0,0,1-12,116.34L-47.4,89.633a2.073,2.073,0,0,1-.406-2.9,2.073,2.073,0,0,1,2.9-.406l29.356,22.146L-38.538,52.688a2.071,2.071,0,0,1-.156-.788V9.513a2.069,2.069,0,0,1,2.07-2.07,2.07,2.07,0,0,1,2.07,2.07V51.489L-8.843,113.9a2.069,2.069,0,0,1-.647,2.427A2.066,2.066,0,0,1-10.756,116.757Z" transform="translate(48.226 -7.443)" fill="#7bce00"/>
                </g>
                <g transform="translate(66.063 18.624)">
                    <path d="M-30.01,31.239a2.069,2.069,0,0,1-2.07-2.07V18.014a2.068,2.068,0,0,1,2.07-2.07,2.069,2.069,0,0,1,2.07,2.07V29.169A2.071,2.071,0,0,1-30.01,31.239Z" transform="translate(32.08 -15.944)" fill="#7bce00"/>
                </g>
                <g transform="translate(66.063 46.935)">
                    <path d="M-30.01,48.335a2.068,2.068,0,0,1-2.07-2.07V35.112a2.069,2.069,0,0,1,2.07-2.07,2.071,2.071,0,0,1,2.07,2.07V46.265A2.069,2.069,0,0,1-30.01,48.335Z" transform="translate(32.08 -33.042)" fill="#7bce00"/>
                </g>
                <g transform="translate(21.799 46.936)">
                    <path d="M-40.947,71.915a2.061,2.061,0,0,1-1.368-.518l-15.8-13.922a2.076,2.076,0,0,1-.7-1.623A2.075,2.075,0,0,1-58,54.281l27.059-20.81a2.071,2.071,0,0,1,2.9.379,2.07,2.07,0,0,1-.378,2.9L-53.488,56.031l13.91,12.261a2.07,2.07,0,0,1,.184,2.921A2.068,2.068,0,0,1-40.947,71.915Z" transform="translate(58.813 -33.042)" fill="#7bce00"/>
                </g>
                <g transform="translate(21.8 67.745)">
                    <path d="M-21.109,49.749H-56.742a2.068,2.068,0,0,1-2.07-2.07,2.069,2.069,0,0,1,2.07-2.07h35.633a2.069,2.069,0,0,1,2.07,2.07A2.068,2.068,0,0,1-21.109,49.749Z" transform="translate(58.812 -45.61)" fill="#7bce00"/>
                </g>
            </svg>
            {renderValue}
            <div>kW</div>
        </IconWrapper>
    )
});

export default GridIcon;

const IconWrapper = styled.div`
  font-family: ${NUVOSEMIFONT};
  color: ${NUVOTEXTBLUE};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
