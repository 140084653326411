import React, {useContext} from 'react';
import {useQuery} from "@apollo/client";
import Spinner from "../../Spinner/Spinner";
import styled from "styled-components";
import UserContext from "../../../context/UserContext";
import usePermission from "../../Hooks/useGetPermissions";
import {SUPER_ADMIN_ROLE} from "../../../helpers/variables";
import EditButton from "./EditButton";
import {isLiveColor, isLiveHandler} from "../../DataGrid/handlers";
import ChangeOwner from "./ChangeOwner";
import SharedWith from "./SharedWith";
import ModalPortal from "../ModalPortal";
import ShareModal from "./ShareModal";
import {NUVOREGULARFONT} from "../../../constants/styles";

const SitesTable = ({query, func}) => {
    const {user} = useContext(UserContext);
    const hasPermission = usePermission(SUPER_ADMIN_ROLE)

    const {loading, error, data} = useQuery(query, {variables: {user_id: user.id}, fetchPolicy: 'cache-and-network'});
    if (loading) return <Spinner/>
    if (data && data[func].length === 0) return <h2 style={{textAlign: 'center'}}>No data to display</h2>

    return (
        <Card>
            <TableWrapper>
                <StyledTable>
                    <thead>
                    <tr>
                        <th>Status</th>
                        <th>Device Name</th>
                        <th>Device ID</th>
                        <th>Locations</th>
                        <th>No. Inverters</th>
                        <th>Current Power (kW)</th>
                        <th>Grid power (kW)</th>
                        <th>Total Production</th>
                        {func !== 'getSharedSites' && <th>Actions</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {data && data[func].map((row, index) => {
                            const totalEnergy = row.total_kwh_active ? row.total_kwh_active.toFixed(0) : null
                            return (
                                <tr key={row.id}>
                                    <td data-label="Status"
                                        style={{color: isLiveColor(isLiveHandler(row.seconds_past_now, row.online_check))}}>• {isLiveHandler(row.seconds_past_now, row.online_check)}</td>
                                    <td data-label="Name">{row.name}</td>
                                    <td data-label="MAC ID">{row.mac_id}</td>
                                    <td data-label="Location">{row.location}</td>
                                    <td data-label="Inverter Count">{row.inverter_count}</td>
                                    <td data-label="Current Power">{Math.round(row.total_ac_active_power / 1000)}</td>
                                    <td data-label="Grid Power">{Math.round(row.grid_power / 1000)}</td>
                                    <td data-label="Total Production">{totalEnergy ? new Intl.NumberFormat().format(totalEnergy) : null}</td>
                                    {func !== 'getSharedSites' && <Actions>
                                        {func !== 'getSharedSites' &&
                                            <ModalPortal icon="ic:outline-screen-share" title="Share site">
                                                {(setIsOpen) => <ShareModal deviceId={row.id} deviceName={row.name}
                                                                            setIsOpen={setIsOpen}/>}
                                            </ModalPortal>
                                        }
                                        {func !== 'getSharedSites' &&
                                            <ModalPortal icon="carbon:folder-shared" title="Shared with...">
                                                {(setIsOpen) => <SharedWith deviceId={row.id} setIsOpen={setIsOpen}/>}
                                            </ModalPortal>
                                        }
                                        {func !== 'getSharedSites' && <EditButton deviceId={row.mac_id}/>}
                                        {func === 'getAllSites' && hasPermission &&
                                            <ModalPortal icon="mdi:user-edit" title="Change owner">
                                                {(setIsOpen) => <ChangeOwner deviceId={row.id} deviceName={row.name}
                                                                             owner={row.owner?.username}
                                                                             setIsOpen={setIsOpen}/>}
                                            </ModalPortal>
                                        }
                                    </Actions>}
                                </tr>
                            )
                        }
                    )}
                    </tbody>
                </StyledTable>
            </TableWrapper>
        </Card>
    )
};

export default SitesTable;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: auto;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-right: 16px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  max-height: 70vh;
`;

const Actions = styled.td`
  display: flex;
  flex-direction: row;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
  font-family: ${NUVOREGULARFONT};

  @media (min-width: 768px) {
    width: 100%;
  }

  th,
  td {
    padding: 12px;
    text-align: center;
  }

  th {
    background-color: #fafafa;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
    color: rgb(47, 101, 158);
    font-size: 14px;
  }

  td {
    font-size: 12px;
    color: rgb(47, 101, 158)
  }

  tr {
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) {
    background-color: #ffffff;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: rgba(47, 101, 158, 0.66);
  }
`;
