import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import {GUEST_ROLE} from "../../../helpers/variables";
import PopoverContent from "./PopoverContent";

const Popover = ({userId, userRoles}) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const title = () => {
        if (userRoles.length > 0) {
            return userRoles[0].name
        } else return GUEST_ROLE
    }

    return (
        <PopoverContainer ref={popoverRef}>
            <TriggerButton isOpen={isOpen} onClick={handleToggle}>
                {title()}
            </TriggerButton>
            {isOpen && <PopoverContent userRoles={userRoles} userId={userId} handleToggle={handleToggle}/>}
        </PopoverContainer>
    );
};

export default Popover;

const PopoverContainer = styled.div`
  position: static;
`;

const TriggerButton = styled.button`
  cursor: pointer;
  padding: 5px;
  font-size: 11px;
  color: #000000;
  background-color: ${({isOpen}) => (isOpen ? '#91CC32' : 'rgba(145, 204, 50, 0.11)')};
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    background-color: #91CC32;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
  }
`;


