
import { gql, useQuery } from "@apollo/client";
import {useEffect, useState} from "react";

const  GET_SITE_BY_ID = gql`
    query GetSiteById ($deviceId: String!) {
        getSiteById (deviceId: $deviceId) {
            mac_id
            name
            location
            latitude
            longitude
            username
            inverter_type
            inverter_count
            genset_config
            meter_count
            meter1_label
            meter2_label
            meter3_label
            meter4_label
            meter5_label
        }
    }
`;

const useGetSiteById = (editSiteId) => {

    const [editSiteDetails, setEditSiteDetails] = useState({});

    const siteDetails = useQuery(GET_SITE_BY_ID, {
        variables: { deviceId: editSiteId },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (siteDetails.data) {
            const { getSiteById } = siteDetails.data
             // using short circut feature to replace when value is null , otherwise JS crashes
            setEditSiteDetails({
                email: getSiteById.username || 'email',
                deviceName: getSiteById.name || 'Add Site Name',
                deviceId: getSiteById.mac_id || 'device ID',
                location: getSiteById.location || 'Add Location',
                latitude: getSiteById.latitude || ' Add Latitude',
                longitude: getSiteById.longitude || 'Add longitude',
                inverterType: getSiteById.inverter_type || 'Add Inverter Type',
                inverterCount: getSiteById.inverter_count || "1",
                gensetConfig: getSiteById.genset_config || "EB State",
                meterCount: getSiteById.meter_count || "1",
                meter1Label: getSiteById.meter1_label || 'Add Meter 1 Label',
                meter2Label: getSiteById.meter2_label || 'Add Meter 2 Label',
                meter3Label: getSiteById.meter3_label || 'Add Meter 3 Label',
                meter4Label: getSiteById.meter4_label || 'Add Meter 4 Label',
                meter5Label: getSiteById.meter5_label || 'Add Meter 5 Label',
            });
        }
    }, [siteDetails])

    return { editSiteDetails };
}

export default useGetSiteById;

