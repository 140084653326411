
import { useEffect, useState } from "react";
import { MOBILE_OFFSET } from "../../constants/offsets";

const useMobileView = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(windowWidth < MOBILE_OFFSET);

    useEffect(() => {
       const handleIsMobile = () => {
           setWindowWidth(window.innerWidth);
           windowWidth < MOBILE_OFFSET ? setIsMobile(true) : setIsMobile(false);
       }
        window.addEventListener("resize", handleIsMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return { isMobile };
}

export default useMobileView;
