import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import {InlineIcon} from '@iconify/react';
import circleIcon from '@iconify/icons-mdi/circle-medium';
import './DataGrid.css';
import {
    headerColor,
    isLiveHandler,
    isLiveColor,
    rowStyle,
    gridStyle,
    rowClassName
} from "./handlers"
import {useNavigate} from "react-router-dom";

const DataGrid = ({data, loading}) => {
    const navigate = useNavigate();

    const columns = [
        {
            name: 'status', header: 'Status', minWidth: 20, defaultFlex: 1, headerProps: {style: headerColor},
            render: ({value, data}) => {
                const color = isLiveColor(isLiveHandler(data.seconds_past_now, data.online_check));
                return (
                    <React.Fragment>
                        <InlineIcon icon={circleIcon} color={color}/>
                        <StatusWrapper style={{color}}>{value}</StatusWrapper>
                    </React.Fragment>
                )
            }
        },
        {name: 'deviceName', header: 'Device Name', minWidth: 50, defaultFlex: 1, headerProps: {style: headerColor}},
        {name: 'deviceId', header: 'Device ID', minWidth: 50, defaultFlex: 1, headerProps: {style: headerColor}},
        {name: 'location', header: 'Locations', minWidth: 50, defaultFlex: 1, headerProps: {style: headerColor}},
        {name: 'inverterCount', header: 'No. Inverters', minWidth: 20, defaultFlex: 1, headerProps: {style: headerColor}},
        {
            name: 'currentPower',
            header: 'Current Power (kW)',
            minWidth: 30,
            defaultFlex: 1,
            headerProps: {style: headerColor},
            onRender: (cellProps, {data}) => {
                cellProps.style.color = isLiveColor(isLiveHandler(data.seconds_past_now, data.online_check));
            }
        },
        {name: 'gridPower', header: 'Grid power (kW)', minWidth: 25, defaultFlex: 1, headerProps: {style: headerColor}},
        {
            name: 'totalEnergy',
            header: 'Total Production (kWh)',
            minWidth: 30,
            defaultFlex: 1,
            headerProps: {style: headerColor},
        },
    ];

    const OnRowClick = useCallback((rowProps) => {
        const siteData = rowProps.data;
        navigate(`/dashboard/site`, {state: {deviceId: siteData.deviceId}});
    }, [navigate]);

    const onRenderRow = useCallback((rowProps) => {
        const {onClick} = rowProps;

        rowProps.onClick = (event) => {
            OnRowClick(rowProps);
            if (onClick) {
                onClick(event);
            }
        };
    }, [OnRowClick]);

    const defaultSortInfo = useMemo(() => [{name: 'deviceName', dir: 1}], []);

    return (
        <ReactDataGrid
            idProperty="deviceId"
            loading={loading}
            enableSelection={true}
            onRenderRow={onRenderRow}
            defaultSortInfo={defaultSortInfo}
            showColumnMenuTool={false}
            rowStyle={rowStyle}
            rowClassName={rowClassName}
            columns={columns}
            dataSource={data}
            style={gridStyle}
            pagination={true}
            defaultLimit={5}
            pageSizes={[4, 8, 20]}
            showCellBorders="horizontal"
        />
    );
}

export default DataGrid;

const StatusWrapper = styled.div`
  display: inline-block;
`;
