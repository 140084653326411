import styled from 'styled-components';
import React, {useMemo} from 'react';
import {NUVOWHITE, NUVOBOLDFONT, NUVOSEMIFONT, NUVOTEXTBLUE} from "../../constants/styles";
import useMobileView from "../Hooks/useMobileView";
import PropTypes from "prop-types";

const KpiCard = ({items}) => {
    const {isMobile} = useMobileView();
    const isDouble = items.length > 1;
    const props = useMemo(() => ({isMobile, isDouble}), [isMobile, isDouble]);

    return (
        <KpiCardWrapper {...props}>
            {items.map((item) => (
                <React.Fragment key={item.description}>
                    <Text {...props}>{item.description}</Text>
                    <Value {...props} color={item.color}>{item.value.toLocaleString()}</Value>
                    {item.unit && <Text {...props}>{item.unit}</Text>}
                </React.Fragment>
            ))}
        </KpiCardWrapper>
    );
};

KpiCard.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        unit: PropTypes.string,
    })).isRequired,
};

export default KpiCard;

const KpiCardWrapper = styled.div`
  width: ${props => props.isMobile ? '80px' : '24%'};
  height: 90px;
  margin-top: 20px;
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  font-size: ${props => props.isDouble ? '14px' : '18px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
`;

const Value = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.isMobile ? (props.isDouble ? '0.8em' : '0.7em') : (props.isDouble ? '1em' : '1.7em')};
`;

const Text = styled.div`
  font-family: ${NUVOSEMIFONT};
  font-size: ${props => props.isMobile ? (props.isDouble ? '0.7em' : '0.5em') : '0.8em'};
  color: ${NUVOTEXTBLUE};
`;
