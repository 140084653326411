
import React from "react";
import styled from "styled-components";
import {BOXSHADOWBLUE, NUVOGREEN, NUVOBOLDFONT, NUVOHEADERBLUE, NUVOWHITE} from "../../constants/styles";
import RedirectButton from "../ButtonComponents/RedirectButton";
import { useNavigate } from "react-router-dom";
import useRenderNav from "../Hooks/Nav/useRenderNav";
import useMobileView from "../Hooks/useMobileView";

const ShareSiteSuccess = () => {
  const navigate = useNavigate();
  const isMobile = useMobileView();
  const { renderNav } = useRenderNav(isMobile);
    return (
      <PageWrapper>
        {renderNav()}
        <DashboardContent>
            <DashBoardHeaderWrapper>
                <DashBoardHeader>Share Site</DashBoardHeader>
            </DashBoardHeaderWrapper>
            <AddDeviceWrapper>
                    <CheckBoxWrapper>
                        <CheckBoxText> Site Successfully Shared</CheckBoxText>
                    </CheckBoxWrapper>
                <RedirectButton onClick={() => navigate('/dashboard')}> Back to Dashboard </RedirectButton>
            </AddDeviceWrapper>
        </DashboardContent>
      </PageWrapper>
        
    )
}

export default ShareSiteSuccess;

const PageWrapper = styled.div`
  display: flex;
`;
  

const DashboardContent = styled.div`
  width: 100%;
`;

const DashBoardHeaderWrapper = styled.div`
  width: 100%;
  height: 50px;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 14px;
  box-shadow: 0 1px 10px -2px ${BOXSHADOWBLUE};
  margin-bottom: 40px;
`;

const DashBoardHeader = styled.h1`
  margin: 0 0 0 30px;
`;

const AddDeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const CheckBoxWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
`;

const CheckBoxText = styled.div`
  color: ${NUVOWHITE};
  font-size: 30px;
  background-color: ${NUVOGREEN};
  border-radius: 3px;
  text-align: center;
  font-weight: 600;
  width: 100%;
`;