
import styled from "styled-components";
import useMobileView from "../Hooks/useMobileView";

const ProductionTable = ({inverterData}) => {

  const { isMobile } = useMobileView();

    return (
      <>
        <Wrapper>
          <Production>Inv{inverterData.inverter}</Production>
        </Wrapper>
        <StyledTable>
        <thead>
            <tr>
                <th>Start</th>
                <th>End</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style={{color: '#5D5D5D'}}>{inverterData.startReading === null ? `-` : parseFloat(inverterData.startReading).toLocaleString('en')}</td>
                <td style={{color: '#5D5D5D'}}>{inverterData.endReading === null ? `-` : parseFloat(inverterData.endReading).toLocaleString('en')}</td>
            </tr>
            <tr>
            </tr>
        </tbody>
    </StyledTable>
      <Wrapper>
        <Value>{parseFloat(inverterData.endReading - inverterData.startReading).toLocaleString('en')}</Value>
      </Wrapper>
    </>
    )
    
}

export default ProductionTable;

const Production = styled.div`
  color: #4a87f9;
  font-size: 10px;
`;

const Value = styled.div`
  color: #009e49;
  font-size: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

`;
 const StyledTable = styled.table`
     border: none;
     border-collapse: collapse;
     td,
  th {
    border: none;
    padding: 0;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 3px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
    }
  }
  thead > tr {
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
 `;
