import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useMemo, useState } from 'react';
import UnavailableFeature from '../Dashboard/Charts/UnavailableFeature';
import useMobileView from '../Hooks/useMobileView';

const MonthlyBarChart = ({ data, type, duration, showInverters, showCheckMeter, keys, groupMode, colors, labelSkipWidth }) => {
    const { isMobile } = useMobileView();

    const [xAxisTicks, setXAxisTicks] = useState([]);

    const dataToDisplay = useMemo(() => {
        if (type === 'production') {
            if (!showInverters) {
                if (duration === '1M') return data.monthTotal;
                if (duration === '7D') return data.weekTotal;
                if (duration === 'YTD') return data.ytdTotal;
            } else {
                if (duration === '1M') return data.monthByInverter;
                if (duration === '7D') return data.weekByInverter;
                if (duration === 'YTD') return data.ytdByInverter;
            }
        } else if (type === 'grid') {
            if (!showCheckMeter) {
                return data.gridGensetRunningTotals;
            }
        }
        return [];
    }, [data, type, duration, showInverters, showCheckMeter]);

    useEffect(() => {
        if (isMobile) {
            setXAxisTicks(dataToDisplay.filter((_, index) => index % 5 === 0).map(item => item.day));
        } else {
            setXAxisTicks(dataToDisplay.filter((_, index) => index % 2 === 0).map(item => item.day));
        }
    }, [isMobile, dataToDisplay]);

    if (showCheckMeter && type === 'grid') {
        return <UnavailableFeature feature={'check meter'} />;
    }

    return (
        <ResponsiveBar
            data={dataToDisplay}
            keys={keys ? keys : ['kWh']}
            indexBy="day"
            groupMode={groupMode ? groupMode : 'stacked'}
            margin={{ top: 30, right: isMobile ? 50 : 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            valueFormat={value => `${Math.round(Number(value)).toLocaleString('en', { minimumFractionDigits: 0 })}`}
            colors={colors ? colors : '#1E8C45'}
            colorBy="id"
            legends={
                isMobile
                    ? []
                    : [
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 1,
                            itemWidth: 110,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 8,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 0.7,
                                    },
                                },
                            ],
                        },
                    ]
            }
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: xAxisTicks,
                legend: 'Day of the month',
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'kWh',
                legendPosition: 'middle',
                legendOffset: -40,
                format: '.2s',
            }}
            labelSkipWidth={labelSkipWidth ? labelSkipWidth : 15}
            formattedValue
            labelFormat="0f"
            labelSkipHeight={12}
            labelTextColor="#FFFFFF"
        />
    );
};

export default MonthlyBarChart;
