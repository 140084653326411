import { gql, useQuery } from "@apollo/client";

const GET_INVERTER_POWER_BY_DATE = gql`
    query GetInverterPowerByDeviceIdByDate ($deviceId: String!, $date: String!) {
        getInverterPowerByDeviceIdByDate (deviceId: $deviceId, date: $date ) {
            id
            data {
                x
                y
            }
        }
    }
`;

const useGetInverterPowerData = (deviceId, date) => {
    const { loading, error, data } = useQuery(GET_INVERTER_POWER_BY_DATE, {
        variables: { deviceId: deviceId, date: date },
        onError: (error) => {
            console.log("useGetInverterPowerData error: ", error);
        }
    });

    return { loading, error, data };
}

export default useGetInverterPowerData;
