import styled from 'styled-components';
import {useNavigate} from "react-router-dom";
import {IS_LOGGED_IN} from "../../graphql/queries";
import {useApolloClient} from "@apollo/client";

const SignOut = () => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const handleLogout = async (event, client) => {
        event.preventDefault();
        localStorage.removeItem('authToken');
        client.cache.writeQuery({
            query: IS_LOGGED_IN,
            data: {
                isLoggedIn: false
            }
        });
        navigate('/');
    };

    return (
        <SignOutWrapper onClick={(event) => handleLogout(event, client)}>Sign Out</SignOutWrapper>
    );
}

export default SignOut;

const SignOutWrapper = styled.a`
  margin: 0 30px 0 0;
  cursor: pointer;
`;
