
import React from "react";
import styled from "styled-components";
import { NUVOBLUE } from "../constants/styles";
import signupImage from "../assets/images/signup_image.jpg"
import RegLoginBox from "./RegLoginBox";

const Landing = () => {

    return (
            <Wrapper>
                <BlueBackground />
                <SignupImage />
                <RegLoginBox />
            </Wrapper>
    )
}

export default Landing;

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlueBackground = styled.div`
  float: left;
  width: 50%;
  height: 100% ;
  background-color: ${NUVOBLUE};
`;

export const SignupImage = styled.div`
  float: right;
  width: 50%;
  height: 100%;
  background-image: url(${signupImage});
  object-fit: contain;
`;
