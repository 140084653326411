import { gql, useQuery} from "@apollo/client";

const GET_GRID_POWER_BY_DATE = gql`
    query GetGridPowerByDeviceIdByDate ($deviceId: String!, $date: String!) {
        getGridPowerByDeviceIdByDate (deviceId: $deviceId, date: $date) {
            x
            y_ac_active_power_a
            y_ac_active_power_b
            y_ac_active_power_c
        }
    }
`;

const useGetGridPowerData = (deviceId, date) => {
    const { loading, error, data } = useQuery(GET_GRID_POWER_BY_DATE, {
        variables: { deviceId: deviceId, date: date },
        onError: (error) => {
            console.log("useGetGridPowerData error: ", error);
        }
    });

    return { loading, error, data };
}

export default useGetGridPowerData;
