import Landing from "../Components/Landing";
import ForgotPassword from "../Components/ForgotPassword/ForgotPassword";
import SharedSignup from "../Components/SharedSignup/SharedSignup";
import PasswordReset from "../Components/PasswordReset/PasswordReset";

// ==============================|| PUBLIC ROUTING ||============================== //

const PublicRoutes = [
    {path: "*", element: <Landing/>},
    {path: "/", element: <Landing/>},
    {path: "/signup", element: <SharedSignup/>},
    {path: "forgot-password", element: <ForgotPassword/>},
    {path: "password-reset/token/:token", element: <PasswordReset/>},
]

export default PublicRoutes;
