import React, {useContext, useEffect, useReducer, useCallback} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DayPicker from '../DayPicker/DayPicker';
import Spinner from '../../Spinner/Spinner';
import SiteChart from '../../SiteChart/SiteChart';
import {DateContext} from "../../../context/DateContext";
import {NUVOWHITE, NUVOHEADERBLUE, NUVOBOLDFONT} from '../../../constants/styles';
import useGetGridPowerData from '../../Hooks/useGetGridPowerData';
import useGetGridVoltageData from '../../Hooks/useGetGridVoltageData';
import useGetInverterPowerData from '../../Hooks/useGetInverterPowerData';

const transformGridVoltageData = (getGridVoltageByDeviceIdByDate) => {
    const transform = (key) => getGridVoltageByDeviceIdByDate.map((item) => ({ "x": item.x, "y": item[key] }));
    return [
        {id: "Phase C (V)", data: transform('y_voltage_cn')},
        {id: "Phase B (V)", data: transform('y_voltage_bn')},
        {id: "Phase A (V)", data: transform('y_voltage_an')}
    ];
};

const transformGridPowerData = (getGridPowerByDeviceIdByDate) => {
    const transform = (key) => getGridPowerByDeviceIdByDate.map((item) => {
        const value = (item[key] / 1000).toFixed(2);
        return {"x": item.x, "y": value};
    });
    return [
        {id: "Phase C (kW)", data: transform('y_ac_active_power_c')},
        {id: "Phase B (kW)", data: transform('y_ac_active_power_b')},
        {id: "Phase A (kW)", data: transform('y_ac_active_power_a')}
    ];
};

const transformInverterPowerData = (getInverterPowerByDeviceIdByDate) => {
    return getInverterPowerByDeviceIdByDate.map((inverter) => {
        return {
            ...inverter,
            data: inverter.data.map((item) => {
                const value = (item.y / 1000).toFixed(2);
                return {...item, y: value};
            })
        }
    });
};

const chartReducer = (state, action) => {
    switch (action.type) {
        case 'SET_GRID_VOLTAGE':
            return {...state, gridVoltageChartData: transformGridVoltageData(action.data.getGridVoltageByDeviceIdByDate)};
        case 'SET_GRID_POWER':
            return {...state, gridPowerChartData: transformGridPowerData(action.data.getGridPowerByDeviceIdByDate)};
        case 'SET_INVERTER_POWER':
            return {...state, inverterPowerChartData: transformInverterPowerData(action.data.getInverterPowerByDeviceIdByDate)};
        case 'SET_CHART_TYPE':
            return {...state, chartType: action.chartType};
        default:
            throw new Error();
    }
};

const initialChartData = {
    gridVoltageChartData: [
        {id: "Phase C (V)", data: []},
        {id: "Phase B (V)", data: []},
        {id: "Phase A (V)", data: []},
    ],
    gridPowerChartData: [
        {id: "Phase C (kW)", data: []},
        {id: "Phase B (kW)", data: []},
        {id: "Phase A (kW)", data: []},
    ],
    inverterPowerChartData: null,
    chartType: 'gridPower',
};

const chartTypes = {
    'gridPower': 'Grid Power',
    'gridVoltage': 'Grid Voltage',
    'inverterPower': 'Inverter Power'
}

const DetailedDistribution = React.memo(({deviceId}) => {
    const {date} = useContext(DateContext);
    const dateFormatted = moment(date).format('YYYY-MM-DD');
    const {loading: gridPowerLoading, data: gridPowerData} = useGetGridPowerData(deviceId, dateFormatted);
    const {loading: gridVoltageLoading, data: gridVoltageData} = useGetGridVoltageData(deviceId, dateFormatted);
    const {loading: inverterPowerLoading, data: inverterPowerData} = useGetInverterPowerData(deviceId, dateFormatted);
    const [chartData, dispatch] = useReducer(chartReducer, initialChartData);

    const handleChartType = useCallback((type) => {
        dispatch({type: 'SET_CHART_TYPE', chartType: type});
    }, []);

    useEffect(() => {
        if (gridPowerData) dispatch({type: 'SET_GRID_POWER', data: gridPowerData});
        if (gridVoltageData) dispatch({type: 'SET_GRID_VOLTAGE', data: gridVoltageData});
        if (inverterPowerData) dispatch({type: 'SET_INVERTER_POWER', data: inverterPowerData});
    }, [gridVoltageData, gridPowerData, inverterPowerData]);

    const renderChart = useCallback(() => {
        switch (chartData.chartType) {
            case 'gridVoltage':
                return gridVoltageLoading ? <Spinner/> : <SiteChart
                    data={chartData.gridVoltageChartData}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 'auto',
                        stacked: false,
                        reverse: false
                    }}/>
            case 'gridPower':
                return gridPowerLoading ? <Spinner/> : <SiteChart
                    data={chartData.gridPowerChartData}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: false,
                        reverse: false
                    }}/>
            case 'inverterPower':
                return inverterPowerLoading ? <Spinner/> : <SiteChart
                    data={chartData.inverterPowerChartData}
                    colors={[
                        '#bad80a', '#009e49', '#00b294', '#00bcf2', '#00188f',
                        '#68217a', '#ec008c', '#e81123', '#ff8c00', '#fff100'
                    ]}
                    yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
                    legendItemSpacing={55}/>
            default:
                return null;
        }
    }, [gridVoltageLoading, chartData.gridVoltageChartData, gridPowerLoading, chartData.gridPowerChartData, inverterPowerLoading, chartData.inverterPowerChartData, chartData.chartType])

    return (
        <>
            <DateWrapper>
                <DayPicker/>
            </DateWrapper>
            <ControlsWrapper>
                <ChartTimeWrapper>
                    {Object.keys(chartTypes).map(type => (
                        <ChartTimeSelector
                            key={type}
                            onClick={() => handleChartType(type)}
                            active={type === chartData.chartType}>
                            {chartTypes[type]}
                        </ChartTimeSelector>
                    ))}
                </ChartTimeWrapper>
                <ToggleWrapper>
                </ToggleWrapper>
            </ControlsWrapper>
            <SiteChartWrapperLarge>
                <CardHeader>Power Distribution</CardHeader>
                <SiteChartCard>
                    {renderChart()}
                </SiteChartCard>
            </SiteChartWrapperLarge>
        </>
    )
});

export default DetailedDistribution;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
`;
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
`;

const ChartTimeWrapper = styled.div`
  display: flex;
`;

const ChartTimeSelector = styled.div`
  margin-right: 20px;
  color: ${props => props.active ? `#1976d2` : '#5D5D5D'};
  border-bottom: ${props => props.active ? `2px solid #1976d2` : 'none'};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
`;

const ToggleWrapper = styled.div`
  text-align: center;
  margin-left: 100px;
`;

const SiteChartWrapperLarge = styled.div`
  background-color: ${NUVOWHITE};
  width: 100%;
  margin: 10px auto 0 auto;
  height: 470px;
  border: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
`;

const SiteChartCard = styled.div`
  width: 100%;
  height: 90%;
`;

const CardHeader = styled.div`
  color: ${NUVOHEADERBLUE};
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  height: 30px;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;
