import {useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {emailRegex} from "../../helpers/variables";

const SHARE_SITES = gql`
    mutation OwnerShareSites ($users: [String]!, $site_id: Int!) {
        ownerAssignSiteUsers(users: $users, site_id: $site_id) {
            success
            message
            error
        }
    }
`;

const useShareSites = (site_id, setIsOpen) => {
    const [values, setValues] = useState({
        email: '',
        email2: '',
    })

    const [error, setError] = useState(null);

    const [ownerShareSites] = useMutation(SHARE_SITES, {
        onError(error) {
            setError(error.message)
        },
        onCompleted() {
            setIsOpen && setIsOpen(false)
        },
        variables: {users: [values.email], site_id}
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }


    const isValidEmail = (email) => {
        return emailRegex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isValidEmail(values.email)) {
            ownerShareSites()
                .catch(error => console.log(error))
        } else setError('The email format is not correct')
    }

    return {handleChange, handleSubmit, values, error}
}

export default useShareSites;
