import {useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {emailRegex} from "../../helpers/variables";

const useAdminAddUser = (validateRegister, setIsOpen) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
        isChecked: false
    });

    const [errors, setErrors] = useState({});
    const [RegisterUser] = useMutation(REGISTER_USER, {
        onCompleted() {
            setIsOpen && setIsOpen(false)
        },
        onError(error) {
            setErrors({error: error.message});
        },
        variables: {name: values.name, email: values.email, password: values.isChecked ? '' : values.password}
    });

    const handleChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleCheckbox = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.checked
        })
    }

    const isValidEmail = (email) => {
        return emailRegex.test(email);
    };


    const handleSubmit = e => {
        e.preventDefault();
        if (values.isChecked) {
            if (Object.keys(validateRegister(values)).length > 0) {
                setErrors(validateRegister(values))
            } else {
                RegisterUser()
                    .catch(error => console.log(error));
            }
        } else if (isValidEmail(values.email)) {
            RegisterUser()
                .catch(error => console.log(error));
        } else {
            setErrors({email: 'Email address is invalid'})
        }
    }
    return {handleChange, handleSubmit, handleCheckbox, values, errors};

};

export default useAdminAddUser;

const REGISTER_USER = gql`
    mutation AdminAddUser($name: String!, $email: String!, $password: String! ) {
        adminAddUser (name: $name, email: $email , password: $password) {
            message
        }
    }
`;
