import {ApolloClient, InMemoryCache, ApolloLink, gql, HttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {IS_LOGGED_IN} from "./queries";

const httpLink = new HttpLink({uri: '/graphql'});

const cache = new InMemoryCache();

const errorLink = onError(({graphQLErrors, networkError, operation, response}) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
            const {message, locations, path} = error;
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

            // Handle specific error messages
            if (message === 'Context creation failed: invalid signature' || message === 'Context creation failed: invalid token' || message === 'Context creation failed: jwt expired' || message === 'User is not authenticated') {
                handleTokenExpiration(error, operation, response);
            }
            // Add more specific error handling here if needed
        });
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // Handle network errors if needed
    }
});

function handleTokenExpiration(error, operation, response) {
    localStorage.removeItem('authToken');
    cache.writeQuery({
        query: IS_LOGGED_IN,
        data: {
            isLoggedIn: false
        }
    });
}

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('authToken');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`
        }
    };
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

// eslint-disable-next-line import/prefer-default-export
export const client = new ApolloClient({
    link,
    cache
});

const query = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
    }
`;

cache.writeQuery({
    query,
    data: {
        isLoggedIn: !!localStorage.getItem('authToken')
    }
});
