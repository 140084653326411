
import { useState } from "react";
import Header1Blue from "../../TextComponents/Header1";
import FormContainer from "../../FormComponents/FormContainer";
import FormLabel from "../../FormComponents/FormLabel";
import Input from "../../FormComponents/Input";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import useProfile from "./useProfile";
import validateProfile from "./validateProfile";
import styled from "styled-components";
import {NUVOGREEN, NUVORED, NUVOWHITE} from "../../../constants/styles";

const UpdateProfile = () => {

    const [updateSuccess, setUpdateSuccess] = useState(null)
    const handleUpdateSuccess = (value) => {
        setUpdateSuccess(value);
    }
    const { handleChange, handleSubmit, values, errors } = useProfile(validateProfile, handleUpdateSuccess );

    const handleUpdateStateRender = (updateState) => {
        switch(updateState) {
            case "success":
                return <RegSuccess>Updates have successfully been saved</RegSuccess>;
            case "fail":
                return <RegFail>Oops something went wrong, update failed</RegFail>;
            default:
                return null;
        }
    }

    return (
        <AddDeviceWrapper>
            <Header1Blue>Update Profile</Header1Blue>
            <MessageWrapper>
                {handleUpdateStateRender(updateSuccess)}
            </MessageWrapper>
            <FormContainer noValidate className='form' onSubmit={handleSubmit}>
                <FormLabel htmlFor="name" name="Name"/>
                <Input
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    fullWidth={true}
                />
                <ErrorMessage message={errors.name}/>
                <FormLabel htmlFor="email" name="Email"/>
                <Input
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    fullWidth={true}
                />
                <ErrorMessage message={errors.email}/>
                <ButtonWrapper>
                    <SubmitButton>Update Profile</SubmitButton>
                </ButtonWrapper>
            </FormContainer>
        </AddDeviceWrapper>
    )
}

export default UpdateProfile;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`;

const AddDeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  margin: 0 auto;
`;

const RegSuccess = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  color: ${NUVOWHITE};
  background-color: ${NUVOGREEN};
  border-radius: 3px;
  text-align: center;
`;

const RegFail = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  color: ${NUVOWHITE};
  background-color: ${NUVORED};
  border-radius: 3px;
  text-align: center;
`;

const MessageWrapper = styled.div`
    height: 20px;
`;

