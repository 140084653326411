
import styled from "styled-components";
import { NUVOTEXTBLUE } from "../../constants/styles";
import { NUVOREGULARFONT } from "../../constants/styles";

const BigTextBlue = styled.p`
  font-family: ${NUVOREGULARFONT};
  color: ${NUVOTEXTBLUE};
  font-size: 16px;
`;

export default BigTextBlue;

