import {emailRegex} from "../../helpers/variables";

const validateLogin = (values) => {
    let errors = {}

    if (!values.email) {
        errors.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
        errors.email = "Email address is invalid";
    }

    if (!values.password) {
        errors.password = "Password is required";
    }

    return errors;
}

export default validateLogin;