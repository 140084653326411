import React from 'react';
import SubmitButton from "../../ButtonComponents/SubmitButton";
import styled from "styled-components";
import Modal from "../Modal";
import FormLabel from "../../FormComponents/FormLabel";
import Input from "../../FormComponents/Input";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import validateRegister from "../../Register/validateRegister";
import useAdminAddUser from "../../Hooks/useAdminAddUser";
import {NUVOBLUE, NUVOGREEN, NUVOTEXTBLUE} from "../../../constants/styles";
import useMobileView from "../../Hooks/useMobileView";

function AddUser({setIsOpen}) {
    const { isMobile } = useMobileView();
    const {handleChange, handleCheckbox, handleSubmit, values, errors} = useAdminAddUser(validateRegister, setIsOpen);
    return <Modal onClose={() => setIsOpen(false)} width={isMobile ? '90%' : '30%'}>
        <h2 style={{textAlign: 'center'}}>Add a new user</h2>
        <Container>
            <FormLabel htmlFor="Name" name="Name*"/>
            <Input
                fullWidth
                name="name"
                type="text"
                placeholder="Johnny English"
                value={values.name}
                onChange={handleChange}
            />
            <ErrorMessage message={errors.name}/>
            <FormLabel htmlFor="email" name="Email*"/>
            <Input
                fullWidth
                name="email"
                type="email"
                placeholder="name@domain.com"
                value={values.email}
                onChange={handleChange}
            />
            <ErrorMessage message={errors.email}/>
            <CheckBoxRow>
                <CheckInput name="isChecked" type="checkbox" value={values.isChecked} onChange={handleCheckbox}/>
                <Label>Set Password</Label>
            </CheckBoxRow>
            {!values.isChecked &&
                <span style={{fontSize: 11}}>If you don't set a password, the user will receive an email with instructions to set a password</span>}
            {values.isChecked && <><FormLabel htmlFor="password" name="Password*"/>
                <Input
                    fullWidth
                    name="password"
                    type="password"
                    placeholder="password"
                    value={values.password}
                    onChange={handleChange}
                />
                <ErrorMessage message={errors.password}/>
                <FormLabel htmlFor="password2" name="Confirm Password*"/>
                <Input
                    fullWidth
                    name="password2"
                    type="password"
                    placeholder="password"
                    value={values.password2}
                    onChange={handleChange}
                />
                <ErrorMessage message={errors.password2}/></>
            }
            {errors.error && <ErrorMessage message={errors.error}/>}
            <ButtonWrapper>
                <CancelButton onClick={() => setIsOpen(false)}>Cancel</CancelButton>
                <Button onClick={handleSubmit}
                        disabled={(values.isChecked && Object.values(values).some(value => value === '')) ||
                            (!values.isChecked && (!values.name || !values.email))}>
                    Add
                </Button>
            </ButtonWrapper>
        </Container>
    </Modal>
        ;
}

export default AddUser;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Button = styled(SubmitButton)`
  width: 40%;
`;

const CancelButton = styled(Button)`
  background-color: #da3e3e;
`;

const CheckBoxRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center
`;

const CheckInput = styled.input`
  color: ${NUVOTEXTBLUE};
  width: 18px;
  height: 18px;
  outline: none;
  border: 1px solid ${NUVOBLUE};
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 10px;

  &::placeholder {
    color: rgb(200, 200, 200, 1);
  }

  &:focus {
    outline: none;
    border-color: ${NUVOGREEN};
    box-shadow: 0 0 2.5px ${NUVOGREEN};
    transition: all 300ms ease-in;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  color: ${NUVOTEXTBLUE};
`;