import React, {useState} from 'react';
import Header1Blue from "../../TextComponents/Header1";
import FormLabel from "../../FormComponents/FormLabel";
import Input from "../../FormComponents/Input";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import styled from "styled-components";
import {NUVOBLUE, NUVOGREEN, NUVORED, NUVOTEXTBLUE} from "../../../constants/styles";
import useMobileView from "../../Hooks/useMobileView";
import useShareSites from "../../Hooks/useShareSite";
import Modal from "../Modal";

function ShareModal({deviceId, deviceName, setIsOpen}) {
    const [isChecked, setIsChecked] = useState(false);
    const {isMobile} = useMobileView();
    const {handleChange, handleSubmit, values, error} = useShareSites(deviceId, setIsOpen);
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked)
    }

    return (
        <Modal onClose={() => setIsOpen(false)} width={isMobile ? '90%' : '30%'}>
            <Header1Blue>Share Site</Header1Blue>
            <FormLabel htmlFor="email" name="User Email*"/>
            <Input2
                fullWidth
                name="email"
                type="email"
                placeholder="email@domain.com"
                value={values.email}
                onChange={handleChange}
            />
            <FormLabel htmlFor="email2" name="Confirm Email*"/>
            <Input2
                fullWidth
                name="email2"
                type="email"
                placeholder="email@domain.com"
                value={values.email2}
                onChange={handleChange}
            />
            {error && <ErrorMessage message={error}/>}
            <CheckBoxWrapper>
                <CheckInput
                    fullWidth
                    name="checkbox"
                    type="checkbox"
                    value={values.checkbox}
                    onChange={handleCheckboxChange}
                />
                <CheckBoxText>Please confirm you want to share the
                    site/device <HighlightText>({deviceName})</HighlightText> and give access to
                    potentially sensitive site information to another user?
                </CheckBoxText>
            </CheckBoxWrapper>
            <ButtonWrapper>
                <SubmitButton onClick={handleSubmit}
                              disabled={!isChecked || Object.values(values).some(value => value === '') ||
                                  values.email !== values.email2}>
                    Share Site
                </SubmitButton>
            </ButtonWrapper>
        </Modal>
    );
}

export default ShareModal;

const CheckBoxWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CheckBoxText = styled.div`
  color: ${NUVOBLUE};
  font-weight: 600;
`;

const HighlightText = styled.div`
  color: ${NUVORED};
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  width: 100%
`;

const CheckInput = styled.input`
  color: ${NUVOTEXTBLUE};
  width: 30px;
  height: 30px;
  outline: none;
  border: 1px solid ${NUVOBLUE};
  border-radius: 5px;
  padding: 0 10px;

  &::placeholder {
    color: rgb(200, 200, 200, 1);
  }

  &:focus {
    outline: none;
    border-color: ${NUVOGREEN};
    box-shadow: 0 0 2.5px ${NUVOGREEN};
    transition: all 300ms ease-in;
  }
`;

const Input2 = styled(Input)`
  margin-top: 10px;
  margin-bottom: 10px;
`