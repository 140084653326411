import styled from "styled-components";
import {NUVOSEMIFONT, NUVOTEXTBLUE} from "../../constants/styles";
import {useEffect} from "react";

const SolarIcon = ({data, width, height, handleSolar }) => {
    const activePower = data?.getEnergyFlowData.inv_active_power;

    useEffect(() => {
        handleSolar(activePower);
    }, [activePower]);

    return (
        <IconWrapper>
            <div>Solar</div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 117.711 94.534">
                <g transform="translate(0 0)">
                    <g transform="translate(0 0)">
                        <path d="M-116.892,106.147a2.069,2.069,0,0,1-2.07-2.07V80.66h-48.877a7.873,7.873,0,0,1-6.613-3.575,7.871,7.871,0,0,1-.633-7.488l23.992-55.04a4.906,4.906,0,0,1,4.5-2.944h29.7a2.069,2.069,0,0,1,2.07,2.07,2.07,2.07,0,0,1-2.07,2.07h-29.7a.767.767,0,0,0-.7.459l-23.994,55.042a3.7,3.7,0,0,0,.3,3.565,3.707,3.707,0,0,0,3.151,1.7h50.947a2.07,2.07,0,0,1,2.07,2.07v25.488A2.071,2.071,0,0,1-116.892,106.147Z" transform="translate(175.747 -11.613)" fill="#7bce00"/>
                    </g>
                    <g transform="translate(56.786 0)">
                        <path d="M-88.435,80.66h-50.947a2.068,2.068,0,0,1-2.07-2.07,2.069,2.069,0,0,1,2.07-2.07h50.947a3.707,3.707,0,0,0,3.151-1.7,3.7,3.7,0,0,0,.3-3.565l-23.994-55.042a.767.767,0,0,0-.7-.459h-29.7a2.069,2.069,0,0,1-2.07-2.07,2.068,2.068,0,0,1,2.07-2.07h29.7a4.907,4.907,0,0,1,4.5,2.942L-81.188,69.6a7.883,7.883,0,0,1-.634,7.489A7.878,7.878,0,0,1-88.435,80.66Z" transform="translate(141.452 -11.613)" fill="#7bce00"/>
                    </g>
                    <g transform="translate(37.948 90.395)">
                        <path d="M-113.085,70.345h-37.674a2.071,2.071,0,0,1-2.07-2.07,2.071,2.071,0,0,1,2.07-2.07h37.674a2.069,2.069,0,0,1,2.07,2.07A2.069,2.069,0,0,1-113.085,70.345Z" transform="translate(152.829 -66.206)" fill="#7bce00"/>
                    </g>
                </g>
                <g transform="translate(25.634 17.975)">
                    <path d="M-95.894,26.608h-62.3a2.069,2.069,0,0,1-2.07-2.07,2.071,2.071,0,0,1,2.07-2.07h62.3a2.069,2.069,0,0,1,2.07,2.07A2.068,2.068,0,0,1-95.894,26.608Z" transform="translate(160.266 -22.469)" fill="#7bce00"/>
                </g>
                <g transform="translate(15.553 41.244)">
                    <path d="M-81.821,40.661h-82.464a2.071,2.071,0,0,1-2.07-2.07,2.071,2.071,0,0,1,2.07-2.07h82.464a2.069,2.069,0,0,1,2.07,2.07A2.069,2.069,0,0,1-81.821,40.661Z" transform="translate(166.354 -36.522)" fill="#7bce00"/>
                </g>
                <g transform="translate(36.435 5.889)">
                    <g transform="translate(32.179 0)">
                        <path d="M-123.72,69.485a2.072,2.072,0,0,1-2.038-1.724l-8.521-50.177a2.07,2.07,0,0,1,1.694-2.386,2.068,2.068,0,0,1,2.388,1.692l8.521,50.179a2.069,2.069,0,0,1-1.694,2.386A2.089,2.089,0,0,1-123.72,69.485Z" transform="translate(134.309 -15.17)" fill="#7bce00"/>
                    </g>
                    <g transform="translate(0 0)">
                        <path d="M-151.67,69.485a2.1,2.1,0,0,1-.349-.03,2.07,2.07,0,0,1-1.694-2.386l8.521-50.179A2.074,2.074,0,0,1-142.8,15.2a2.069,2.069,0,0,1,1.694,2.386l-8.521,50.177A2.069,2.069,0,0,1-151.67,69.485Z" transform="translate(153.743 -15.17)" fill="#7bce00"/>
                    </g>
                </g>
            </svg>
            <div>{activePower ? activePower.toLocaleString() : '-'}</div>
            <div>kW</div>
        </IconWrapper>
    )
}

export default SolarIcon;

const IconWrapper = styled.div`
  font-family: ${NUVOSEMIFONT};
  color: ${NUVOTEXTBLUE};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
