import {useContext, useState} from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import UserContext from "../../../context/UserContext";
import { useNavigate } from 'react-router-dom';
import { GET_SITES } from '../../Hooks/useGetSites';
import { GET_SHARED_USER_SITES } from '../../Hooks/useSharedUserSites';

const MAC_ID_CHECKS  = gql`
    query MacIdChecks($deviceId: String!) {
        macIdChecks (deviceId: $deviceId) {
            mac_idExists
            mac_idAllocated
        }
    }
`;

// const ADD_DEVICE = gql`
//     mutation addDevice( $deviceId: String!, $name: String!, $location: String!, $latitude: String!, $longitude: String!, $username: String!, $inverterCount: Number!, $inverterType: String!, $meterCount: Number!,$meterLable: String!) {
//         addDevice (deviceId: $deviceId, name: $name, location: $location, latitude: $latitude, longitude: $longitude, username: $username, inverterCount: $inverterCount, inverterType: $inverterType, meterCount: $meterCount, meterLabel: $meterLabel) {
//             id
//             message
//         }
//     // }
// `;
const ADD_DEVICE = gql`
mutation AddDevice (
    $deviceId: String!, 
    $name: String!, 
    $username: String!,
    $location: String!, 
    $latitude: String!, 
    $longitude: String!,
    $inverterCount: String,
    $gensetConfig: String,
    $inverterType: String
    $meterCount: String,
    $meter1Label: String,
    ) {
    addDevice (
    deviceId: $deviceId,
    name: $name,
    username: $username,
    location: $location,
    latitude: $latitude,
    longitude: $longitude,
    inverterCount: $inverterCount,
    gensetConfig: $gensetConfig,
    inverterType: $inverterType,
    meterCount: $meterCount,
    meter1Label: $meter1Label
    ) {
    id
    message
  }
  }
`;

const useAddDeviceForm = (validateAddDevice, setView) => {
    const [values, setValues] = useState( {
        email:'',
        deviceName: '',
        deviceId: '',
        location: '',
        latitude: 0, // set to null because need it to be a number/ int for the SiteMap component
        longitude: 0, // set to null because need it to be a number/ int for the SiteMap component
        inverterCount: '',
        gensetConfig: '',
        inverterType: '',
        meterCount: '',
        meter1Label: ''
    })

    const { user } = useContext(UserContext);
    const [errors, setErrors] = useState({});
    const [AddDevice] = useMutation(ADD_DEVICE);

    const client = useApolloClient();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit =  (e) => {
        e.preventDefault();

        client.query({
            query: MAC_ID_CHECKS,
            variables: {
                deviceId: values.deviceId
            },
            fetchPolicy: 'network-only',
        })
            .then((result) => {
                if (Object.keys(validateAddDevice(values, result.data.macIdChecks)).length > 0) {
                    setErrors(validateAddDevice(values, result.data.macIdChecks));
                } else {
                    AddDevice({ variables: {
                            name: values.deviceName,
                            deviceId: values.deviceId,
                            location: values.location,
                            latitude: values.latitude.toString(),
                            longitude: values.longitude.toString(),
                            username: user.username,
                            inverterCount: values.inverterCount,
                            gensetConfig: values.gensetConfig,
                            inverterType: values.inverterType,
                            meterCount: values.meterCount,
                            meter1Label: values.meter1Label
                        }})
                        .then((result) => {
                            if (result) {
                                navigate('/dashboard');
                            }
                        }).catch(error => console.log("ERROR ADD DEVICE",error.message))
                }

            })
            .catch(errors => console.log("ERROR ADD",errors));


        // MacIdChecks({ variables: { deviceId: values.deviceId}})

    }
    return { handleChange, handleSubmit, values, errors };
}

export default useAddDeviceForm;
