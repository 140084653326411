import {gql, useQuery} from "@apollo/client";
import {useState} from "react";

const GET_SITE_SOLAR_PRODUCTION = gql`
    query GetSiteSolarProduction ($deviceId: String!) {
        getSiteSolarProduction (deviceId: $deviceId) {
            today_solar_production
            latest_kwh_total_active
            online_check
            last_update_day
            day
        }
    }
`;

const useDailyEnergy = (deviceId) => {
    const [todaySolarProduction, setTodaySolarProduction] = useState(0);
    const [totalSolarProduction, setTotalSolarProduction] = useState(0);

    const {loading} = useQuery(GET_SITE_SOLAR_PRODUCTION, {
        variables: {deviceId},
        nextFetchPolicy: 'network-only',
        onCompleted: ({getSiteSolarProduction}) => {
            const sumProduction = getSiteSolarProduction.reduce((acc, item) => {
                return {
                    todayProduction: acc.todayProduction + item.today_solar_production,
                    totalProduction: acc.totalProduction + item.latest_kwh_total_active
                }
            }, {todayProduction: 0, totalProduction: 0});

            setTodaySolarProduction(Math.round(sumProduction.todayProduction));
            setTotalSolarProduction(Math.round(sumProduction.totalProduction));
        },
        onError: (error) => {
            console.log("Lifetime Solar Production:", error);
        }
    })

    return {todaySolarProduction, totalSolarProduction, loading};
}

export default useDailyEnergy;
