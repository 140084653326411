import { useContext, useMemo } from "react";
import UserContext from "../../context/UserContext";
import useGetSites from "./useGetSites";
import useSharedUserSites from "./useSharedUserSites";
import { isLiveHandler } from "../DataGrid/handlers";

const useFetchSiteDataForUser = () => {
    const { user } = useContext(UserContext);
    const siteData = useGetSites(user);
    const sharedUserSiteData = useSharedUserSites(user);

    const userData = useMemo(() => {
        if (siteData.error || sharedUserSiteData.error) {
            return [];
        }
        if (siteData.data && sharedUserSiteData.data) {
            const combinedSites = [...siteData?.data.getSites, ...sharedUserSiteData?.data.getSharedSites];
            const uniqueSites = combinedSites.reduce((unique, item) => {
                return unique.findIndex(uniqueItem => uniqueItem.mac_id === item.mac_id) !== -1 ? unique : [...unique, item];
            }, []);

            return uniqueSites.map(item => {
                const totalEnergy = item.total_kwh_active ? item.total_kwh_active.toFixed(0) : null;

                return {
                    status: isLiveHandler(item.seconds_past_now, item.online_check),
                    deviceName: item.name,
                    deviceId: item.mac_id,
                    location: item.location,
                    inverterCount: item.inverter_count,
                    currentPower: Math.round(item.total_ac_active_power / 1000),
                    gridPower: Math.round(item.grid_power / 1000),
                    totalEnergy: totalEnergy ? new Intl.NumberFormat().format(totalEnergy) : null,
                    actions: item.mac_id,
                    date_time: item.date_time,
                    seconds_past_now: item.seconds_past_now,
                    online_check: item.online_check,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    last_update: item.last_update ? item.last_update.slice(0, -6) : null,
                    sharedSite: sharedUserSiteData.data.getSharedSites.includes(item),
                };
            });
        } else return []
    }, [siteData, sharedUserSiteData]);

    const loading = useMemo(() => (siteData.loading && sharedUserSiteData.loading), [siteData.loading, sharedUserSiteData.loading]);
    const error = useMemo(() => (siteData.error && sharedUserSiteData.error), [siteData.error, sharedUserSiteData.error]);

    return { data: userData, loading, error };
};

export default useFetchSiteDataForUser;
