
import styled from "styled-components";
import { NUVOTEXTBLUE } from "../../constants/styles";
import { NUVOSEMIFONT } from "../../constants/styles";

const SmallTextBlue = styled.p`
  font-family: ${NUVOSEMIFONT};
  color: ${NUVOTEXTBLUE};
  font-size: 12px;
`;

export default SmallTextBlue;

