import {useState} from "react";
import {gql, useMutation} from "@apollo/client";

const useRegForm = (validateRegister, handleRegistrationState, handleLoginActive) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        password2: ''
    });

    const [errors, setErrors] = useState({});
    const [RegisterUser] = useMutation(REGISTER_USER, {
        onCompleted() {
            handleRegistrationState('success');
            handleLoginActive(true);
        },
        onError(error) {
            setErrors({error: error.message});
        },
        variables: values
    });


    const handleChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        });
    };


    const handleSubmit = e => {
        e.preventDefault();
        if (Object.keys(validateRegister(values)).length > 0) {
            setErrors(validateRegister(values))
        } else {
            RegisterUser()
                .catch(error => console.log(error));
        }
    }
    return {handleChange, handleSubmit, values, errors};

};

export default useRegForm;

const REGISTER_USER = gql`
    mutation RegisterUser($name: String!, $email: String!, $password: String! ) {
        registerUser (name: $name, username: $email , password: $password) {
            success
            message
            error
        }
    }
`;