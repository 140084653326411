import { gql, useQuery } from "@apollo/client";

const GET_GRID_VOTAGE_BY_DATE = gql`
    query GetGridVoltageByDeviceIdByDate ($deviceId: String!, $date: String!) {
        getGridVoltageByDeviceIdByDate (deviceId: $deviceId, date: $date) {
            x
            y_voltage_an
            y_voltage_bn
            y_voltage_cn
        }
    }
`;

const useGetGridVoltageData = (deviceId, date) => {
    const { loading, error, data }  = useQuery(GET_GRID_VOTAGE_BY_DATE, {
        variables: { deviceId: deviceId, date: date },
        onError: (error) => {
            console.log("useGetGridVoltageData error: ", error);
        }
    });
    return { loading, error, data };
}

export default useGetGridVoltageData;
