import {gql} from "@apollo/client";

export const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
    }
`;

export const GET_USER = gql`
    query Me {
        me {
            id
            name
            username
            admin
            parent
            roles {
                id
                name
            }
        }
    }
`;
