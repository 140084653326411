
import { useState, createContext, useEffect } from 'react';

export const DateContext = createContext();

const DateContextProvider = (props) => {
    const [date, setDate] = useState(new Date());
    const [monthDate, setMonthDate] = useState(new Date());
    const [YTDDate, setYTDDate] = useState(new Date());


    const addDate = (duration, value) => {
        if (duration === 'day') setDate(value);
        if (duration === 'month'){
            setMonthDate(value);
        };
        if (duration === 'YTD') setYTDDate(value);
    }

    useEffect(() => {
    }, [monthDate])
    return (
        <DateContext.Provider value={{ date, monthDate, YTDDate, addDate }} >
            {props.children}
        </DateContext.Provider>
    );
}

export default DateContextProvider;