
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import styled from "styled-components";
import { NUVOBLUE, NUVOWHITE } from "../../../constants/styles";
import format from "date-fns/format";

const DayRangePicker = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <DayPickerWrapper>
            <PickerButton onClick={handleClick} >{format(startDate, "dd/MM/yyyy")}</PickerButton>
    {isOpen && (<DatePicker
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
        />
    )}
        </DayPickerWrapper>
    );
}

export default DayRangePicker;

const DayPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const PickerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  background-color: ${NUVOBLUE};
  color: ${NUVOWHITE};
  align-content: center;
  border: none;
  border-radius: 3px;
  letter-spacing: 1.5px;
  //margin-right: 0.5rem;
  cursor: pointer;
`;
