import Dashboard from "../Components/Dashboard/Dashboard";
import AddDevice from "../Components/Dashboard/AddDevice/AddDevice";
import EditDevice from "../Components/Dashboard/EditDevice/EditDevice";
import ShareDevice from "../Components/Dashboard/ShareDevice/ShareDevice";
import Profile from "../Components/Dashboard/Profile/Profile";
import Reports from "../Components/Dashboard/Reporting/Reports";
import Detail from "../Components/Dashboard/Detail";
import SitesView from "../Components/Management/Sites/SitesView";
import PasswordReset from "../Components/PasswordReset/PasswordReset";

// ==============================|| MAIN ROUTING ||============================== //

const AuthRoutes = [
    {
        path: "dashboard",
        children: [
            {index: true, element: <Dashboard/>},
            {path: "addDevice", element: <AddDevice/>},
            {path: "editDevice", element: <EditDevice/>},
            {path: "shareDevice", element: <ShareDevice/>},
            {path: "profile", element: <Profile/>},
            {path: "reports", element: <Reports/>},
            {path: "site", element: <Detail/>},
            {path: "*", element: <Dashboard/>},
        ],
    },
    {
        path: "management",
        children: [
            {path: "sites-list", element: <SitesView/>},
        ],
    },
    {path: "reset-password/:token", element: <PasswordReset/>},
    {path: "/", element: <Dashboard/>},
]

export default AuthRoutes;
