
import { useContext, useEffect, useState } from "react";
import UserContext  from "../../../context/UserContext";
import {gql, useQuery, useApolloClient, useMutation} from "@apollo/client";

const UPDATE_USER_PROFILE = gql`
    mutation UpdateUserProfile ($username: String!, $name: String!, $id:  String!) {
        updateUserProfile (id: $id, username: $username, name: $name) {
            message
            username
            success
        }
    }
`;

const GET_USER_PASSWORD = gql`
    query GetUserProfile($username: String!) {
        getUserProfile (username: $username) {
            password
        }
    }
`;

const RESET_USER_PASSWORD = gql`
    mutation ResetUserProfile($id: ID!, $newPassword: String!) {
        resetUserPassword (id: $id, newPassword: $newPassword) {
            id
            success
            message
        }
    }
`;

const usePassword = (validatePassword, handleUpdateSuccess) => {
    const { user } = useContext(UserContext);
    console.log(user.id);
    const [values, setValues] = useState({
        current: '',
        new: '',
        confirmed: ''
    });

    const [passwordCheck, setPasswordCheck] = useState(null);
    const { loading, data, error } = useQuery(GET_USER_PASSWORD, {
        variables: { username: user.username }
    })

    const [ResetUserPassword] = useMutation(RESET_USER_PASSWORD, {
        update(cache, {data: {resetUserPassword: result }})  {
            console.log("Password Reset worked", result);
            handleUpdateSuccess('success');

        },
        onError(error) {
            console.log(error)
            handleUpdateSuccess('fail');
        },
        variables: {
            id: user.id,
            newPassword: values.new
        }
    })

    useEffect(() => {
        if (data) {
            console.log(data);
            setPasswordCheck(data.getUserProfile.password)
        }
    },[data])


    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const client = useApolloClient();
    const handleSubmit = async (e) => {

        e.preventDefault();
        console.log(values);
        console.log("Password reset")
        const { name } = e.target
        if (Object.keys(await validatePassword(values, user, passwordCheck)).length > 0) {
            setErrors(await validatePassword(values, user, passwordCheck));
        } else {
            console.log("No Errors");
            console.log(values);
            setErrors({});
            await ResetUserPassword();
            await client.refetchQueries({
                include: [GET_USER_PASSWORD],
            });
        }
        // .catch(errors => console.log(errors));
    }


    return { handleChange, handleSubmit, values, errors };
}

export default usePassword;
