import React from 'react';

const withData = (DataCard, useDataHook) => {
    return (props) => {
        const dataProps = useDataHook();
        return <DataCard {...props} {...dataProps} />;
    }
}

export default withData;
