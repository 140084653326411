import React, {useState} from 'react';
import styled from "styled-components";
import {gql, useQuery} from "@apollo/client";
import Spinner from "../../Spinner/Spinner";
import {useAssignUserRoles} from "../../Hooks/useAssignRoles";
import SubmitButton from "../../ButtonComponents/SubmitButton";

function PopoverContent({userId, userRoles, handleToggle}) {
    const {loading, error, data} = useQuery(GET_ALL_ROLES);
    const {assignAndUnAssignUserRoles} = useAssignUserRoles(handleToggle);
    const [selected, setSelected] = useState(userRoles[0]?.id);
    if (loading) return <Content><Spinner/></Content>

    const handleSelected = (roleId) => {
        setSelected(roleId)
    }

    const handleSubmit = () => {
        assignAndUnAssignUserRoles(userId, selected, userRoles.filter(r => r.id !== selected)[0]?.id)
    }

    return (
        <Content>
            <h3>Assign roles</h3>
            <>
                {data.getAllRoles.map(role => (
                    <Button key={role.id} selected={selected === role.id}
                            onClick={() => handleSelected(role.id)}>
                        {selected === role.id && '✔️'} {role.name}
                    </Button>
                ))}
            </>
            <Actions>
                <CloseButton onClick={handleToggle}>Cancel</CloseButton>
                <SaveButton onClick={handleSubmit}
                            disabled={!selected || userRoles[0]?.id === selected}>
                    Confirm
                </SaveButton>
            </Actions>
        </Content>
    );
}

export default PopoverContent;

const Content = styled.div`
  position: absolute;
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 250px;
`;

const Actions = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
`;

const Button = styled.span`
  cursor: pointer;
  margin-right: 16px;
  background-color: ${({selected}) => (selected ? "#34a06b" : 'rgba(145, 204, 50, 0.11)')};
  color: ${({selected}) => (selected ? "white" : 'black')};
  padding: 5px;
  font-size: 11px;
  font-style: italic;
  border-radius: 5px;
`;

const SaveButton = styled(SubmitButton)`
  width: auto;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 6px;
`;

const CloseButton = styled(SubmitButton)`
  width: auto;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #da3e3e;
  padding: 6px;
`;

export const GET_ALL_ROLES = gql`
    query GetAllRoles {
        getAllRoles {
            id
            name
        }
    }
`;
