import {useMutation, gql} from '@apollo/client';
import {GET_SITES} from "../Management/Users/ShareSites";

export function useAssignUserSites(setIsOpen) {
    const [modifyUserSites, {data, loading, error}] = useMutation(MODIFY_USER_SITES_MUTATION, {
        refetchQueries: [
            GET_SITES,
            'getSharedSites'
        ],
        onCompleted() {
            setIsOpen && setIsOpen(false)
        },
        onError(error) {
            console.log(error)
        }
    });

    const executeAssignAndUnAssignUserSites = async (userId, addSites, removeSites) => {
        try {
            return {
                assignUserSites: await modifyUserSites({
                    variables: {
                        user_id: userId,
                        addSites,
                        removeSites,
                        skipUnAssign: removeSites.length === 0,
                        skipAssign: addSites.length === 0
                    }
                })
            };
        } catch (err) {
            console.error('Error executing AssignUserSites and UnAssignUserSites mutations:', err.message);
            console.log(err.graphQLErrors); // log the error response
            return null;
        }
    };

    return {executeAssignAndUnAssignUserSites, data, loading, error};
}

const MODIFY_USER_SITES_MUTATION = gql`
    mutation modifyUserSites($user_id: String!, $addSites: [Int]!, $removeSites: [Int]!, $skipUnAssign: Boolean!, $skipAssign: Boolean!) {
        assignUserSites(user_id: $user_id, sites: $addSites) @skip(if: $skipAssign) {
            success
            message
            error
        }
        unassignUserSites(user_id: $user_id, sites: $removeSites) @skip(if: $skipUnAssign){
            success
            message
            error
        }
    }
`;
