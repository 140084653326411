import React, {useState, useEffect, memo} from 'react';
import {gql, useQuery} from "@apollo/client";
import styled from 'styled-components';
import Modal from "../Modal";
import Input from "../../FormComponents/Input";
import {NUVOTEXTBLUE} from "../../../constants/styles";
import useMobileView from "../../Hooks/useMobileView";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import AssignSites from "./AssignSites";

const ShareSites = memo(function ShareSites({setIsOpen, userId}) {
    const {loading, error, data} = useQuery(GET_SITES, {variables: {user_id: userId}});
    const processedData = loading ? [] : data.getAllSites;
    const userSites = loading ? [] : data.getSharedSites;
    const {isMobile} = useMobileView();
    const [leftSites, setLeftSites] = useState([])
    const [rightSites, setRightSites] = useState([]);
    const [filter, setFilter] = useState('');
    const [selectedSites, setSelectedSites] = useState([]);
    const [draggedSites, setDraggedSites] = useState([]);

    useEffect(() => {
        setLeftSites(processedData.filter(site1 => !userSites.some(site2 => site2.id === site1.id)))
    }, [processedData]);

    useEffect(() => {
        setRightSites(userSites)
    }, [userSites]);

    const resetStates = () => {
        setIsOpen(false)
    };

    const toggleSelectedSite = (site) => {
        if (selectedSites.some((selectedSite) => selectedSite.id === site.id)) {
            setSelectedSites((prevState) => prevState.filter((selectedSite) => selectedSite.id !== site.id));
        } else {
            setSelectedSites((prevState) => [...prevState, site]);
        }
    };

    const onDragStart = (event, site, sourceColumn) => {
        if (selectedSites.some((selectedSite) => selectedSite.id === site.id)) {
            setDraggedSites(selectedSites);
        } else {
            setDraggedSites([site]);
        }
        event.dataTransfer.setData("sourceColumn", sourceColumn);
    };

    const onDrop = (event, column) => {
        const sourceColumn = event.dataTransfer.getData("sourceColumn");
        if (column === sourceColumn) return; // Prevent drop in the same column

        if (column === "left") {
            setRightSites((rightSites) => rightSites.filter((s) => !draggedSites.some((draggedSite) => draggedSite.id === s.id)));
            setLeftSites((leftSites) => [...draggedSites, ...leftSites]);
        } else {
            setLeftSites((leftSites) => leftSites.filter((s) => !draggedSites.some((draggedSite) => draggedSite.id === s.id)));
            setRightSites((rightSites) => [...draggedSites, ...rightSites]);
        }

        // Clear selected and dragged sites
        setSelectedSites([]);
        setDraggedSites([]);
    };

    const allToRight = () => {
        setLeftSites([]);
        setRightSites((rightSites) => [...leftSites, ...rightSites]);
    };

    const allToLeft = () => {
        setRightSites([]);
        setLeftSites((leftSites) => [...rightSites, ...leftSites]);
    };

    const filteredLeftSites = leftSites.filter(site => site.name?.toLowerCase().includes(filter.toLowerCase()) || site.mac_id.toLowerCase().includes(filter.toLowerCase()));
    const filteredRightSites = rightSites.filter(site => site.name?.toLowerCase().includes(filter.toLowerCase()) || site.mac_id.toLowerCase().includes(filter.toLowerCase()));
    if (loading) return 'Loading...';

    return (
        <Modal onClose={resetStates} width={'95%'}>
            <h2>Share sites</h2>
            <InputWrapper isMobile={isMobile} fullWidth={true}>
                <Input placeholder="Search by name or mac" type="text" value={filter}
                       onChange={event => setFilter(event.target.value)} isMobile={isMobile} fullWidth={true}/>
                {filter && (
                    <ClearButton onClick={() => setFilter('')}>
                        &times;
                    </ClearButton>
                )}
            </InputWrapper>
            <ColumnTitles>
                <ColumnTitle>All Sites</ColumnTitle>
                <ColumnTitle>User Sites</ColumnTitle>
            </ColumnTitles>
            <span style={{fontSize: 9}}>You can drag and drop (even multiple)</span>
            <Container>
                <Column data-column="left" onDrop={event => onDrop(event, 'left')}
                        onDragOver={event => event.preventDefault()}>
                    {filteredLeftSites.map(site => (
                        <Site
                            key={site.id}
                            onDragStart={event => onDragStart(event, site, 'left')}
                            onClick={() => toggleSelectedSite(site)}
                            isSelected={selectedSites.some(selectedSite => selectedSite.id === site.id)}
                            draggable
                        >
                            <SiteText>{site.name} ({site.mac_id})</SiteText>
                        </Site>
                    ))}
                </Column>
                <ButtonContainer>
                    <StyledButton onClick={allToRight} disabled={leftSites.length === 0}
                                  title="Share all">{'>>'}</StyledButton>
                    <StyledButton onClick={allToLeft} disabled={rightSites.length === 0}
                                  title="Unshare all">{'<<'}</StyledButton>
                </ButtonContainer>
                <Column data-column="right" onDrop={event => onDrop(event, 'right')}
                        onDragOver={event => event.preventDefault()}>
                    {filteredRightSites.map(site => (
                        <Site
                            key={site.id}
                            onDragStart={event => onDragStart(event, site, 'right')}
                            draggable
                        >
                            <SiteText>{site.name} ({site.mac_id})</SiteText>
                        </Site>
                    ))}
                </Column>
            </Container>
            <div style={{justifyContent: 'space-evenly', display: 'flex'}}>
                <CloseButton onClick={resetStates}>Close</CloseButton>
                <AssignSites userId={userId} setIsOpen={setIsOpen}
                             addSites={rightSites.filter((site1) => !userSites.some((site2) => site2.id === site1.id)).map((site) => site.id)}
                             removeSites={userSites.filter((site1) => !rightSites.some((site2) => site2.id === site1.id)).map((site) => site.id)}
                />
            </div>
        </Modal>
    );
});

export default ShareSites;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  height: 300px;
  overflow-y: scroll;
  background-color: #f6f8fa;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #979797;
  }
`;


const ColumnTitles = styled.div`
  justify-content: space-around;
  display: flex;
  margin-top: 16px
`;

const ColumnTitle = styled.div`
  font-size: 16px;
`;

const Site = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: ${({isSelected}) => (isSelected ? '2px solid #181879' : 'none')};
`;

const SiteText = styled.span`
  color: #181879;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledButton = styled.button`
  font-size: 12px;
  font-weight: 600;
  color: ${({disabled}) => (!disabled ? '#181879' : '#8b8b8b')};
  background-color: #ffffff;
  border: ${({disabled}) => (!disabled ? '1px solid #181879' : '1px solid #8b8b8b')};
  border-radius: 4px;
  padding: 6px 12px;
  cursor: ${({disabled}) => (!disabled ? 'pointer' : 'default')};;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f6f8fa;
  }
`;

const CloseButton = styled(SubmitButton)`
  width: 100px;
  margin-top: 20px;
  background-color: #da3e3e;
`;

const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.isMobile || props.fullWidth ? '100%' : '80%'};
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: ${NUVOTEXTBLUE};
`;

export const GET_SITES = gql`
    query GetAllSites ($user_id: String!) {
        getAllSites {
            id
            mac_id
            name
        }
        getSharedSites(user_id: $user_id) {
            id
            mac_id
            name
            username
        }
    }
`;
