export const NUVOBLUE = '#154677';
export const NUVOHEADERBLUE = '#2F659E';
export const NUVOTEXTBLUE = '#2F659E';
export const NUVOWHITE = '#FFFFFF';
export const NUVOGREEN = '#91CC32';
export const NUVOGREY = '#848484';
export const NUVODARKGREY = '#696969';
export const NUVOBGGREY = '#EFEEEE';
export const NUVORED = '#D9381E'
export const BOXSHADOWBLUE = 'lightsteelblue';
export const NUVOBOLDFONT = "'amikoBold',Helvetica, Roboto, sans-serif";
export const NUVOREGULARFONT = "'amikoRegular',Helvetica, Roboto, sans-serif";
export const NUVOSEMIFONT = "'amikoSemi',Helvetica, Roboto, sans-serif"
export const GRIDCOLOR = '#FBEC5D'
