
import React from "react";
import FormContainer from "../FormComponents/FormContainer";
import Input from "../FormComponents/Input";
import SubmitButton from "../ButtonComponents/SubmitButton";
import FormLabel from "../FormComponents/FormLabel";
import styled from "styled-components";
import Header1Blue from "../TextComponents/Header1";
import BigTextBlue from "../TextComponents/BigTextBlue";
import SmallTextBlue from "../TextComponents/SmallTextBlue";
import UnderScoreButton from "../ButtonComponents/UnderScoreButton";
import logo from "../../assets/images/logo.png";
import useLoginForm from  "./useLoginForm";
import validateLogin from "./validateLogin";
import ErrorMessage from "../FormComponents/ErrorMessage";
import {NUVOGREEN, NUVORED, NUVOWHITE, NUVOBOLDFONT} from "../../constants/styles";

const  Login =  (props) => {
    const {isMobile, setLoginActive }  = props;
    const {handleChange, handleSubmit, values, errors} = useLoginForm(validateLogin, props);
    const handleRegistrationStateRender = (regState) => {
        switch(regState) {
            case "success":
                return <RegSuccess>You have successfully signed-up, please login</RegSuccess>;
            case "fail":
                return <RegFail>Oops something went wrong</RegFail>;
            default:
            return null;
        }
    }

    return (
        <React.Fragment>
            { isMobile ? <Logo src={logo} alt={"logo"} /> : null }
            <Header1Blue>Welcome to Nuvo Energy</Header1Blue>
            <BigTextBlue>Login and register your device(s)</BigTextBlue>
            <SignupWrapper>
                <SmallTextBlue>Don't have an account?</SmallTextBlue>
                <UnderScoreButton setLoginActive={setLoginActive} text="Sign up"/>
            </SignupWrapper>
            { handleRegistrationStateRender(props.registrationState)}
            <FormContainer noValidate className='form' onSubmit={handleSubmit}>
                <FormLabel htmlFor="email" name="Email*"/>
                <Input
                    isMobile={isMobile}
                    name="email"
                    type="email"
                    placeholder="name@domain.com"
                    value={values.email}
                    onChange={handleChange}
                />
                <ErrorMessage message={errors.email}/>
                <FormLabel htmlFor="password" name="Password*"/>
                <Input
                    isMobile={isMobile}
                    name="password"
                    type="password"
                    placeholder="password"
                    value={values.password}
                    onChange={handleChange}
                />
                <Link href={'/forgot-password'}>Forgot Password?</Link>
                <ErrorMessage message={errors.password}/>
                <ButtonWrapper isMobile={isMobile}>
                    <SubmitButton>Login</SubmitButton>
                </ButtonWrapper>
            </FormContainer>
        </React.Fragment>
    )
}
export default Login;

export const ButtonWrapper = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`;

const RegSuccess = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  color: ${NUVOWHITE};
  background-color: ${NUVOGREEN};
  border-radius: 3px;
  text-align: center;
`;

const RegFail = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  color: ${NUVOWHITE};
  background-color: ${NUVORED};
  border-radius: 3px;
  text-align: center;
`;

const SignupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Logo = styled.img`
  height: 100px;
  width: 270px;
  align-self: center;
`;

export const Link = styled.a`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  color: ${NUVOGREEN};
  margin-top: 5px;
  margin-left: 5px;
`;
