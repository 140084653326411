import React from "react";
import styled from "styled-components";


const Modal = ({children, onClose, width}) => (
    <ModalWrapper onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()} width={width}>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            {children}
        </ModalContent>
    </ModalWrapper>
);

export default Modal;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  width: ${props => props.width};
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #999;
`;