import {useQuery} from '@apollo/client';
import {GET_USER} from "../graphql/queries";
import UserContext from "./../context/UserContext";
import {AuthRoutes, SuperAdminRoutes} from "../routes";
import Spinner from "./Spinner/Spinner";
import {SUPER_ADMIN_ROLE} from "../helpers/variables";

const AuthenticatedView = () => {
    const {loading, data} = useQuery(GET_USER, {
        fetchPolicy: "network-only"
    });
    if (loading) return <Spinner/>; // Show a loader component while data is being fetched

    return (
        <UserContext.Provider value={{user: data?.me || {}}}>
            {data?.me.roles.some(role => role.name === SUPER_ADMIN_ROLE) && <SuperAdminRoutes/>}
            <AuthRoutes/>
        </UserContext.Provider>
    );
};

export default AuthenticatedView;
