import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

const GET_PRODUCTION_RUNNING_TOTALS = gql`
  query GetProductionRunningTotals($deviceId: String!, $dateTruncType: String!, $date: String!) {
    getSolarRunningTotalByAnyDate(deviceId: $deviceId, dateTruncType: $dateTruncType, date: $date) {
      data {
        day
        month
        Inv1
        Inv2
        Inv3
        Inv4
        Inv5
        Inv6
        Inv7
        Inv8
        Inv9
        Inv10
        kWh
      }
      productionReadings {
        startReading
        endReading
      }
      inverterCount
    }
  }
`;

const useGetProductionRunningTotals = (deviceId, dateTruncType, date) => {
  const { data, error, loading } = useQuery(GET_PRODUCTION_RUNNING_TOTALS, {
    variables: { deviceId, dateTruncType, date },
  });

  const productionRunningTotals = useMemo(() => {
    if (data) {
      const { data: inverterData, productionReadings, inverterCount } = data.getSolarRunningTotalByAnyDate;
      const productionByInverterReadings = productionReadings.map((reading, index) => ({
        inverter: index + 1,
        ...reading,
      }));

      const inverterKeys = Array.from({length: inverterCount}, (_, i) => `Inv${i+1}`);

      return {
        inverterData,
        inverterCount,
        productionByInverterReadings,
        inverterKeys
      };
    }

    return {};
  }, [data]);

  return {
    loading,
    error,
    productionRunningTotals,
  };
};

export default useGetProductionRunningTotals;
