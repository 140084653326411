import {useMutation, gql} from '@apollo/client';
import {GET_SHARED_USERS} from "../Management/Sites/queries";

export function useOwnerUnAssignSiteUsers(setIsOpen) {
    const [unAssignSiteUser, {data, loading, error}] = useMutation(OWNER_UNASSIGNED_SITE_USERS, {
        refetchQueries: [
            GET_SHARED_USERS,
        ],
        onError(error) {
            console.log(error)
        }
    });

    const unAssignSitesUsers = async (users, site_id) => {
        try {
            return {
                ownerUnAssignUserSites: await unAssignSiteUser({
                    variables: {
                        users,
                        site_id,
                    }
                })
            };
        } catch (err) {
            console.error('Error executing AssignUserSites and OwnerUnAssignSiteUsers mutations:', err.message);
            console.log(err.graphQLErrors);
            return null;
        }
    };

    return {unAssignSitesUsers, data, loading, error};
}

const OWNER_UNASSIGNED_SITE_USERS = gql`
    mutation OwnerUnAssignSiteUsers($users: [String]!, $site_id: Int!) {
        ownerUnAssignSiteUsers(users: $users, site_id: $site_id) {
            success
            message
            error
        }
    }
`;
