
import Header1Blue from "../../TextComponents/Header1";
import FormContainer from "../../FormComponents/FormContainer";
import FormLabel from "../../FormComponents/FormLabel";
import Input from "../../FormComponents/Input";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import useShareDeviceForm from "./useShareDeviceForm";
import styled from "styled-components";
import {
    BOXSHADOWBLUE,
    NUVOBLUE,
    NUVOBOLDFONT,
    NUVOGREEN,
    NUVOHEADERBLUE, NUVORED,
    NUVOTEXTBLUE,
    NUVOWHITE
} from "../../../constants/styles";
import validateShareDevice from "./validateShareDevice";
import { useLocation }  from 'react-router-dom';
import useRenderNav from "../../Hooks/Nav/useRenderNav";
import useMobileView from "../../Hooks/useMobileView";
import ShareSiteSuccess from "../ShareSiteSuccess";
import ShareSiteFail from "../ShareSiteFail";


const ShareDevice = ({ shareSiteData }) => {
    const location = useLocation();
    console.log("LOCOOOOO",location.state);
    const { isMobile } = useMobileView();
    const { renderNav } = useRenderNav(isMobile);
    const {handleChange, handleCheckboxChange , handleSubmit, values, errors, sharedSiteStatus} = useShareDeviceForm(validateShareDevice, location.state);

    const handleShareStateRender = (shareStatus) => {
      switch(shareStatus) {
        case "success":
            return <ShareSiteSuccess />
        case "fail":
            return <ShareSiteFail />
        default:
        return (
          <PageWrapper>
          { renderNav()}
          <DashboardContent>
                  <AddDeviceWrapper>
                      <Header1Blue>Share Site</Header1Blue>
                      <FormContainer noValidate className='form' onSubmit={handleSubmit}>
                          <FormLabel htmlFor="email" name="New User Email*"/>
                          <Input
                              isMobile={isMobile}
                              name="email"
                              type="text"
                              placeholder="email@domain.com"
                              value={values.email}
                              onChange={handleChange}
                          />
                          <ErrorMessage message={errors.email}/>
                          <FormLabel htmlFor="email2" name="Confirm Email*"/>
                          <Input
                              isMobile={isMobile}
                              name="email2"
                              type="text"
                              placeholder="email@domain.com"
                              value={values.email2}
                              onChange={handleChange}
                          />
                          <ErrorMessage message={errors.email2}/>
                          <CheckBoxWrapper>
                              <CheckInput
                                  isMobile={isMobile}
                                  name="checkbox"
                                  type="checkbox"
                                  value={values.checkbox}
                                  onChange={handleCheckboxChange}
                              />
                              <CheckBoxText isMobile={isMobile}>Please confirm you want to share the site/device <HighlightText>({location.state.deviceName})</HighlightText> and give access to potentially sensitive site information to another user?</CheckBoxText>
                          </CheckBoxWrapper>
                              <ErrorMessage message={errors.checkbox}/>
                          <ButtonWrapper isMobile={isMobile}>
                              <SubmitButton>Share Site</SubmitButton>
                          </ButtonWrapper>
                      </FormContainer>
              </AddDeviceWrapper>
              <MobileNavSpacer />
            </DashboardContent>
        </PageWrapper>
        )
      }
    };

    return (
      <>
        {handleShareStateRender(sharedSiteStatus)}
      </>  
    )
}

export default ShareDevice;

const PageWrapper = styled.div`
  display: flex;
`;

const MobileNavSpacer = styled.div`
    height: 70px;
`;

const DashboardContent = styled.div`
  width: 100%;
  margin-top: 80px;
`;

const CheckBoxWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
`;

const CheckBoxText = styled.div`
  font-size: ${ props => props.isMobile ? '12px' : '16px'};
  color: ${NUVOBLUE};
  font-weight: 600;
  width: 75%;
`;

const HighlightText = styled.div`
  color: ${NUVORED};
  display: inline-block;
`;

const DashBoardHeaderWrapper = styled.div`
  width: 100%;
  height: 50px;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 14px;
  box-shadow: 0 1px 10px -2px ${BOXSHADOWBLUE};
  margin-bottom: 40px;
`;

const DashBoardHeader = styled.h1`
  margin: 0 0 0 30px;
`;

const ButtonWrapper = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`;

const AddDeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  margin: 0 auto;
`;


const CheckInput = styled.input`
  color: ${NUVOTEXTBLUE};
  width: 30px;
  height: 30px;
  outline: none;
  border: 1px solid ${NUVOBLUE};
  border-radius: 5px;
  padding: 0 10px;
  
  &::placeholder {
    color: rgb(200, 200, 200, 1);
  }
  
  &:focus {
    outline: none;
    border-color: ${NUVOGREEN};
    box-shadow: 0 0 2.5px ${NUVOGREEN};
    transition: all 300ms ease-in;
  }

`;

const ShareSuccess = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  color: ${NUVOWHITE};
  background-color: ${NUVOGREEN};
  border-radius: 3px;
  text-align: center;
`;

const ShareFail = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  color: ${NUVOWHITE};
  background-color: ${NUVORED};
  border-radius: 3px;
  text-align: center;
`;
