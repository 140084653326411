
import Header1Blue from "../../TextComponents/Header1";
import FormContainer from "../../FormComponents/FormContainer";
import FormLabel from "../../FormComponents/FormLabel";
import Input from "../../FormComponents/Input";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import useAddDeviceForm from "./useAddDeviceForm";
import useMobileView from "../../Hooks/useMobileView";
import styled from "styled-components";
import {BOXSHADOWBLUE, NUVOBOLDFONT, NUVOGREEN, NUVOHEADERBLUE, NUVOBLUE, NUVOTEXTBLUE} from "../../../constants/styles";
import validateAddDevice from "./validateAddDevice";
import useRenderNav from "../../Hooks/Nav/useRenderNav";

const AddDevice = ({ setView }) => {

    const { isMobile } = useMobileView();
    const { renderNav } = useRenderNav(isMobile);
    const {handleChange, handleSubmit, values, errors} = useAddDeviceForm(validateAddDevice, setView);

    return (
            <PageWrapper>
            {renderNav()}
            <DashboardContent>
                <AddDeviceWrapper>
                    <Header1Blue>Add a new device</Header1Blue>
                    <FormContainer noValidate className='form' onSubmit={handleSubmit}>
                        <FormLabel htmlFor="deviceName" name="Site Name*"/>
                        <Input
                            isMobile={isMobile}
                            name="deviceName"
                            type="text"
                            placeholder="Fourways Mall"
                            value={values.deviceName}
                            onChange={handleChange}
                        />
                        <ErrorMessage message={errors.deviceName}/>
                        <FormLabel htmlFor="deviceId" name="Mac Address*"/>
                        <Input
                            isMobile={isMobile}
                            name="deviceId"
                            type="text"
                            placeholder="80-80-80-FF-89-BC"
                            value={values.deviceId}
                            onChange={handleChange}
                        />
                        <ErrorMessage message={errors.deviceId}/>
                        <FormLabel htmlFor="deviceId" name="Location*"/>
                        <Input
                            isMobile={isMobile}
                            name="location"
                            type="text"
                            placeholder="Fourways"
                            value={values.location}
                            onChange={handleChange}
                        />
                        <ErrorMessage message={errors.location}/>
                        <GpsLookup href={'https://www.maps.ie/coordinates.html'} target={'_blank'}>GPS Lookup</GpsLookup>
                        <FormLabel htmlFor="latitude" name="GPS Latitude*"/>
                        <Input
                            isMobile={isMobile}
                            name="latitude"
                            type="number"
                            placeholder="-26.7565"
                            value={values.latitude}
                            onChange={handleChange}
                        />
                        <ErrorMessage message={errors.latitude}/>
                        <FormLabel htmlFor="longitude" name="GPS Longitude*"/>
                        <Input
                            isMobile={isMobile}
                            name="longitude"
                            type="number"
                            placeholder="28.45424"
                            value={values.longitude}
                            onChange={handleChange}
                        />
                        <ErrorMessage message={errors.longitude}/>
                        <FormLabel htmlFor="inverterCount" name="Number Of Inverters (required)"/>
                        <Select 
                            isMobile={isMobile}
                            name="inverterCount"
                            value={values.inverterCount}
                            onChange={handleChange}
                            type="text" 

                            >
                             <option value="1">1</option>
                             <option value="2">2</option>
                             <option value="3">3</option>
                             <option value="4">4</option>
                             <option value="5">5</option>
                             <option value="6">6</option>
                             <option value="7">7</option>
                             <option value="8">8</option>
                             <option value="9">9</option>
                             <option value="10">10</option>
                             <option value="11">11</option>
                             <option value="12">12</option>
                             <option value="13">13</option>
                             <option value="14">14</option>
                             <option value="15">15</option>
                            </Select>
                         <ErrorMessage message={errors.inverterCount}/>
                        <FormLabel htmlfor="gensetConfig" name="Genset Configuration"/>
                        <Select
                            isMobile={isMobile}
                            name="gensetConfig"
                            value={values.gensetConfig}
                            onChange={handleChange}
                            type="text"  
                            >
                             <option value="EB State">Shared Single Meter</option>
                             <option value="Meter">Dual Meter</option>
                            </Select>
                         <ErrorMessage message={errors.gensetConfig}/>
                        {/*<FormLabel htmlFor="inverterType" name="Inverter Type"/>
                        <Input
                            isMobile={isMobile}
                            name="inverterType"
                            type="text"
                            placeholder="default"
                            value={values.inverterType}
                            onChange={handleChange}
                        />
                         <ErrorMessage message={errors.inverterType}/>
                        
                        <FormLabel htmlFor="meterCount" name="Number Of Meters(required)"/>
                        <Select
                            isMobile={isMobile}
                            name="meterCount"
                            value={values.meterCount}
                            onChange={handleChange}
                            type="text"  
                            >
                             <option value="1">1</option>
                             <option value="2">2</option>
                             <option value="3">3</option>
                             <option value="4">4</option>
                             <option value="5">5</option>
                            </Select>
                         <ErrorMessage message={errors.meterCount}/>
                        <FormLabel htmlFor="meter1Label" name="Meter 1 Label"/>
                        <Input
                            isMobile={isMobile}
                            name="meter1Label"
                            type="text"
                            placeholder="default"
                            value={values.meter1Label}
                            onChange={handleChange}
                        />
                        <ErrorMessage message={errors.meter1Label}/>*/}
                       
                        <ButtonWrapper isMobile={isMobile}>
                            
                            <SubmitButton>Add Device</SubmitButton>
                        </ButtonWrapper>
                    </FormContainer>
                </AddDeviceWrapper>
                { 
                isMobile
                ? <MobileNavSpacer />
                : null 
            }
            </DashboardContent>
            </PageWrapper>

    )
}

export default AddDevice;

const PageWrapper = styled.div`
    display: flex;
`;

const MobileNavSpacer = styled.div`
    height: 70px;
`;


const DashboardContent = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: ${props => props.isMobile ? '100%' : '80%'};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`;

const AddDeviceWrapper = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;
  margin: 50px auto auto auto;
`;

const GpsLookup = styled.a`
    color: ${NUVOGREEN};
`;

const Select = styled.select`
  color: ${NUVOTEXTBLUE};
  width: ${props => props.isMobile || props.fullWidth ? '100%' : '80%' };
  height: 42px;
  outline: none;
  border: 1px solid ${NUVOBLUE};
  border-radius: 5px;
  padding: 0 10px;
  
  &::placeholder {
    color: rgb(200, 200, 200, 1);
  }
  
  &:focus {
    outline: none;
    border-color: ${NUVOGREEN};
    box-shadow: 0 0 2.5px ${NUVOGREEN};
    transition: all 300ms ease-in;
  }
`;
