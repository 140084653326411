import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

export const GET_SOLAR_PRODUCTION_TODAY = gql`
    query GetSolarProductionToday ($deviceId: String!) {
        getSolarProductionToday (deviceId: $deviceId) {
            data {
                Inv1
                Inv2
                Inv3
                Inv4
                Inv5
                Inv6
                Inv7
                Inv8
                Inv9
                Inv10
            }
        }
    }
`;

const useDailySolarProduction = (deviceId) => {
    const [dailySolarProduction, setDailySolarProduction] = useState(0);

    const {loading} = useQuery(GET_SOLAR_PRODUCTION_TODAY, {
        variables: {deviceId},
        nextFetchPolicy: 'network-only',
        onCompleted: ({getSolarProductionToday}) => {
            const data = getSolarProductionToday?.data;
            if (data) {
                const sum = Object.values(data).reduce((acc, val) => acc + (typeof val === 'number' ? val : 0), 0);
                setDailySolarProduction(Math.round(sum));
            } else {
                setDailySolarProduction(0);
            }
        },
        onError: (error) => {
            console.log("Today's Solar Production:", error);
        }
    });

    return {dailySolarProduction, loading};
}

export default useDailySolarProduction;
