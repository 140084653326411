import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {NUVOBLUE, NUVOWHITE} from "../../constants/styles";
import logo from "../../assets/images/logo.png";
import SharedRegister from "./SharedRegister";
import signupImage from "../../assets/images/signup_image.jpg";

const RegLoginBox = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isLoginActive, setIsLoginActive] = useState(true);
    const [isMobile, setIsMobile] = useState(windowWidth < 700)
    const [registrationState, setRegistrationState] = useState("none");

    useEffect(() => {
        const handleIsMobile = () => {
            setWindowWidth(window.innerWidth);
            windowWidth < 700 ? setIsMobile(true) : setIsMobile(false);
        }
        window.addEventListener("resize", handleIsMobile);
    });

    const handleLoginActive = () => {
        isLoginActive ? setIsLoginActive(false) : setIsLoginActive(true);
    };

    const handleRegistrationState = (state) => {
        setRegistrationState(state);
    }

    if (isMobile) {
        return (
            <Wrapper>
                <BlueBackground />
                <SignupImage />
            <MobileBox>
                <FullBox>
                    <FormWrapper>
                        <SharedRegister setLoginActive={handleLoginActive} isMobile={isMobile} handleRegistrationState={handleRegistrationState} handleLoginActive={handleLoginActive} />
                    </FormWrapper>
                </FullBox>
            </MobileBox>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <BlueBackground />
                <SignupImage />
            <Box>
                <HalfBox>
                    <Logo src={logo} alt={"logo"}/>
                </HalfBox>
                <HalfBox>
                    <FormWrapper>
                        <SharedRegister setLoginActive={handleLoginActive} isMobile={isMobile} handleRegistrationState={handleRegistrationState} handleLoginActive={handleLoginActive} />
                    </FormWrapper>
                </HalfBox>
            </Box>
            </Wrapper>
        )
    }
}

export default RegLoginBox;

const Box = styled.div`
  background-color: ${NUVOWHITE};
  width: 80vw;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 20px;
`;

const MobileBox = styled.div`
  background-color: ${NUVOWHITE};
  width: 80vw;
  overflow: auto;
  position: absolute;
  align-items: center;
  border-radius: 20px;
  padding-bottom: 20px;
`;


const HalfBox = styled.div`
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 100px;
  width: 270px;
`;

const FormWrapper = styled.div`
  height: 80%;
  width: 90%;
`;


const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlueBackground = styled.div`
  float: left;
  width: 50%;
  height: 100% ;
  background-color: ${NUVOBLUE};
`;

const SignupImage = styled.div`
  float: right;
  width: 50%;
  height: 100%;
  background-image: url(${signupImage});
  object-fit: contain;
`;
