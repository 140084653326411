import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {NUVOBOLDFONT, NUVOHEADERBLUE, NUVOWHITE} from '../../../constants/styles';
import DayPicker from '../DayPicker/DayPicker';
import ChartError from './ChartError';
import Spinner from '../../Spinner/Spinner';
import SiteChart from '../../SiteChart/SiteChart';
import useGetPowerRawData from '../../Hooks/useGetPowerRawData';
import {DateContext} from '../../../context/DateContext';
import BigTextBlue from "../../TextComponents/BigTextBlue";

const processData = (data, isGensetMeterSite) => {
    const buildChartData = data.getPowerRawData.map(item => {
        const gensetRunning = isGensetMeterSite ? (item.meter2_ac_active !== null && item.meter2_ac_active !== 0) : item.genset_ac_active !== null;
        const gridValue = gensetRunning ? 0 : item.grid_ac_active / 1000;

        const gridData = { "x": item.x, "y": gridValue };
        const loadData = { "x": item.x, "y": (gridValue + item.inverter_ac_active / 1000 + (isGensetMeterSite ? item.meter2_ac_active / 1000 : item.genset_ac_active / 1000)) };
        const inverterData = { "x": item.x, "y": item.inverter_ac_active / 1000 };
        const gensetData = { "x": item.x, "y": (isGensetMeterSite ? (item.meter2_ac_active === null ? 0 : item.meter2_ac_active / 1000) : (item.genset_ac_active === null ? 0 : item.genset_ac_active / 1000)) };

        return {
            "GenSet Power (kW)": gensetData,
            "Grid Power (kW)": gridData,
            "Load Power (kW)": loadData,
            "Solar Power (kW)": inverterData,
        }
    });

    return Object.keys(buildChartData[0]).map(key => ({
        "id": key,
        "data": buildChartData.map(item => item[key])
    }));
}

const PowerDistribution = ({deviceId, isGensetMeterSite}) => {
    const {date} = useContext(DateContext);
    const [chartData, setChartData] = useState([]);
    const {data, loading, error} = useGetPowerRawData(deviceId, moment(date).format('YYYY-MM-DD'));

    useEffect(() => {
        if (data && data.getPowerRawData.length > 0) {
            const newData = processData(data, isGensetMeterSite);
            setChartData(newData);
        }
    }, [data, isGensetMeterSite]);

    if (error) {
        return (
            <>
                <DayPicker/>
                <SiteChartWrapperLarge>
                    <CardHeader> Power Distribution </CardHeader>
                    <ChartError/>
                </SiteChartWrapperLarge>
            </>
        )
    }

    return (
        <>
            <DayPicker/>
            <SiteChartWrapperLarge>
                <CardHeader>Power Distribution</CardHeader>
                <SiteChartCard>
                    {loading
                        ? <Spinner/>
                        : data && data.getPowerRawData.length > 0 ?
                            <SiteChart
                                data={chartData}
                                colors={['#FF9138', '#002D62', '#FF2400', '#1E8C45']}
                                yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
                            />
                            : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <BigTextBlue>No data available.</BigTextBlue>
                            </div>
                    }
                </SiteChartCard>
            </SiteChartWrapperLarge>
        </>
    )
}

export default PowerDistribution;

const CardHeader = styled.div`
  color: ${NUVOHEADERBLUE};
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  height: 30px;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;

const SiteChartWrapperLarge = styled.div`
  background-color: ${NUVOWHITE};
  width: 100%;
  margin: 10px auto 0 auto;
  height: 470px;
  border: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
`;

const SiteChartCard = styled.div`
  width: 100%;
  height: 90%;
`;
