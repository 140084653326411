
import { useContext, useEffect, useState } from "react";
import UserContext  from "../../../context/UserContext";
import {gql, useQuery, useApolloClient, useMutation} from "@apollo/client";
import {GET_ALL_SITES} from "../../Hooks/useGetAllSites";

const GET_USER_PROFILE = gql`
    query GetUserProfile ($username: String!) {
        getUserProfile (username: $username) {
            username
            name
            id
        }
    }
`;

const UPDATE_USER_PROFILE = gql`
    mutation UpdateUserProfile ($username: String!, $name: String!, $id:  String!) {
        updateUserProfile (id: $id, username: $username, name: $name) {
            message
            success
        }
    }
`;

const useProfile = (validateProfile, handleUpdateSuccess) => {
    const { user } = useContext(UserContext);
    const [values, setValues] = useState({
        name: '',
        email: ''
    });

    const {loading, error, data } = useQuery(GET_USER_PROFILE, {
        fetchPolicy: 'network-only',
        variables: { username: user.username}
    });

    const [UpdateDevice] = useMutation(UPDATE_USER_PROFILE, {
        update(cache, {data: {updateUserProfile: user }})  {
            console.log("worked", user);
            handleUpdateSuccess('success');

        },
        onError(error) {
            console.log(error)
            handleUpdateSuccess('fail');
        },
        variables: {
            username: values.email,
            name:  values.name,
            id: user.id
        }
    })


    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const client = useApolloClient();

    const handleSubmit =  (e) => {

        e.preventDefault();
        console.log(values);
        console.log("Update Submitted")
        const { name } = e.target
        if (Object.keys(validateProfile(values)).length > 0) {
            setErrors(validateProfile(values));
        } else {
            console.log("No Errors");
            console.log(values);
                    UpdateDevice();
                }
            // .catch(errors => console.log(errors));
    }

    useEffect(() => {
        if (data) {
            setValues({
                name: data.getUserProfile.name || 'name',
                email: data.getUserProfile.username || 'email'
            })
        }

    }, [data])

    return { handleChange, handleSubmit, values, errors };
}

export default useProfile;