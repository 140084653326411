import React from 'react';
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Icon} from "@iconify/react";

function EditButton({deviceId}) {
    const navigate = useNavigate();
    const handleEditClick = (e) => {
        e.stopPropagation();
        // props.handleEditSite(data.deviceId);
        navigate('/dashboard/editDevice', { state: {deviceId: deviceId.toString()}});
    }

    return (
        <span title="Edit"><IconX icon="material-symbols:edit-square-outline-rounded" onClick={handleEditClick}/></span>
    );
}

export default EditButton;

const IconX = styled(Icon)`
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  margin-right: 20px;

  &:hover {
    background-color: rgba(0, 0, 12, 0.13);
  }
`;