import {gql} from "@apollo/client";

export const GET_MY_SITES = gql`
    query GetSites($user_id: String!) {
        getSites(user_id: $user_id) {
            id
            mac_id
            name
            username
            location
            latitude
            longitude
            genset_meter
            genset_meter_nr
            inverter_count
            total_ac_active_power
            total_kwh_active
            date_time
            now
            seconds_past_now
            last_update
            sharedSite
            grid_power
            online_check
            last_online_date
        }
    }
`;

export const GET_SHARED_SITES = gql`
    query GetSharedSites($user_id: String!) {
        getSharedSites(user_id: $user_id) {
            id
            mac_id
            name
            username
            location
            latitude
            longitude
            genset_meter
            genset_meter_nr
            inverter_count
            total_ac_active_power
            total_kwh_active
            date_time
            now
            seconds_past_now
            last_update
            sharedSite
            grid_power
            online_check
            last_online_date
        }
    }
`;

export const GET_ALL_SITES = gql`
    query GetAllSites {
        getAllSites {
            id
            mac_id
            name
            username
            location
            latitude
            longitude
            genset_meter
            genset_meter_nr
            inverter_count
            total_ac_active_power
            total_kwh_active
            date_time
            now
            seconds_past_now
            last_update
            sharedSite
            grid_power
            online_check
            last_online_date
            owner {
                id
                username
            }
        }
    }
`;

export const GET_SHARED_USERS = gql`
    query GetSharedUsers($site_id: Int!) {
        getSharedUsers (site_id: $site_id) {
            id
            name
            username
            password
            admin
            removed
            parent
        }
    }
`;
