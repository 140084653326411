
const validateEditDevice = (values, data = null) => {
    const errors = {}

    const deviceIdRegEx = /([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/;
    const latitudeRegEx = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    const longitudeRegEx = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

    if (!values.deviceName) {
        errors.deviceName = "Site name is required";
    } else if (values.deviceName.length > 40) {
        errors.deviceName = "Site name too long, must be no more than 40 characters";
    }

    // console.log(data.mac_idExists);
    if (!values.deviceId) {
        errors.deviceId = "Device/Mac ID is required";
    } else if (!deviceIdRegEx.test(values.deviceId)) {
        errors.deviceId = "Device / Mac ID not in valid format, format should be 80-80-80-FF-89-BC";
    } else if (!data.mac_idExists) {
            errors.deviceId = "Device/Mac ID does not exist, speak to your installer";
    }

    if (!values.location) {
        errors.location = "Location is required";
    } else if (values.location.length > 20 ) {
        errors.location = "Location too long, must be no more than 20 characters"
    } else if (values.location === "Add Location") {
        errors.location = "Add Location is not a valid location"
    }

    if (!values.latitude) {
        errors.latitude = "GPS Latitude is required";
    } else if (!latitudeRegEx.test(values.latitude)) {
        errors.latitude = "Not a valid GPS co-ordinate, latitude must be between -90 and 90";
    }

    if (!values.longitude) {
        errors.longitude = "GPS Longitude is required";
    } else if (!longitudeRegEx.test(values.longitude)) {
        errors.longitude = "Not a valid GPS co-ordinate, longitude must be between -180 and 180";
    }
    // if (!value.inverterCount) {
    //     errors.inverterCount = "Inverter Number is required";
    // } else if (value.inverter.length > 15) {
    //     errors.InverterNumber = "Inverter Number too long, must be no more than 5 characters";
    // // }
    // if (!value.InverterType) {
    //     errors.InverterType = "Inverter Type is required";
    // } else if (values.InverterType.length > 40) {
    //     errors.InverterType = "Inverter Type too long, must be no more than 10 characters";
    // }
    // if (!value.MeterNumber) {
    //     errors.deviceName = "Meter Number is required";
    // } else if (value.MeterNumber.length > 5) {
    //     errors.deviceName = "Site name too long, must be no more than 40 characters";
    // }
    // if (!value.MeterLabel) {
    //     errors.MeterLabel= "Meter Label is required";
    // } else if (value.MeterLabel.length > 40) {
    //     errors.MeterLabel = "Meter Label too long, must be no more than 40 characters";
    // }

    return errors;
}

export default validateEditDevice;
