import { useContext, useState} from 'react';
import { gql, useMutation } from '@apollo/client';
import UserContext from "../../../context/UserContext";

const SEND_SIGNUP_EMAIL = gql`
    mutation SendSignupEmail( $deviceId: String!, $email: String!, $parentEmail: String! $deviceName: String!) {
        sendSignupEmail(deviceId: $deviceId, email: $email parentEmail: $parentEmail, deviceName: $deviceName) {
            siteId
            user
            message
            siteName
        }
    }
`;


const useShareDeviceForm = (validateShareDevice, shareSiteData ) => {
    const { user } = useContext(UserContext);
    const [sharedSiteStatus, setSharedSiteStatus] = useState('');

    const [values, setValues] = useState( {
        email:'',
        email2:'',
    });


    const [errors, setErrors] = useState({});
    const [SendSignupEmail] = useMutation(SEND_SIGNUP_EMAIL, {
        update(_, {data: {sendSignupEmail: email }}) {
            if (email) {
                setSharedSiteStatus('success');
            }
        },
        onError(error) {
            console.log("Something went wrong sharing site");
            setSharedSiteStatus("fail");
        },
        variables: {
            deviceId: shareSiteData.deviceId,
            email: values.email,
            parentEmail: user.username,
            deviceName: shareSiteData.deviceName
        }
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleCheckboxChange = (e) => {

        setValues({
            ...values,
            [e.target.name]: e.target.checked
        })
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
                if (Object.keys(validateShareDevice(values)).length > 0) {
                    setErrors(validateShareDevice(values));
                } else {
                    SendSignupEmail().then(result => console.log(result))
                        .catch(error => console.log(error))
                }


    }
    return { handleChange, handleCheckboxChange, handleSubmit, values, errors, sharedSiteStatus };
}

export default useShareDeviceForm;
