import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

const GET_PRODUCTION_RUNNING_TOTALS_BYWEEK = gql`
  query GetProductionRunningTotalsByWeek($deviceId: String!) {
    getSolarRunningTotalByWeek (deviceId: $deviceId) {
      data {
        day
        month
        Inv1
        Inv2
        Inv3
        Inv4
        Inv5
        Inv6
        Inv7
        Inv8
        Inv9
        Inv10
        kWh
      }
      productionReadings {
        startReading
        endReading
      }
      inverterCount
    }
  }
`;

const useGetProductionRunningTotalsByWeek = (deviceId) => {
  const { data, error, loading } = useQuery(GET_PRODUCTION_RUNNING_TOTALS_BYWEEK, {
    variables: { deviceId: deviceId }
  });

  const productionRunningTotalsByWeek = useMemo(() => {
    if (data) {
      const { data: inverterData, inverterCount } = data.getSolarRunningTotalByWeek;
      const inverterKeysByWeek = Array.from({length: inverterCount}, (_, i) => `Inv${i+1}`);

      return {
        inverterData,
        inverterKeysByWeek
      };
    }

    return null;
  }, [data]);

  return { loading, error, productionRunningTotalsByWeek }
};

export default useGetProductionRunningTotalsByWeek;
