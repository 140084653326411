import { useState, useContext, useCallback, useMemo } from "react";
import styled from "styled-components";
import useGetProductionRunningTotals from "../../Hooks/useGetProductionRunningTotals";
import useGetProductionRunningTotalsByWeek from "../../Hooks/useGetProductionRunningTotalsByWeek";
import { DateContext } from "../../../context/DateContext";
import { ChartDurationContext } from "../../../context/ChartDurationContext";
import moment from "moment";
import MonthlyBarChart from "../../SiteChart/MonthlyBarChart";
import Spinner from "../../Spinner/Spinner";
import ChartError from "./ChartError";
import ProductionTable from '../../Table/ProductionTable';
import GridDataCard from "../../GridCard/GridDataCard";
import {NUVOBOLDFONT, NUVOHEADERBLUE, NUVOWHITE} from "../../../constants/styles";
import ControlsWrapper from "./ControlsWrapper";

const byInverterChartColors = [
    '#bad80a',
    '#009e49',
    '#00b294',
    '#00bcf2',
    '#00188f',
    '#68217a',
    '#ec008c',
    '#e81123',
    '#ff8c00',
    '#fff100',
];

const Production = ({ deviceId }) => {
    const { monthDate, YTDDate } = useContext(DateContext);
    const { chartDuration, updateDuration } = useContext(ChartDurationContext);
    const [chartTypeToggle, setChartTypeToggle] = useState(false);

    const defaultParams = useMemo(() => ({
        duration: chartDuration === '1M' ? monthDate : YTDDate,
        dateTrunc: chartDuration === '1M' ? 'month' : 'year',
        startOf: chartDuration === '1M' ? 'month' : 'year'
    }), [chartDuration, monthDate, YTDDate]);

    const {loading: productionLoading, error: productionError, productionRunningTotals} =
        useGetProductionRunningTotals(
            deviceId,
            defaultParams.dateTrunc,
            moment(defaultParams.duration).startOf(defaultParams.startOf).format('YYYY-MM-DD')
        );

    const {loading: weekLoading, error: weekError, productionRunningTotalsByWeek} = useGetProductionRunningTotalsByWeek(deviceId);

    const handleChartTimeActive = useCallback((state) => state === chartDuration, [chartDuration]);

    const handleChartTimeState = useCallback((time) => updateDuration(time), [updateDuration]);

    if (productionError || weekError) {
        return (
            <SiteChartCardLarge>
                <ControlsWrapper
                    chartDuration={chartDuration}
                    handleChartTimeState={handleChartTimeState}
                    handleChartTimeActive={handleChartTimeActive}
                    chartTypeToggle={chartTypeToggle}
                    setChartTypeToggle={setChartTypeToggle}
                />
                <SiteChartWrapperLarge>
                    <CardHeader> Production </CardHeader>
                    <ChartError />
                </SiteChartWrapperLarge>
            </SiteChartCardLarge>
        )
    }

    return (
        <SiteChartCardLarge>
            <ControlsWrapper
                chartDuration={chartDuration}
                handleChartTimeState={handleChartTimeState}
                handleChartTimeActive={handleChartTimeActive}
                chartTypeToggle={chartTypeToggle}
                setChartTypeToggle={setChartTypeToggle}
            />
            <SiteChartWrapperLarge>
                <CardHeader>
                    {Object.keys(productionRunningTotals).length > 0 && productionRunningTotals.productionByInverterReadings.map((inverter, index) => (
                        <GridDataCard type={'small'} key={index}>
                            <ReadingWrapper>
                                <ProductionTable inverterData={inverter}/>
                            </ReadingWrapper>
                        </GridDataCard>
                    ))}
                </CardHeader>
                {(productionLoading || weekLoading) ? (
                    <Spinner />
                ) : (
                    <ChartWrapper>
                        <MonthlyBarChart
                            data={{
                                monthTotal: productionRunningTotals.inverterData,
                                weekTotal: productionRunningTotalsByWeek.inverterData,
                                ytdTotal: productionRunningTotals.inverterData,
                                monthByInverter: productionRunningTotals.inverterData,
                                weekByInverter: productionRunningTotalsByWeek.inverterData,
                                ytdByInverter: productionRunningTotals.inverterData
                            }}
                            labelSkipWidth={20}
                            duration={chartDuration}
                            showInverters={chartTypeToggle}
                            keys={chartTypeToggle ? productionRunningTotals.inverterKeys : ['kWh']}
                            groupMode={chartTypeToggle ? 'grouped' : 'stacked'}
                            colors={chartTypeToggle ? byInverterChartColors : '#1E8C45'}
                            type='production'
                        />
                    </ChartWrapper>
                )}
            </SiteChartWrapperLarge>
        </SiteChartCardLarge>
    );
};
export default Production;

const SiteChartCardLarge = styled.div`
    width: 100%;
    height: 80%;
`;

const SiteChartWrapperLarge = styled.div`
  background-color: ${NUVOWHITE};
  width: 100%;
  margin: 10px auto 0 auto;
  height: 470px;
  border: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 87%;
`;

const ReadingWrapper = styled.div`
    font-size: 8px;
`;

const CardHeader = styled.div`
  color: ${NUVOHEADERBLUE};
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  height: 67px;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: rgba(47, 101, 158, 0.15);
`;

