import React from "react";
import styled from "styled-components";
import Overview from "./Overview";
import useMobileView from "../Hooks/useMobileView";
import useRenderNav from "../Hooks/Nav/useRenderNav";

const Dashboard = () => {
    const {isMobile} = useMobileView();
    const {renderNav} = useRenderNav(isMobile);

    return (
        <DashboardWrapper>
            {renderNav()}
            <Overview isMobile={isMobile}/>
        </DashboardWrapper>
    )
}

export default Dashboard;

const DashboardWrapper = styled.div`
  display: flex;
`;

