
import styled from 'styled-components';
import { NUVOBOLDFONT, NUVOHEADERBLUE, NUVOWHITE } from "../../../constants/styles";
import SignOut from "../../ButtonComponents/SignOut";
import { useState }  from "react";
import useFetchSiteDataForUser from "../../Hooks/useFetchSiteDataForUser";
import DataExportModal from "../../Modal/DataExportModal";


const UserReporting = ({ isMobile }) => {
    const { data } = useFetchSiteDataForUser();
    const [showModal, setShowModal] = useState(false);
    const [siteId, setSiteId] = useState(null);

    const handleExportClick = (deviceId) => {
        setShowModal(true);
        setSiteId(deviceId);
    }

    const handleExportModalClose = () => {
        setShowModal(false);
    }

    return (
        <DashboardContent>
            <DashBoardHeaderWrapper>
                <DashBoardHeader>Reporting</DashBoardHeader>
                <SignOut>Sign-out</SignOut>
            </DashBoardHeaderWrapper>
            <ReportingTable>
                <ReportingRowHeader>
                    <ReportingTableHeader>Site Name</ReportingTableHeader>
                    {/*<ReportingTableHeader>Reports</ReportingTableHeader>*/}
                    <ReportingTableHeader>Data Export</ReportingTableHeader>
                </ReportingRowHeader>
                    { data.map((site) => {
                        return (
                            <ReportingRow key={site.deviceId}>
                                <ReportingTableCell>{site.deviceName}</ReportingTableCell>
                                {/*<ReportingTableCell>*/}
                                {/*    <RunReportButton onClick={() => {handleReportClick()}} isMobile={isMobile}>Run Reports </RunReportButton>*/}
                                {/*</ReportingTableCell>*/}
                                <ReportingTableCell>
                                    <DataExportButton onClick={() => handleExportClick(site.deviceId)} isMobile={isMobile}>Export Data </DataExportButton>
                                </ReportingTableCell>
                            </ReportingRow>
                        )
                    })}
                    { isMobile ? <MobileNavSpacer /> : null}
            </ReportingTable>
            <DataExportModal
                handleClose={handleExportModalClose}
                show={showModal}
                header='Export Site Data'
                deviceId={siteId}
            />
        </DashboardContent>
    )
}

export default UserReporting;


const DashboardContent = styled.div`
  width: 100%;
`;

const MobileNavSpacer = styled.div`
    height: 100px;
`;

const RunReportButton = styled.button`
  background: #848484;
  color: ${NUVOWHITE};
  width: ${props => props.isMobile ? '100px': '150px'};
  height: 40px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: ${props => props.isMobile ? '10px' : '0' }
  margin-bottom: 20px;

  &:hover {
    filter: brightness(1.03);
  }
  &:active {
    -webkit-box-shadow: 0 0 5px #696969;
    -moz-box-shadow:  0 0 5px #696969;
    box-shadow:  0 0 5px #696969;
  }
`;

const DataExportButton = styled.button`
  background: #4a87f9;
  color: ${NUVOWHITE};
  width: ${props => props.isMobile ? '100px': '150px'};
  height: 40px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: ${props => props.isMobile ? '10px' : '0' }
  margin-bottom: 20px;

  &:hover {
    filter: brightness(1.03);
  }
  &:active {
    -webkit-box-shadow: 0 0 5px #696969;
    -moz-box-shadow:  0 0 5px #696969;
    box-shadow:  0 0 5px #696969;
  }
`;

const DashBoardHeaderWrapper = styled.div`
  background-color: ${NUVOWHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 14px;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
  margin-bottom: 40px;
`;

const DashBoardHeader = styled.h1`
  margin: 0 0 0 30px;
`;

const ReportingTable = styled.table`
  width: 100%;
  height: 50px;
`;

const ReportingTableHeader = styled.th`
    
`;

const ReportingRowHeader = styled.tr`
    text-align: center;
    border-bottom: 2px solid #02075d;;

`;

const ReportingRow = styled.tr`
    text-align: center;
`;
const ReportingTableCell = styled.td`
  text-overflow: ellipsis;
`;

