import React, {useState} from "react";
import {gql, useQuery} from '@apollo/client';
import styled from "styled-components";
import {NUVOREGULARFONT} from "../../../constants/styles";
import Input from "../../FormComponents/Input";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import ModalPortal from "../ModalPortal";
import ResetPasswordAdmin from "./ResetPasswordAdmin";
import SendResetPassword from "./SendResetPassword";
import ActivateDeactivateUser from "./ActivateDeactivateUser";
import ShareSites from "./ShareSites";
import Spinner from "../../Spinner/Spinner";
import AssignRole from "./AssignRole";
import AddUser from "./AddUser";
import FilterSelect from "../Filter";
import useMobileView from "../../Hooks/useMobileView";

const UserTable = () => {
    const { isMobile } = useMobileView();
    const [showDisabled, setShowDisabled] = useState(false);
    const {loading, data} = useQuery(GET_ALL_USERS, {
        variables: {disabled: showDisabled},
        fetchPolicy: 'cache-and-network'
    })
    const [searchInput, setSearchInput] = useState('');
    if (loading) return <Spinner/>;
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filteredUsers = data?.getAllUsers.filter((user) =>
        user.username.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.name?.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleClearInput = () => {
        setSearchInput('');
    };

    return (
        <>
            <Card>
                <TopActions isMobile={isMobile}>
                    <InputSearch placeholder={'Search'} title={'Search by name and username'}
                                 onChange={handleSearchInputChange} value={searchInput}/>
                    {searchInput && <ClearButton onClick={handleClearInput}>Clear</ClearButton>}
                    <FilterSelect value={showDisabled} onChange={setShowDisabled}/>
                    <ModalPortal
                        renderItem={(setIsOpen) => <div><AddButton onClick={() => setIsOpen(true)}>➕ Add User</AddButton></div>}>
                        {(setIsOpen) => <AddUser setIsOpen={setIsOpen}/>}
                    </ModalPortal>
                </TopActions>
                <TableWrapper isMobile={isMobile}>
                    <UsersTable>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Role</th>
                            <th>Sites</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredUsers?.map((user) => (
                            <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.username}</td>
                                <td><AssignRole userRoles={user.roles} userId={user.id}/></td>
                                <td>
                                    <ModalPortal icon="mdi:eye-arrow-right" title="Show sites" noNeedSpace>
                                        {(setIsOpen) => <ShareSites userId={user.id} setIsOpen={setIsOpen}/>}
                                    </ModalPortal>
                                </td>
                                <Actions>
                                    {!user.removed ? <>
                                            <ModalPortal icon="material-symbols:lock-reset" title="Reset Password">
                                                {(setIsOpen) => <ResetPasswordAdmin user={{email: user.username}}
                                                                                    setIsOpen={setIsOpen}/>}
                                            </ModalPortal>

                                            <ModalPortal icon="ic:round-send-time-extension" title="Send reset password"
                                                         color="#91CC32">
                                                {(setIsOpen) => <SendResetPassword userEmail={user.username}
                                                                                   setIsOpen={setIsOpen}/>}
                                            </ModalPortal>

                                            <ModalPortal icon="material-symbols:person-remove-rounded" title="Remove user"
                                                         color="red">
                                                {(setIsOpen) => <ActivateDeactivateUser user={user} setIsOpen={setIsOpen}
                                                                                        activate={false}/>}
                                            </ModalPortal></>
                                        : <ModalPortal icon="mdi:account-reactivate" title="Activate user"
                                                       color="green">
                                            {(setIsOpen) => <ActivateDeactivateUser user={user} setIsOpen={setIsOpen}
                                                                                    activate/>}
                                        </ModalPortal>}
                                </Actions>
                            </tr>
                        ))}
                        </tbody>
                    </UsersTable></TableWrapper>
            </Card></>
    );
};

export default UserTable;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: auto;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-right: 16px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  max-height: ${({isMobile}) => isMobile ? '65vh' : '70vh' };;
`;

const UsersTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
  font-family: ${NUVOREGULARFONT};

  @media (min-width: 768px) {
    width: 100%;
  }

  th, td {
    border-bottom: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr {
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) {
    background-color: #ffffff;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #f2f2f2;
  }
`;

const TopActions = styled.div`
  display: ${({isMobile}) => isMobile ? 'grid' : 'flex' };
  grid-template-columns: ${({isMobile}) => isMobile ? '1fr 1fr' : 'auto' };
  gap: 10px;
  align-items: center;
  margin-bottom: 8px;
`;

const Actions = styled.td`
  flex-direction: row;
  display: flex;
  justify-content: center
`;

const ClearButton = styled(SubmitButton)`
  margin-top: 0;
  margin-bottom: 0;
  width: auto;
  margin-left: 8px;
  background-color: #555555
`;

const InputSearch = styled(Input)`
  width: auto;
`;

const AddButton = styled(SubmitButton)`
  margin-top: 0;
  margin-bottom: 0;
  width: auto;
  margin-left: auto;
`;

export const GET_ALL_USERS = gql`
    query GetAllUsers ($disabled: Boolean) {
        getAllUsers (disabled: $disabled) {
            id
            name
            username
            admin
            removed
            roles {
                id
                name
            }
            sites {
                id
                mac_id
                name
            }
        }
    }

`;
