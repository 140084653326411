import React, {useState} from 'react';
import FormContainer from "../FormComponents/FormContainer";
import FormLabel from "../FormComponents/FormLabel";
import Input from "../FormComponents/Input";
import ErrorMessage from "../FormComponents/ErrorMessage";
import {ButtonWrapper} from "../Login/Login";
import SubmitButton from "../ButtonComponents/SubmitButton";
import styled from "styled-components";
import {NUVOBOLDFONT, NUVOGREEN, NUVOHEADERBLUE, NUVOWHITE} from "../../constants/styles";
import useForgotPassword from "../Hooks/useForgotPassword";
import SentIcon from "./SentIcon";
import BigTextBlue from "../TextComponents/BigTextBlue";
import validateLogin from "../Login/validateLogin";

function EmailForm({isMobile}) {
    const [email, setEmail] = useState('')
    const {handleSubmit, error, isEmailSent} = useForgotPassword(email);

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    return (
        <FormWrapper isMobile={isMobile} isEmailSent={isEmailSent}>
            <Header1Blue
                isEmailSent={isEmailSent}>{isEmailSent ? 'Recovery email has been sent' : 'Forgot Password?'}</Header1Blue>
            {isEmailSent ?
                <div style={{textAlign: 'center'}}>
                    <SentIcon width={100}/>
                    <BigTextBlue>Please, check your email.</BigTextBlue>
                </div>
                : <>
                    <BigTextBlue>Fill in your email address to receive instructions</BigTextBlue>
                    <FormContainer noValidate className='form' onSubmit={handleSubmit}>
                        <FormLabel htmlFor="email" name="Email*"/>
                        <Input
                            isMobile={isMobile}
                            name="email"
                            type="email"
                            placeholder="name@domain.com"
                            value={email}
                            onChange={handleChange}
                            required
                        />
                        <ErrorMessage message={error}/>
                        <ButtonWrapper isMobile={isMobile}>
                            <SubmitButton disabled={validateLogin({email}).email}>Send me the link</SubmitButton>
                        </ButtonWrapper>
                    </FormContainer>
                </>
            }
            <Link href={'/'} isMobile={isMobile} isEmailSent={isEmailSent}>Log in</Link>
        </FormWrapper>
    );
}

export default EmailForm;

const Header1Blue = styled.h1`
  font-family: ${NUVOBOLDFONT};
  color: ${NUVOHEADERBLUE};
  font-size: 24px;
  text-align: ${props => props.isEmailSent ? 'center' : 'justify'};
`;

const Link = styled.a`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: ${NUVOWHITE};
  font-family: ${NUVOBOLDFONT};
  color: ${NUVOGREEN};
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  width: ${props => props.isEmailSent ? 'auto' : '80%'};
`;

const FormWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: ${props => props.isMobile ? '100%' : '50%'};
  align-items: ${props => props.isEmailSent && 'center'};
`;