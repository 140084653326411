import './ToggleSwitch.css'
import { useState } from 'react'

const ToggleSwitch = ({name, checked, onChange}) => {
    return (
        <div className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={ e => onChange(e.currentTarget.checked)}
        />
        <label className="toggle-switch-label" htmlFor={name}>
          <span className="toggle-switch-inner" />
          <span className="toggle-switch-switch" />
        </label>
      </div>
    )
}

export default ToggleSwitch;