
import userIcon from '@iconify/icons-mdi/file-user';
import passwordIcon from '@iconify/icons-mdi/forgot-password';
import Tab from '../../Tab/Tab';
import UpdateProfile from './UpdateProfile';
import ResetPassword from "./ResetPassword";
import styled from "styled-components";
import { NUVOBOLDFONT, NUVOHEADERBLUE, NUVOWHITE} from "../../../constants/styles";
import SignOut from "../../ButtonComponents/SignOut";
import useMobileView from '../../Hooks/useMobileView';
import useRenderNav from '../../Hooks/Nav/useRenderNav';

const Profile = () => {
    const { isMobile } = useMobileView();
    const { renderNav } = useRenderNav(isMobile);
    const tabContent = [
        {
            title: "Profile",
            icon: userIcon,
            content: <UpdateProfile />
        },
        {
            title: "Security",
            icon: passwordIcon,
            content: <ResetPassword />
        }
    ]

    return (
        <PageWrapper>
            {renderNav()}
                <DashboardContent>
                <DashBoardHeaderWrapper>
                    <DashBoardHeader>Profile</DashBoardHeader>
                    <SignOut>Sign-out</SignOut>
                </DashBoardHeaderWrapper>
                <Tab>
                    {tabContent.map((tab, index) => (
                        <Tab.TabPane key={`Tab-${index}`} tab={tab.title} icon={tab.icon}>
                            {tab.content}
                        </Tab.TabPane>
                    ))}
                </Tab>

            </DashboardContent>
        </PageWrapper>
        
    )
}

export default Profile;

const PageWrapper = styled.div`
    display: flex;
`;

const DashboardContent = styled.div`
  width: 100%;
`;

const DashBoardHeaderWrapper = styled.div`
  background-color: ${NUVOWHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${NUVOHEADERBLUE};
  font-family: ${NUVOBOLDFONT};
  font-size: 14px;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.2);
  margin-bottom: 40px;
`;

const DashBoardHeader = styled.h1`
  margin: 0 0 0 30px;
`;


