
import React, { useState, useEffect, Children, isValidElement } from 'react';
import { Icon, InlineIcon } from '@iconify/react';
// import password from '@iconify/icons-mdi/forgot-password';
import styled from 'styled-components';


const Tab = ({ children, active = 0 }) => {
    const [activeTab, setActiveTab] = useState(active);
    const [tabsData, setTabsData] = useState([]);
    const [isActive, setIsActive] =useState(false);


    useEffect(() => {
        let data = [];

        Children.forEach(children, element => {
            if (!isValidElement(element)) return;

            const { props: { tab, icon, children }} = element;
            data.push({ tab, icon,  children });
        })

        setTabsData(data);
    }, [children]);

    const handleClick = (index, activeTab) => {
        setActiveTab(index)
    };

    return (
        <>
            <TabWrapper>
                <TabNav>
                    {
                        tabsData.map(({ tab, icon }, index) => {
                            return (
                                <>
                                    <TabNavItem isActive={`${index === activeTab ? "active": ""}`}>
                                        <Icon icon={icon} style={{ fontSize: '24px' }} />
                                        <TabNavAnchor isActive={`${index === activeTab ? "active": ""}`} onClick={() => {handleClick(index)}} >{tab}</TabNavAnchor>
                                    </TabNavItem>
                                </>

                            )
                        })
                    }
                </TabNav>
                <div>
                    {tabsData[active] && tabsData[activeTab].children}
                </div>

            </TabWrapper>
        </>
    )

}

const TabPane = ({ children }) => {
return {children}
}

Tab.Tabpane = TabPane;

export default Tab

const TabWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const TabNav = styled.ul`
  display: flex;
  justify-content: left;
  align-items: center;
  list-style: none;
  padding: 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const TabNavItem = styled.div`
  float: left;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.isActive ? '#1976d2' : '#5D5D5D'};
`;

const TabNavAnchor = styled.a`
  padding-bottom: 10px;
  color: ${props => props.isActive ? '#1976d2' : '#5D5D5D'};
  border-bottom: ${props => props.isActive ? '3px solid #1976d2' : 'none'};
`;

