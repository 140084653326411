
import styled from "styled-components"
import { NUVOBLUE } from "../../../constants/styles";

const UnavailableFeature = ({feature})=> {
    return (
        <>
        <SiteChartCardLarge>
            <ErrorMessageWrapper>
                <ErrorMessageText>
                    We are still busy with the {feature} feature, it is currently unavailable.
                </ErrorMessageText>
            </ErrorMessageWrapper>
        </SiteChartCardLarge>
    </>
    )
}

export default UnavailableFeature;

const SiteChartCardLarge = styled.div`
  width: 100%;
  height: 80%;
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ErrorMessageText = styled.div`
  width: 60%;
  color: ${NUVOBLUE};
  font-weight: bold;
`;
