
import React, { useState } from "react";
import styled from "styled-components";
import { NUVOWHITE } from "../constants/styles";
import logo from "../assets/images/logo.png";
import Login from "./Login/Login";
import Register from "./Register/Register";
import useMobileView from "./Hooks/useMobileView";

const RegLoginBox = (props) => {
    const { isMobile } = useMobileView();
    const [isLoginActive, setIsLoginActive] = useState(true);
    const [registrationState, setRegistrationState] = useState("none");

    const handleLoginActive = () => {
        isLoginActive ? setIsLoginActive(false) : setIsLoginActive(true);
    };

    const handleRegistrationState = (state) => {
        setRegistrationState(state);
    }

    if (isMobile) {
        return (
            <MobileBox>
                <FullBox>
                    <FormWrapper>
                        {(() => {
                           return isLoginActive ?
                               <Login setLoginActive={handleLoginActive}  isMobile={isMobile} /> :
                               <Register setLoginActive={handleLoginActive} isMobile={isMobile} handleRegistrationState={handleRegistrationState} handleLoginActive={handleLoginActive} />
                        })()}
                    </FormWrapper>
                </FullBox>
            </MobileBox>
        )
    } else {
        return (
            <Box>
                <HalfBox>
                    <Logo src={logo} alt={"logo"}/>
                </HalfBox>
                <HalfBox>
                    <FormWrapper>
                        {(() => {
                            return isLoginActive ?
                                <Login setLoginActive={handleLoginActive} isMobile={isMobile} registrationState={registrationState} /> :
                                <Register setLoginActive={handleLoginActive} isMobile={isMobile} handleRegistrationState={handleRegistrationState} handleLoginActive={handleLoginActive} />
                        })()}
                    </FormWrapper>
                </HalfBox>
            </Box>
        )
    }
}

export default RegLoginBox;

const Box = styled.div`
  background-color: ${NUVOWHITE};
  width: 80vw;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 20px;
`;

const MobileBox = styled.div`
  background-color: ${NUVOWHITE};
  width: 80vw;
  overflow: auto;
  position: absolute;
  align-items: center;
  border-radius: 20px;
  padding-bottom: 20px;
`;


const HalfBox = styled.div`
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 100px;
  width: 270px;
`;

const FormWrapper = styled.div`
  height: 80%;
  width: 90%;
`;


