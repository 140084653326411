import {emailRegex} from "../../helpers/variables";

const validateRegister = (values) => {
    const errors = {}

     if (!values.name) {
        errors.name = "Name is required";
    }

    if (!values.email) {
     errors.email = "Email is required";
 } else if (!emailRegex.test(values.email)) {
     errors.email = "Email address is invalid";
 }

 if (!values.password) {
     errors.password = "Password is required";
 } else if (values.password.length < 8) {
     errors.password = "Password needs to be longer then 8 characters";
 }

 if (!values.password2 || values.password !== values.password2) {
     errors.password2 = "Passwords need to match";
 }

 return errors;
}

export default validateRegister;