
import { useState } from "react";
import Header1Blue from "../../TextComponents/Header1";
import FormContainer from "../../FormComponents/FormContainer";
import FormLabel from "../../FormComponents/FormLabel";
import Input from "../../FormComponents/Input";
import ErrorMessage from "../../FormComponents/ErrorMessage";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import usePassword from "./usePassword";
import validatePassword from "./validatePassword";
import styled from "styled-components";
import {NUVOGREEN, NUVORED, NUVOWHITE} from "../../../constants/styles";

const ResetPassword = () => {

    const [updateSuccess, setUpdateSuccess] = useState(null)
    const handleUpdateSuccess = (value) => {
        setUpdateSuccess(value);
    }
    const { handleChange, handleSubmit, values, errors } = usePassword(validatePassword, handleUpdateSuccess );

    const handleUpdateStateRender = (updateState) => {
        switch(updateState) {
            case "success":
                return <RegSuccess>Password has successfully been update</RegSuccess>;
            case "fail":
                return <RegFail>Oops something went wrong, update failed</RegFail>;
            default:
                return null;
        }
    }

    return (
        <AddDeviceWrapper>
            <Header1Blue>Password Update</Header1Blue>
            <MessageWrapper>
                {handleUpdateStateRender(updateSuccess)}
            </MessageWrapper>
            <FormContainer noValidate className='form' onSubmit={handleSubmit}>
                <FormLabel htmlFor="current" name="Current Password"/>
                <Input
                    name="current"
                    type="password"
                    value={values.current}
                    onChange={handleChange}
                    fullWidth={true}
                />
                <ErrorMessage message={errors.current}/>
                <FormLabel htmlFor="new" name="New Password"/>
                <Input
                    name="new"
                    type="password"
                    value={values.new}
                    onChange={handleChange}
                    fullWidth={true}
                />
                <ErrorMessage message={errors.new}/>
                <FormLabel htmlFor="confirm" name="Confirm Password"/>
                <Input
                    name="confirm"
                    type="password"
                    value={values.confirm}
                    onChange={handleChange}
                    fullWidth={true}
                />
                <ErrorMessage message={errors.confirm}/>
                <ButtonWrapper>
                    <SubmitButton>Reset Password</SubmitButton>
                </ButtonWrapper>
            </FormContainer>
        </AddDeviceWrapper>
    )
}

export default ResetPassword;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`;

const AddDeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  margin: 0 auto;
`;

const RegSuccess = styled.div`
  width: 80%;
  margin: 0 auto;
  color: ${NUVOWHITE};
  background-color: ${NUVOGREEN};
  border-radius: 3px;
  text-align: center;
`;

const RegFail = styled.div`
  width: 80%;
  margin: 0 auto;
  color: ${NUVOWHITE};
  background-color: ${NUVORED};
  border-radius: 3px;
  text-align: center;
`;

const MessageWrapper = styled.div`
  height: 20px;
`;
