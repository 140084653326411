import { ISLIVE_OFFSET } from "../../constants/offsets";

const useIsLive = (siteData) => {
    return siteData && siteData.seconds_past_now != null && siteData.seconds_past_now < ISLIVE_OFFSET;
};

export default useIsLive;

export const getLiveColor = (status) => {
    switch (status) {
        case 'live':
            return '#91CC32';
        case 'delayed':
            return '#FECC47';
        default:
            return '#D94444';
    }
};
