
import { useState, createContext } from 'react'

export const ChartDurationContext = createContext();

const ChartDurationContextProvider = (props) => {
    const [chartDuration, setChartDuration] = useState('1M');

    const updateDuration = (value) => {
        setChartDuration(value);
    }

    return <ChartDurationContext.Provider value={{ chartDuration, updateDuration }} >
        {props.children}
    </ChartDurationContext.Provider>
}

export default ChartDurationContextProvider;