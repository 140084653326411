
let accessToken = '';

export const setAccesstoken = (token) => {
    accessToken = token;
}

export const getAccesstoken = () => {
    return accessToken;
}
