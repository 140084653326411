import useMobileView from "../../Hooks/useMobileView";
import AdminReporting from "./AdminReporting";
import UserReporting from "./UserReporting";
import useRenderNav from "../../Hooks/Nav/useRenderNav";
import styled from "styled-components";
import usePermission from "../../Hooks/useGetPermissions";
import {SUPER_ADMIN_ROLE} from "../../../helpers/variables";

const Reports = () => {
    const hasAdminPermission = usePermission(SUPER_ADMIN_ROLE)
    const { isMobile } = useMobileView();
    const { renderNav } = useRenderNav(isMobile);

    return (
        <PageWrapper>
            {renderNav()}        
            { hasAdminPermission
                ? <AdminReporting isMobile={isMobile} />
                : <UserReporting isMobile={isMobile} />
            }
        </PageWrapper>
        
    )
}

const PageWrapper = styled.div`
    display: flex;
`;

export default Reports