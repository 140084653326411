import { gql, useQuery } from "@apollo/client";

const GET_SHARED_USER_MAP_LOCATIONS = gql`
    query GetSharedSites($user_id: String!) {
        getSharedSites (user_id: $user_id) {
            name
            latitude
            longitude
        }
    }
`;

const useSharedUserMapLocations = ({ id }) => {
    const sharedUserMapLocationData = useQuery(GET_SHARED_USER_MAP_LOCATIONS, {
        // skip: parent,
        variables: { user_id: id }
    })

    return { sharedUserMapLocationData }
}

export default useSharedUserMapLocations;
