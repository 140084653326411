import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="paper_x5F_plane"
    style={{
      enableBackground: "new 0 0 128 128",
    }}
    viewBox="0 0 128 128"
    {...props}
  >
    <style>
      {".st1{fill:#fff}.st3{fill:#074370}.st5{opacity:.5;fill:#0c508e}"}
    </style>
    <circle
      cx={64}
      cy={63.8}
      r={64}
      style={{
        fill: "#89dc45",
      }}
    />
    <path d="M126.2 27.2 35.4 61.7l2.3 29.6z" className="st1" />
    <path
      d="m55 79.6-18 12 7.8-21.8z"
      style={{
        fill: "#d4d6d6",
      }}
    />
    <path
      d="M37.7 92.3c-.3 0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l88.5-64.1c.4-.3 1.1-.2 1.4.2.3.4.2 1.1-.2 1.4L38.2 92.1c-.1.2-.3.2-.5.2z"
      className="st3"
    />
    <path d="m14.2 43.3 112-16.1-59.8 61.1z" className="st1" />
    <path
      d="m121.3 29.2-86 33.6L37 92.2l8.7-23z"
      style={{
        fill: "#eaeaea",
      }}
    />
    <path
      d="M66.4 89.3c-.2 0-.5-.1-.7-.2L43.9 70.3c-.4-.4-.5-1-.1-1.4.4-.4 1-.5 1.4-.1L66.3 87l57-58.3L16.5 44l20.4 17.6c.4.4.5 1 .1 1.4-.4.4-1 .5-1.4.1l-22-19c-.3-.3-.4-.7-.3-1 .1-.4.4-.7.8-.7L126 26.3c.4-.1.8.2 1 .5.2.4.1.8-.2 1.2L67.1 89c-.2.2-.4.3-.7.3z"
      className="st3"
    />
    <path
      d="M37.7 92.3h-.3c-.5-.2-.8-.7-.7-1.3l7-22.4c.1-.3.3-.5.5-.6l63.7-32.5L37.5 63c-.5.2-1.1-.1-1.3-.6-.2-.5.1-1.1.6-1.3l89-34.8c.5-.2 1.1 0 1.3.5.2.5 0 1.1-.5 1.3L45.4 69.6l-6.8 22c-.1.5-.5.7-.9.7z"
      className="st3"
    />
    <path
      d="M37.7 92.3c-.5 0-1-.4-1-.9l-2.3-29.7c0-.6.4-1 .9-1.1.6 0 1 .4 1.1.9l2.3 29.7c0 .6-.4 1.1-1 1.1z"
      className="st3"
    />
    <path
      d="M76.9 38.9c-.5 0-.9-.4-1-.8-.1-.5.3-1.1.8-1.1L90 34.9c.5-.1 1.1.3 1.1.8.1.5-.3 1.1-.8 1.1L77 38.9h-.1zM25.2 47.1c-.5 0-.9-.4-1-.8-.1-.5.3-1.1.8-1.1l39.7-6.3c.5-.1 1.1.3 1.1.8.1.5-.3 1.1-.8 1.1l-39.7 6.3h-.1zM67 81.8c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l28-27c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-28 27c-.2.2-.5.3-.7.3z"
      className="st5"
    />
    <path
      d="M17 79c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0-6.1c-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1 1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1zM84 106c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0-6.1c-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1 1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1zM88 25c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0-6.1c-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1 1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1zM59.8 21.2c0-.4-.4-.8-1-.9l-1.9-.3c-.5-.1-1.1-.6-1.1-1.1l-.3-1.9c-.1-.5-.5-1-.9-1s-.8.4-.9 1l-.3 1.9c-.1.5-.6 1.1-1.1 1.1l-1.9.3c-.5.1-1 .5-1 .9s.4.8 1 .9l1.9.3c.5.1 1.1.6 1.1 1.1l.3 1.9c.1.5.5 1 .9 1s.8-.4.8-1l.3-2.1c.1-.5.6-1 1.1-1.1l1.8-.1c.7-.1 1.2-.5 1.2-.9zM108.5 76.8c0-.4-.4-.8-1-.9l-1.9-.3c-.5-.1-1.1-.6-1.1-1.1l-.3-1.9c-.1-.5-.5-1-.9-1s-.8.4-.9 1l-.3 1.9c-.1.5-.6 1.1-1.1 1.1l-1.9.3c-.5.1-1 .5-1 .9s.4.8 1 .9l1.9.3c.5.1 1.1.6 1.1 1.1l.3 1.9c.1.5.5 1 .9 1s.8-.4.8-1l.3-2.1c.1-.5.6-1 1.1-1.1l1.8-.1c.8-.2 1.2-.5 1.2-.9zM39 107c0 1.1 1.7 2 3.3 2h13.9c1.7 0 2.7-.9 2.7-2s1.6-2 3-2 2-.9 2-2-1-2-2.7-2H51c-1.7 0-3 .9-3 2s-1 2-2.7 2h-3c-1.6 0-3.3.9-3.3 2z"
      className="st1"
    />
  </svg>
)
export default SvgComponent
