import KpiCard from './KpiCard';
import useMobileView from "../Hooks/useMobileView";
import useDailySolarProduction from "../Hooks/useDailySolarProduction";
import {NUVOGREEN} from "../../constants/styles";

const DailyEnergyKpi = ({deviceId}) => {
    const {isMobile} = useMobileView();
    const description = isMobile ? "Solar" : "Today's Solar Production";
    const {dailySolarProduction, loading} = useDailySolarProduction(deviceId);
    const value = loading ? "-" : dailySolarProduction;
    return <KpiCard items={[{description: description, value, unit: 'kWh', color: NUVOGREEN}]}/>
}

export default DailyEnergyKpi;
