import { gql, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { GraphQLError } from "graphql";

const GET_GRID_GENSET_RUNNING_TOTALS = gql`
    query GetGridGensetRunningTotals($deviceId: String!, $dateTruncType: String!, $date: String!) {
        getGridGensetRunningTotalByAnyDate (deviceId: $deviceId, dateTruncType: $dateTruncType, date: $date ) {
            data {
                day
                month
                grid_import
                genset_import
                import_total
                export_total
                m2_import_total
                import_kwh
                export_kwh
                m2_import_kwh
                eb_null_count
                eb_grid_count
                eb_genset_count
                lastperiodRecord
            }
            importReading {
                startReading
                endReading
            }
            exportReading {
                startReading
                endReading
            }
            m2ImportReading {
                startReading
                endReading
            }
        }
    }
`;

const useGetGridGensetRunningTotalByAnyDate = (deviceId, dateTruncType, date, isGensetMeterSite) => {
    const [state, setState] = useState({
        gridGensetRunningTotals: [],
        importReading: {start: 0, end: 0},
        exportReading: {start: 0, end: 0},
        gensetReading: {start: 0, end: 0},
        gridImportTotal: 0,
        gensetImportTotal: 0,
        gensetRunningMinutes: 0,
        exportTotal: 0,
        error: null,
    });

    const { loading, error, data } = useQuery(GET_GRID_GENSET_RUNNING_TOTALS, {
        variables: { deviceId: deviceId, dateTruncType: dateTruncType, date: date }
    });

    useEffect(() => {
        if (error) {
            setState(prevState => ({...prevState, error: {type: GraphQLError, error}}));
            return;
        }

        if (data?.getGridGensetRunningTotalByAnyDate) {
            const { data: totalsData, importReading, exportReading, m2ImportReading } = data.getGridGensetRunningTotalByAnyDate;

            const {
                gridImportTotal,
                gensetImportTotal,
                gensetRunningMinutes
            } = totalsData.reduce((acc, item) => ({
                gridImportTotal: acc.gridImportTotal + item.grid_import,
                gensetImportTotal: acc.gensetImportTotal + (isGensetMeterSite ? item.m2_import_kwh : item.genset_import),
                gensetRunningMinutes: acc.gensetRunningMinutes + (isGensetMeterSite ? 0 : item.eb_genset_count),
            }), { gridImportTotal: 0, gensetImportTotal: 0, gensetRunningMinutes: 0 });

            const exportTotal = totalsData[totalsData.length - 1]?.export_total || 0;

            setState(prevState => ({
                ...prevState,
                gridGensetRunningTotals: totalsData,
                importReading: {start: importReading.startReading, end: importReading.endReading},
                exportReading: {start: exportReading.startReading, end: exportReading.endReading},
                gensetReading: {start: m2ImportReading?.startReading || 0, end: m2ImportReading?.endReading || 0},
                gridImportTotal,
                gensetImportTotal,
                gensetRunningMinutes,
                exportTotal,
                error: null
            }));
        }
    }, [data, error, isGensetMeterSite]);

    return { ...state, loading };
}

export default useGetGridGensetRunningTotalByAnyDate;
