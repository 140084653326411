import {gql, useQuery} from "@apollo/client";

const GET_POWER_RAW_DATA = gql`
  query GetPowerRawData ($deviceId: String!, $date: String!) { 
          getPowerRawData (deviceId: $deviceId, date: $date) {
            x
            inverter_ac_active
            grid_ac_active
            genset_ac_active
            meter2_ac_active
          }
    }
`;

const useGetPowerRawData = (deviceId, date) => {
    return useQuery(GET_POWER_RAW_DATA, {
        variables: {deviceId: deviceId, date: date}
    });
}

export default useGetPowerRawData;
