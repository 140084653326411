import {useMutation, gql} from '@apollo/client';
import {useState} from "react";

function useAdminResetPassword(setIsOpen) {
    const [error, setError] = useState(null);
    const [adminResetPasswordMutation, {data, loading}] = useMutation(ADMIN_RESET_PASSWORD, {
        onError(error) {
            setError(error)
        },
        onCompleted() {
            setIsOpen && setIsOpen(false)
        }
    });

    function validatePassword(password) {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        return regex.test(password);
    }

    const adminResetPassword = async (my_email, email, password) => {
        if (validatePassword(password)) {
            await adminResetPasswordMutation({variables: {my_email, email, password}});
        } else setError('Weak password')
    };

    return {adminResetPassword, data, loading, error};
}

export default useAdminResetPassword;

const ADMIN_RESET_PASSWORD = gql`
    mutation AdminResetPassword($my_email: String, $email: String, $password: String) {
        adminResetPassword(my_email: $my_email, email: $email, password: $password) {
            success
            message
            error
        }
    }
`;
