
import styled from "styled-components";
import useMobileView from "../Hooks/useMobileView";

const Table = ({ isGensetMeterSite, importData, exportData, gensetData}) => {

  const { isMobile } = useMobileView();

    return (
      <>
        <div style={{fontSize: "15px"}}>Actual Readings</div>
        <StyledTable>
        <thead>
            <tr>
                <th></th>
                <th>Start</th>
                <th>End</th>
                <th style={{color: '#1976d2'}}>Move</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Import(kWh)</td>
                <td style={{color: '#5D5D5D'}}>{importData.start === null ? `-` : parseFloat(importData.start).toLocaleString('en')}</td>
                <td style={{color: '#5D5D5D'}}>{importData.end === null ? `-` : parseFloat(importData.end).toLocaleString('en')}</td>
                <td style={{color: '#4a87f9'}}>{parseFloat(importData.end - importData.start).toLocaleString('en')}</td>
            </tr>
            <tr>
                <td>Export(kWh)</td>
                <td style={{color: '#5D5D5D'}}>{exportData.start === null ? `-` : parseFloat(exportData.start).toLocaleString('en')}</td>
                <td style={{color: '#5D5D5D'}}>{exportData.end === null ? `-` : parseFloat(exportData.end).toLocaleString('en')}</td>
                <td style={{color: '#ae6bec'}}>{parseFloat(exportData.end - exportData.start).toLocaleString('en')}</td>
            </tr>
            {isGensetMeterSite ? <tr>
                <td>Genset(kWh)</td>
                <td style={{color: '#5D5D5D'}}>{gensetData.start === null ? `-` : parseFloat(gensetData.start).toLocaleString('en')}</td>
                <td style={{color: '#5D5D5D'}}>{gensetData.end === null ? `-` : parseFloat(gensetData.end).toLocaleString('en')}</td>
                <td style={{color: '#ae6bec'}}>{parseFloat(gensetData.end - gensetData.start).toLocaleString('en')}</td>
            </tr> : null}
        </tbody>
    </StyledTable>
    </>
    )
    
}

export default Table;

 const StyledTable = styled.table`
     border: none;
     border-collapse: collapse;
     td,
  th {
    border: none;
    padding: 0;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 3px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
    }
  }
  thead > tr {
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
 `;
