import {useMutation, gql} from '@apollo/client';
import {GET_ALL_USERS} from "../Management/Users/UsersTable";

export function useAssignUserRoles(setIsOpen) {
    const [modifyUserRoles, {data, loading, error}] = useMutation(MODIFY_USER_ROLES_MUTATION, {
        refetchQueries: [
            {query: GET_ALL_USERS}, // DocumentNode object parsed with gql
            'getAllUsers' // Query name
        ],
        onCompleted() {
            setIsOpen && setIsOpen()
        },
        onError(error) {
            console.log(error)
        }
    });

    const assignAndUnAssignUserRoles = async (user_id, role_id, removeRole) => {
        try {
            return {
                assignUserSites: await modifyUserRoles({
                    variables: {
                        user_id,
                        role_id,
                        removeRole,
                        includeUnAssign: Boolean(removeRole)
                    }
                })
            };
        } catch (err) {
            console.error('Error executing AssignUserSites and UnAssignUserSites mutations:', err.message);
            console.log(err.graphQLErrors); // log the error response
            return null;
        }
    };

    return {assignAndUnAssignUserRoles, data, loading, error};
}

const MODIFY_USER_ROLES_MUTATION = gql`
    mutation modifyUserRoles($user_id: String!, $role_id: String!, $removeRole: String, $includeUnAssign: Boolean!) {
        assignUserRole(user_id: $user_id, role_id: $role_id) {
            success
            message
            error
        }
        unassignUserRole(user_id: $user_id, role_id: $removeRole) @include(if: $includeUnAssign){
            success
            message
            error
        }
    }
`;
