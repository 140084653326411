
import { stubArray } from "lodash";
import styled from "styled-components";

const GridDataCard = ({ label, value, labelColour, children, type }) => {
  if (type === 'small') {
    return (
        <GridCardSmall >
            <Label color={labelColour}>{label}</Label>
            <Value color={labelColour}> {value}</Value>
            {children}
        </GridCardSmall>

    )
  }
    return (
        <GridCard type={type}>
            <Label color={labelColour}>{label}</Label>
            <Value color={labelColour}> {value}</Value>
            {children}
      {type === 'large' ? null : (
        <>
          <div style={{fontSize: "10px"}}>Chart Total</div>
        </>
      )}
        </GridCard>
    )
}  

export default GridDataCard;

const Label = styled.div`
    font-family: Roboto,Arial,sans-serif;
    font-size: 10px;
    color: ${ props => props.color ? props.color :'#202124'};
    font-weight: bold;
`;

const Value = styled.div`
    color: ${ props => props.color ? props.color :'#202124'};
`;

const GridCard = styled.div`
  width: ${props => props.type === 'large' ? '40%' : '20%' };
  height: 77px;
  display: flex;
  justify-content:space-between;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  background-color: white;
  border-radius: 6px;
  margin-right: 20px;
  border: 1px solid #dadce0;
  
`;

const GridCardSmall = styled.div`
  width: 8%;
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  background-color: white;
  border-radius: 6px;
  margin-right: 20px;
  border: 1px solid #dadce0;
  
`;
