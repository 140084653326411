import React from 'react';
import styled, {keyframes} from "styled-components";
import SubmitButton from "../../ButtonComponents/SubmitButton";
import {useAssignUserSites} from "../../Hooks/useAssignSites";

function AssignSites({userId, addSites, removeSites, setIsOpen}) {
    const {executeAssignAndUnAssignUserSites, data, loading, error} = useAssignUserSites(setIsOpen);

    return (
        <SaveButton onClick={() => executeAssignAndUnAssignUserSites(userId, addSites, removeSites)}
                    disabled={loading || (addSites.length === 0 && removeSites.length === 0)}>
            {loading ? <Spinner/> : 'Save'}
        </SaveButton>
    );
}

export default AssignSites;

const SaveButton = styled(SubmitButton)`
  width: 100px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: ${spinAnimation} 1s linear infinite;
`;
