import {useContext} from 'react';
import UserContext from "../../context/UserContext";

const usePermission = (requiredPermission) => {
    const {user} = useContext(UserContext);
    // Check if the user has the required permission
    return user?.roles?.some(role => role.name === requiredPermission) ?? false;
};

export default usePermission;
